.staff-office-listing {
  padding: 6px 0 20px;
}

.staff-office-card {
  background-color: var(--color-white);
  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  font-size: 12px;
  font-weight: 300;
  color: #6f7788;
  margin-bottom: 30px;
  // padding: 30px 30px 20px;
  min-height: 200px;
  text-decoration: none;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-bottom: 10px;
    // padding: 20px 20px 10px;
    min-height: auto;
  }
  .office-card-header {
    padding-right: 10px;
    display: flex;
    margin-bottom: 10px;
    .img-box {
      margin-right: 20px;
      width: 66px;
      height: 66px;
      img {
        max-width: 100%;
        height: 100%;
        border-radius: 15px;
        width: 100%;
        object-fit: cover;
      }
    }
    .text-box {
      width: calc(100% - 10px - 66px);
      .office-desc {
        display: flex;
        align-items: flex-start;
        p {
          margin-bottom: 0;
        }
        img {
          position: relative;
          top: 4px;
        }
      }
      .office-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 0.94;
        color: #587e85;
        margin-bottom: 10px;
      }
    }
  }
  .office-setting-icon {
    position: absolute;
    right: 15px;
  
  }
  .deleted-office {
    img {
      width: 16px;
      height: 16px;
    }
    color: red;
  }
}
.staff-detail-card {
  .timesheet-icon{
margin-right: 7px;
  }
  ul {
    color: #87928d;
    font-size: 11px;
    li {
      margin-bottom: 10px;
      img {
        margin-right: 7px;
      }
    }
  }
}
.download-app-wrapper {
  background: #f9fcee;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.0628824);
  border-radius: 15px;
  padding: 30px 15px;
  margin-top: 45px;
  color: #6f7788;
  font-size: 12px;
  text-align: center;
  &.download-app-bottom {
    border-radius: 0;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    .badge-img-box {
      margin-left: 30px;
      @media screen and (max-width: 767px) {
        margin: 20px 0 0;
      }
    }
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
  .main-heading {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 5px;
    color: #587e85;
    line-height: 1.3;
  }
  .badge-img-box {
    img {
      margin: 8px;
    }
  }
}
.approval-modal {
  .modal-custom-title p {
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .card-container {
    max-height: 588px;
    overflow-y: auto;
    z-index: 3;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
    .request-approval-card {
      padding: 30px;
      background-color: #f9fcee !important;
      @media screen and (max-width: 767px) {
        padding: 15px 15px 0px;
      }
      .request-div{
        max-width: 80%;
        @media screen and (max-width: 767px) {
          max-width: 56%;
        }
      }
      .approval-status {
        background: #66accf;
        border-radius: 6px;
        max-height: 26px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        padding: 4px 6px;
        &.leave{
          background: #FCC539;
        }

      }
    }
  }
}
