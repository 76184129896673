.header {
    width: 100%;
    height: 80px;

    z-index: 10;

    background-color: var(--color-white);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    position: relative;
    @media (max-width: 900px) {
        height: 52px;
        box-shadow: 0 1px 2px 0 rgba(220, 220, 220, 0.5);
    }
}

.logo {
    @media (max-width: 767px) {
     max-width: 90px;
    }
}
.practice-menu{
    font-size: 14px;
    font-weight: 600;
    color: #79869A;
    padding: 10px 20px;
    margin-right: 26px;
    cursor: pointer;
    @media (max-width: 991px) {
       margin-bottom: 10px;
       margin-right: 0;
       padding: 10px 0;
       display: inline-block;
       }
}
.actions {
    .btn-group {
        display: flex;
        align-items: center;
        .login-btn{
            border: 0;
            background: transparent;
            padding-left: 14px;
            padding-right: 14px;
            &:hover, 
            &:focus{
                background: transparent;
                border: 0;
                color: #a9cf3d;
            }
        }
    }

    .user-profile-picture {
        display: none;
    }

    @media (max-width: 900px) {
        .btn-group {
            display: none;
        }

        .user-profile-picture {
            display: block;
        }
    }
}

.user-profile-picture {
    height: 35px;
}

.dropdown {
    width: 100%;

    padding: 20px 16px;

    position: absolute;
    top: calc(100% + 1px);

    background-color: var(--color-white);
    border-radius: 0 0 18px 18px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
    transform-origin: top;
}

.dropdown-open {
    opacity: 1;
    transform: scaleY(1);
    button{
        width: 100%;
    }
}

.dropdown-close {
    opacity: 0;
    transform: scaleY(0);
}

.usr-info {
    cursor: pointer;
    min-width: 120px;
    font-size: 14px;

    @media (max-width: 767px) {
        min-width: inherit;
    }

    span {
        font-weight: 500;
        color: #111b45;
    }

    .media-body {
        img {
            display: inline-block;
            margin-left: 10px;
        }
    }
}

.user-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 8px;

    @media (max-width: 767px) {
        margin-right: 0;
        width: 35px;
        height: 35px;
    }
}

.caret {
    margin-left: 8px;
}
.practice-modal{
    .modal-desc{
        font-size: 16px;
        color: #535B5F;
        font-weight: 300;
        line-height: 1.5;
        margin-bottom: 40px;
        @media (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
}