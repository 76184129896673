//FONT SIZE
@mixin fontsize($pxval) {
   font-size: $pxval + px;

   //Fallback in px
   font-size: rem($pxval);
}

@mixin font-size($pxval: 16, $line: 1.5) {
   font-size: $pxval + px;
   line-height: $line;
   font-size: rem($pxval);

   //line-height: rem($pxval)
}

// CLEAR AFTER MIXIN
// @include clear;

@mixin clear {
   &:after {
      content: "";
      display: table;
      clear: both;
   }
}

// ANCHOR HOVER & FOCUS
@mixin anchor($decoration: none, $outline: none) {
   &:focus,
   &:hover {
      text-decoration: none;
      outline: none;
   }
}

// OR SEPRATOR
@mixin or_seprator($wtd: 100%, $dis: block, $mrg: 30px auto, $brd-btm: 1px solid lighten(#000, 15%), $ht: 1px) {
   width: $wtd;
   display: $dis;
   margin: $mrg;
   border-bottom: $brd-btm;
   height: $ht;

   @include pos(rel, null);

   text-align: center;

   span {
      @include square(35px);

      @include fontsize(14);

      line-height: 35px;
      display: block;

      @include pos(ab, 0 null null 50%);

      @include translate(-50%, -50%);

      background-color: #fff;

      @include radius(50%);
   }
}

// Opacity Mixin
// @include opacity(0);

@mixin opacity($value) {
   $IEValue: $value * 100;

   opacity: $value;
   -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $IEValue + ")";
   filter: alpha(opacity = $IEValue);
}

// FONT WEIGHT MIXIN
// @include font-weight(thin);

@mixin font-weight($weight) {
   $weights: (thin: 100, extra-light: 200, ultra-light: 200, light: 300, normal: 400, book: 400, regular: 400, medium: 500, semi-bold: 600, demi-bold: 600, bold: 700, extra-bold: 800, ultra-bold: 900, heavy: 900, black: 900, ultra: 900, ultra-black: 900, extra-ultra: 900);

   $output: $weight;

   @if map-has-key($weights, $weight) {
      $output: map-get($weights, $weight);
   }

   font-weight: $output;
}

//truncate
@mixin truncate($truncation-boundary) {
   max-width: $truncation-boundary;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

@mixin shadow($top, $left, $blur, $color, $inset: "false") {
   @if $inset == "true" {
      -webkit-box-shadow: inset $top $left $blur $color;
      -moz-box-shadow: inset $top $left $blur $color;
      box-shadow: inset $top $left $blur $color;
   }

   @else {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
   }
}

@mixin cb-easing($time: 2s, $P0: .25, $P1: .1, $P2: .25,$P3: 1 ){
  -webkit-transition: all $time cubic-bezier($P0,$P1,$P2,$P3);
	-moz-transition: all $time cubic-bezier($P0,$P1,$P2,$P3);
	-o-transition: all $time cubic-bezier($P0,$P1,$P2,$P3);
	transition: all $time cubic-bezier($P0,$P1,$P2,$P3);
}

@mixin box-shadow-none($none: none) {
   -webkit-box-shadow: $none;
   -moz-box-shadow: $none;
   box-shadow: $none;
}
