.request-appointment-time{
    .rc-time-picker-input{
        background-color: transparent;
        width: 100%;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: solid 1px rgba(151, 151, 151, 0.4);
        padding-bottom: 15px;
        box-shadow: none;
        color: #102c42;
        padding-left: 0;
        font-size: 14px;
        outline: 0;
        font-weight: 600;
        height: auto;
        &:disabled{
            background-color: transparent;
        }
    }
}
