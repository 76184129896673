.app-page {
    .container {
        max-width: 1470px;

        @media (max-width: 1500px) {
            max-width: 1200px;
        }
    }
}

.vendor-layout {
    .container {
        max-width: 1170px;
    }
}

.landing-page-block {
    background-color: $white;

    .container {
        max-width: 1470px;

        @media (max-width: 1500px) {
            max-width: 1200px;
        }
    }
}

.contact-section {
    @include padding(110 0);
    background-color: $white;
    background-image: url(../../images/landing-pages/schedule-demo-bg.svg);
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 26% auto;

    @media (max-width: 767px) {
        @include padding(40 0);

        &.contact-schedule {
            background-size: 16% auto;
        }
    }

    .schedule-block {
        h2 {
            color: #111b45;
            @include fontsize(36);
            line-height: 35px;
            font-weight: 600;
            margin: 0;

            @media (max-width: 767px) {
                @include fontsize(20);
                line-height: 22px;
                text-align: left;
            }
        }

        h4 {
            @include fontsize(16);
            color: #5b6b82;
            font-weight: 400;
            margin: 24px 0 0;

            @media (max-width: 767px) {
                margin: 16px 0 0;
                text-align: left;
                line-height: 26px;
                @include fontsize(14);
            }
        }

        .schedule-form {
            margin-top: 40px;

            @media (max-width: 767px) {
                margin-top: 30px;
            }

            .c-field {
                text-align: left;

                label {
                    color: #79869a;
                    padding-bottom: 0;
                    margin: 0;
                    @include fontsize(13);
                    font-weight: 400;
                    padding-left: 30px;
                    text-align: left;
                }

                span.ico {
                    @include pos(ab, 9px null null 0);
                }

                .c-form-control {
                    background: transparent;
                    padding-left: 30px;
                    text-align: left;

                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #102c42;
                        opacity: 0.2;
                    }

                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #102c42;
                        opacity: 0.2;
                    }

                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: #102c42;
                        opacity: 0.2;
                    }

                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: #102c42;
                        opacity: 0.2;
                    }
                }
            }

            .button-block {
                padding-top: 20px;

                @media (max-width: 767px) {
                    padding-top: 10px;
                    text-align: center;
                }

                @media (max-width: 767px) {
                    .button {
                        padding: 0 25px !important;
                        width: 100%;
                        max-width: 290px;
                    }
                }
            }
        }
    }
}

.reach-us-block {
    padding-left: 100px;

    @media (max-width: 767px) {
        padding-left: 0;
        margin-top: 40px;
    }

    h2 {
        color: #111b45;
        @include fontsize(36);
        line-height: 35px;
        font-weight: 600;
        margin: 0;

        @media (max-width: 767px) {
            @include fontsize(20);
            line-height: 22px;
            text-align: left;
        }
    }

    .contact-dtl {
        margin-top: 85px;

        @media (max-width: 767px) {
            margin: 24px 0 0;
            max-width: 250px;
        }

        ul {
            li {
                a {
                    text-decoration: none;
                    color: #587e85;
                }

                @include fontsize(16);
                line-height: 30px;
                color: #587e85;
                font-weight: 500;
                margin-bottom: 40px;
                padding-left: 60px;
                min-height: 40px;
                max-width: 220px;

                @media (max-width: 767px) {
                    margin-bottom: 16px;
                    @include fontsize(14);
                    padding-left: 48px;
                    line-height: 22px;
                }

                &:nth-child(1) {
                    background: url("../../images/mail.svg") no-repeat left top 0px;

                    @media (max-width: 767px) {
                        background-size: 24px;
                    }
                }

                &:nth-child(2) {
                    background: url("../../images/phone-call.svg") no-repeat left top 0px;

                    @media (max-width: 767px) {
                        background-size: 24px;
                    }
                }

                &:nth-child(3) {
                    background: url("../../images/pin.svg") no-repeat left top 0px;

                    @media (max-width: 767px) {
                        background-size: 20px;
                    }
                }

                @media (max-width: 767px) {
                    .media-body {
                        text-align: left;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.header-section {
    @include pos(abs, 0 0 null 0);
    z-index: 5;

    @media screen and (max-width: 767px) {
        position: relative;
        background-color: $white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

    .container {
        max-width: 1470px;

        @media (max-width: 1500px) {
            max-width: 1200px;
        }
    }

    &.header-fixed {
        visibility: visible;
        animation-duration: 500ms;
        animation-iteration-count: 1;
        animation-name: fadeInDown;
        animation-timing-function: linear;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
        @include pos(fix, 0 0 null 0);
        z-index: 10;
        background-color: $white;

        .nav-section .doctor-btn {
            background-color: #F2F3F5;
        }
    }

    .logo-block {
        .logo {
            display: inline-block;
            vertical-align: middle;

            @media screen and (max-width: 767px) {
                max-width: 90px;
            }
        }

        h4 {
            display: inline-block;
            vertical-align: middle;
            @include fontsize(12);
            line-height: 14px;
            margin: 0;
            color: #8da1a4;
            font-weight: 600;
            margin-left: 15px;
            padding-left: 15px;
            border-left: 1px solid #e5e5e5;

            @media (max-width: 767px) {
                @include fontsize(8);
            }
        }

        @media (max-width: 991px) {
            @include padding(14 0);
        }
    }

    nav {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 50px;
        align-items: center;

        @media (max-width: 1500px) {
            margin-left: 0px;
        }

        @media (max-width: 991px) {
            @include pos(rel, 0 0 0 0);
            display: table;
            width: 100%;
        }

        &>ul {
            margin: 0;
            padding: 0;

            @media screen and (max-width: 991px) {
                max-height: 68vh;
                overflow-y: auto;
            }

            &>li {
                display: inline-block;
                position: relative;
                padding: 32px 0;

                @media (max-width: 991px) {
                    display: block;
                    padding: 14px 0;
                    margin: 0 15px;
                    border-top: 1px solid #ecf0f0;


                    &.active-li {
                        a {
                            span {
                                opacity: 1;
                            }
                        }

                        &>.sub-menu {
                            @include opacity(1);
                            transform: scale(1, 1);
                            display: block;
                        }
                    }
                }


                &:hover {
                    @media screen and (min-width: 992px) {
                        a {
                            span {
                                opacity: 1;
                            }
                        }

                        &>.sub-menu {
                            @include opacity(1);
                            transform: scale(1, 1);
                            display: block;
                        }
                    }
                }

                .sub-menu {
                    display: none;
                    @include pos(ab, 100% 0 null 0);
                    min-width: 195px;
                    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                    background-color: $white;
                    border-radius: 16px 16px;
                    z-index: 2;
                    @include opacity(0);
                    transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
                    transform: scale(1, 0);
                    transform-origin: top;

                    @media (max-width: 991px) {
                        @include pos(rel, 0 0 0 0);
                        @include opacity(1);
                        transform: scale(1, 1);
                        background-color: transparent;
                        box-shadow: none;
                        display: none;
                    }

                    ul {
                        padding: 0 20px 5px;

                        @media (max-width: 991px) {
                            padding: 0 5px 0;
                            margin-top: 10px;
                        }

                        li {
                            border-bottom: 1px solid rgba($color: #dae2e2, $alpha: 0.5);

                            @media screen and (max-width: 991px) {
                                border-bottom: 0;
                            }

                            &:last-child {
                                border-bottom: 0;
                            }

                            a {
                                @include fontsize(14);
                                line-height: 14px;
                                font-weight: 500;
                                color: #79869a;
                                padding: 14px 0;
                                text-align: left;
                                margin: 0;
                                display: block;
                                text-decoration: none;

                                @media screen and (max-width: 991px) {
                                    padding: 8px 0;
                                }

                                &:hover,
                                &:focus {
                                    color: $theme;
                                }

                                &.active {
                                    color: $theme;
                                }
                            }
                        }
                    }
                }

                &.active-nav-item {
                    a {
                        &:before {
                            right: 0;
                        }

                        span {
                            opacity: 1;
                        }
                    }
                }

                &>a {
                    @include fontsize(14);
                    line-height: 14px;
                    color: #79869a;
                    font-weight: 600;
                    display: block;
                    text-decoration: none;
                    margin: 0 25px;
                    -webkit-transform: perspective(1px) translateZ(0);
                    transform: perspective(1px) translateZ(0);
                    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
                    position: relative;

                    span {
                        opacity: 0;

                        @media (max-width: 991px) {
                            opacity: 1;
                        }
                    }

                    @media (max-width: 1500px) {
                        margin: 0 18px;
                    }

                    @media (max-width: 1200px) {
                        margin: 0 10px;
                    }

                    @media (min-width: 990px) and (max-width: 1024px) {
                        margin: 0px 4px;
                    }

                    @media (max-width: 991px) {
                        margin: 0;

                        &::before {
                            display: none;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        left: 0;
                        right: 100%;
                        bottom: -32px;
                        background: #a9cf3d;
                        height: 2px;
                        -webkit-transition-property: right;
                        transition-property: right;
                        -webkit-transition-duration: 0.3s;
                        transition-duration: 0.3s;
                        -webkit-transition-timing-function: ease-out;
                        transition-timing-function: ease-out;
                    }

                    &:hover:before,
                    &:active:before {
                        right: 0;
                    }

                    span {
                        @media (max-width: 991px) {
                            float: right;
                        }
                    }

                    img {
                        width: 8px;
                        margin-left: 8px;
                    }

                    &.active {
                        &::before {
                            right: 0;
                        }
                    }
                }


                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }

                .blogResources {
                    font-size: 0.875rem;
                    line-height: 14px;
                    font-weight: 500;
                    color: #79869a;
                    padding: 14px 0;
                    text-align: left;
                    margin: 0;
                    display: block;
                    text-decoration: none;
                    background: transparent;
                    border: none;

                    @media (max-width: 991px) {
                        padding: 8px 0;
                    }

                    &:hover {
                        color: #a9cf3d;
                    }
                }

                &.custom-menu {
                    &:hover {
                        @media screen and (min-width: 992px) {
                            a {
                                &::before {
                                    background: transparent;
                                    z-index: 0;
                                    transition: none;
                                }

                                span {
                                    opacity: 1;
                                }
                            }

                            &>.sub-menu {
                                @include opacity(1);
                                transform: scale(1, 1);
                                display: none;
                            }
                        }
                    }

                    .show {
                        transform: rotate(180deg);
                    }
                }
            }

        }
    }

    .reg-btn-block {
  
        float: right;
        margin-top: 13.5px;

        @media (max-width: 991px) {
            margin-left: 0;
            width: 100%;
            padding: 0 16px 8px;
            text-align: right;
            margin-top: 0;
        }

        .button {
            min-height: 48px;
            padding: 11px 30px;

            @media (max-width: 991px) {
                @include fontsize(14);
                min-width: 90px;
                min-height: 36px;
                width: calc(26% - 5px);
                padding: 6px 15px;
                margin-bottom: 6px;
            }

            @media (max-width: 360px) {
                min-width: 75px;
                padding: 7px 10px;
                width: auto;
                font-size: 12px;
            }

            &.login-btn {
                border: 0;
                margin-right: 10px;
                padding-left: 14px;
                padding-right: 14px;
                background-color: transparent;
                color: #a9cf3d;
            }

        }

    }

    .nav-section {

        .button-container {
            display: flex;
            justify-content: space-between;
            margin-left: 10px;
            margin-right: 10px;

            @media (max-width: 991px) {
                margin-bottom: 15px;
            }

            @media (max-width: 426px) {
                flex-direction: column;
                width: 100%;
            }

            .custom-nav-container{
                @media (max-width: 426px) {
                    margin-right: 20px;
                }
            }

            .custom-btn-container {
                @media (max-width: 426px) {
                    margin-top: 15px;
                }
                .custom-btn{
                    
                    @media (max-width: 426px) {
                        width:45%;
                    }
                   
                }
            }
        }


        @media (max-width: 991px) {
            text-align: left;
            @include pos(ab, 100% 0 0 0);
            display: table;
            width: 100%;
            z-index: 10;
            border-radius: 0 0 18px 18px;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
            background-color: $white;
        
            padding-top: 20px;
            @include opacity(0);
            transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
            transform: scale(1, 0);
            transform-origin: top;

            &.open {
                @include opacity(1);
                transform: scale(1, 1);
            }
        }

        .doctor-btn {
            text-decoration: none;
            margin-left: 65px;
            font-size: 14px;
            font-weight: 600;
            color: #79869a;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 100px;
            padding: 12.5px 30px;

            @media (max-width: 1500px) {
                margin-left: 35px;
                padding: 12.5px 25px;
            }

            @media (max-width: 1200px) {
                margin-left: 23px;
            }

            @media (min-width: 998px) and (max-width: 1050px) {
                margin-left: 0px;
            }

            @media (max-width: 991px) {
                float: left;
                padding: 6px 15px;
                margin-left: 0;
                margin-right: 5px;
                background-color: #F2F3F5;
                min-height: 36px;
                white-space: nowrap;
            }

            @media screen and (max-width: 426px) {
                .doctor-btn-search-icon{
                    position: absolute;
                    right: 10px;
                }
            }

            @media (max-width: 360px) {
                margin-right: 0;
                font-size: 12px;
            }

            .find-doctor-vertical-line {
                height: 23px;
                width: 1px;
            }

            img {
                margin-right: 5px;
                width: 13px;
            }
        }
    }

    &~.static-page-content {
        padding-top: 120px;

        @media (max-width: 767px) {
            padding: 30px 0;
        }
    }
}

.footer-section {
    background: #2d3245;

    .footer-block {
        @include padding(100 0);
        color: $white;

        @media (max-width: 991px) {
            @include padding(70 0 40);
        }

        @media (max-width: 767px) {
            @include padding(40 0);
        }

        .container {
            max-width: 1470px;

            @media (max-width: 1500px) {
                max-width: 1200px;
            }
        }

        .footer-logo {
            .logo {
                display: inline-block;
                vertical-align: middle;

                @media (max-width: 767px) {
                    img {
                        max-width: 84px;
                    }
                }
            }

            h4 {
                display: inline-block;
                vertical-align: middle;
                @include fontsize(11);
                line-height: 10px;
                margin: 0;
                color: #e0e6ea;
                font-weight: 600;
                margin-left: 11px;
                padding-left: 11px;
                border-left: 1px solid #5e5e5e;

                @media (max-width: 767px) {
                    @include fontsize(8);
                }
            }
        }

        .social-media-links {
            margin-top: 55px;
            margin-bottom: 50px;

            @media (max-width: 991px) {
                margin-top: 30px;
                margin-bottom: 40px;
            }

            ul {
                li {
                    display: inline-block;
                    margin-left: 20px;
                    vertical-align: middle;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        .cpy-text {
            margin: 0;
            @include fontsize(14);
            margin: 0;
            color: #e0e6ea;
            max-width: 300px;

            @media (max-width: 991px) {
                max-width: 100%;
                margin-top: 40px;
            }

            @media (max-width: 767px) {
                @include fontsize(12);
                margin: 50px auto 0;
                color: #fff;
                max-width: 257px;
            }
        }

        .customer-menu {
            @media (max-width: 767px) {
                order: 2;
            }

            .footer-links {
                margin-left: -20px;

                @media (max-width: 1500px) {
                    margin-left: 0px;
                    padding-left: 60px;
                    margin-right: -30px;
                }

                @media (max-width: 991px) {
                    padding-left: 40px;
                    margin-right: 0px;
                }

                @media (max-width: 767px) {
                    padding-left: 0px;
                }

                ul {
                    display: flex;
                    flex-wrap: wrap;

                    li {
                        width: 50%;

                    }
                }
            }
        }

        .platform-menu {
            @media (max-width: 767px) {
                order: 3;
            }

            .footer-links {
                margin-left: -100px;

                @media (max-width: 1500px) {
                    margin-left: -10px;
                }

                @media (max-width: 1024px) {
                    margin-left: 0px;
                }

                @media (max-width: 767px) {
                    margin-top: 60px;

                }

            }
        }

        .company-menu {
            @media (max-width: 767px) {
                order: 4;
            }

            .footer-links {
                padding-left: 20px;

                @media (max-width: 1500px) {
                    padding-left: 60px;
                }

                @media (max-width: 991px) {
                    margin-top: 60px;
                    padding-left: 0;
                }
            }
        }

        .policy-menu {
            @media (max-width: 767px) {
                order: 5;

            }

            .footer-links {
                @media (max-width: 1500px) {
                    padding-left: 40px;
                }

                @media (max-width: 991px) {
                    margin-top: 60px;
                }

                @media (max-width: 767px) {
                    padding-left: 0;

                }
            }
        }

        .footer-links {
            h4 {
                margin: 0;
                font-weight: 400;
                @include fontsize(18);
                text-transform: uppercase;
                color: #e0e6ea;

                @media (max-width: 767px) {
                    @include fontsize(16);
                }
            }

            ul {
                margin-top: 30px;

                li {
                    margin-bottom: 20px;

                    a {
                        text-decoration: none;
                        @include fontsize(14);
                        line-height: 14px;
                        font-weight: 500;
                        color: $white;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    .blogResourcesBtn {
                        background: transparent;
                        border: none;
                        font-size: 0.875rem;
                        line-height: 21px;
                        font-weight: 500;
                        color: #fff;
                        text-align: left;
                       
                    }
                }
            }
        }
    }
}

.app-page {
    .banner-block {
        &.faq-banner {
            background-image: url("../../images/landing-pages/faq-page-banner.jpg");
            background-position: center right;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            align-items: center;
            min-height: 800px;

            @media (max-width: 991px) {
                min-height: 450px;
                background-image: url("../../images/landing-pages/faq-mobile-banner.jpg");
                background-position: center;
                background-color: #f2f3ef;
            }

            @media (max-width: 767px) {
                padding-bottom: 30px;
            }

            &::before {
                display: none;
            }

            .banner-caption {
                text-align: center;

                margin: 0 auto;

                h2 {
                    @include fontsize(56);
                    color: #587e85;
                    font-weight: 700;
                    margin: 0 0 50px;
                    line-height: 1.4;
                    letter-spacing: -1px;

                    @media (max-width: 991px) {
                        font-size: 45px;
                        line-height: 1.1;
                        margin-bottom: 30px;
                    }

                    @media (max-width: 767px) {
                        font-size: 40px;
                        margin-bottom: 22px;
                    }
                }

                p {
                    font-size: 20px;
                    color: #2d3245;
                    font-weight: 400;

                    @media (max-width: 767px) {
                        font-size: 13px;
                        margin-bottom: 22px;
                    }
                }

                .search-box {
                    width: 100%;
                    margin-bottom: 50px;

                    @media (max-width: 767px) {
                        margin-bottom: 16px;
                    }

                    input {
                        height: 72px;
                        padding: 16px 50px 16px 30px;
                        font-size: 20px;
                        box-shadow: 0px 9.69125px 29.0738px rgba(0, 0, 0, 0.06);

                        @media (max-width: 767px) {
                            font-size: 14px;
                            height: 56px;
                            padding-left: 15px;
                        }
                    }

                    span.ico {
                        top: 25px;
                        left: auto;
                        right: 30px;

                        @media (max-width: 767px) {
                            top: 15px;
                            right: 15px;
                        }
                    }
                }
            }

            .faq-tabs {
                margin-top: 50px;
                border: 0;
                justify-content: center;

                @media (max-width: 1024px) {
                    margin-top: 30px;
                }

                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                .nav-item {
                    margin: 0 15px 30px;

                    @media (max-width: 1024px) {
                        margin: 0 5px 10px;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                        margin-right: 0 !important;
                        margin-bottom: 10px;
                    }
                }

                .nav-link {
                    text-align: center;
                    padding: 18px 55px;
                    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
                    border-radius: 18px;
                    border: 2px solid transparent;
                    background-color: #fff;
                    min-height: 110px;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 1.2;
                    color: #587e85;
                    cursor: pointer;

                    @media (max-width: 1500px) {
                        font-size: 18px;
                    }

                    @media (max-width: 1024px) {
                        padding: 20px 25px;
                    }

                    @media (max-width: 767px) {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 16px;
                        padding: 10px 15px;
                        min-height: auto;
                    }

                    img {
                        display: block;
                        margin: 0 auto 10px;

                        @media (max-width: 767px) {
                            margin: 0 10px 0 0;
                        }
                    }

                    .icon-green {
                        display: none;
                    }

                    &.active {
                        border: 2px solid #a9cf3d;

                        .icon-grey {
                            display: none;
                        }

                        .icon-green {
                            display: block;
                        }
                    }
                }
            }

            &.pricing-banner {
                background-image: url("../../images/landing-pages/pricing-page-banner.jpg");
                background-position: center;

                @media (max-width: 991px) {
                    background-image: url("../../images/landing-pages/pricing-banner-mobile.jpg");
                }

                @media (max-width: 767px) {
                    padding-bottom: 30px;

                    h2 {
                        font-size: 30px;
                    }
                }

                .banner-caption {
                    max-width: 100%;
                    margin-top: -50px;

                    @media (max-width: 991px) {
                        margin-top: 40px;
                    }
                }

                .pricing-tabs {
                    .nav-item {
                        .nav-link {
                            padding: 18px 30px;
                            min-height: 64px;

                            @media (max-width: 1024px) {
                                font-size: 17px;
                                padding: 18px 25px;
                            }

                            @media (max-width: 767px) {
                                font-size: 16px;
                                min-height: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .note_block {
        background-color: #f9faf8;
        padding: 70px 0;
        text-align: center;

        @media (max-width: 991px) {
            padding: 40px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
            text-align: left;
        }

        h2 {
            @include fontsize(24);
            line-height: 40px;
            font-weight: 400;
            margin: 0;
            color: #587e85;

            @media (max-width: 991px) {
                @include fontsize(20);
                line-height: 30px;
            }

            @media (max-width: 767px) {
                @include fontsize(14);
                line-height: 24px;
            }
        }
    }

    .feature-section {
        @include padding(110 0 101);
        background-color: #f0f8f8;

        @media (max-width: 991px) {
            padding: 50px 0;
        }

        @media (max-width: 767px) {
            padding: 30px 0;
        }

        h2 {
            color: #111b45;
            @include fontsize(36);
            line-height: 35px;
            font-weight: 600;
            margin: 0 0 70px;
            text-align: center;

            @media (max-width: 991px) {
                @include fontsize(24);
                line-height: 25px;
                margin: 0 0 30px;
            }

            @media (max-width: 767px) {
                @include fontsize(20);
                line-height: 22px;
                margin: 0 0 10px;
                text-align: left;
            }
        }

        .feature-data-row {
            margin-bottom: 40px;

            @media (max-width: 991px) {
                margin-bottom: 30px;
            }

            @media (max-width: 767px) {
                margin-bottom: 40px;

                img {
                    margin-left: -15px;
                }
            }

            .content-block {
                max-width: 410px;
                margin: 0 auto;

                h4 {
                    @include fontsize(28);
                    line-height: 44px;
                    font-weight: 600;
                    margin: 0 0 12px;
                    color: #587e85;

                    @media (max-width: 991px) {
                        @include fontsize(20);
                        line-height: 24px;
                    }

                    @media (max-width: 767px) {
                        @include fontsize(16);
                        line-height: 20px;
                        margin: 0 0 10px;
                    }
                }

                p {
                    @include fontsize(18);
                    line-height: 30px;
                    font-weight: 400;
                    color: #5b6b82;

                    @media (max-width: 991px) {
                        @include fontsize(14);
                        line-height: 24px;
                    }

                    @media (max-width: 767px) {
                        @include fontsize(12);
                        margin: 0;
                        line-height: 20px;
                    }
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.plan-list-section {
    background: #fff;
    @include padding(100 0);

    @media (max-width: 991px) {
        @include padding(50 0);
    }

    @media (max-width: 767px) {
        @include padding(30 0);
    }

    h2 {
        color: #111b45;
        @include fontsize(36);
        line-height: 1.1;
        font-weight: 600;
        margin: 0;
        text-align: center;

        @media (max-width: 991px) {
            @include fontsize(24);
            text-align: left;
        }

        @media (max-width: 767px) {
            @include fontsize(20);
        }
    }

    .plan-list {
        margin-top: 60px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 991px) {
            margin-top: 30px;
        }

        @media (max-width: 767px) {
            margin-top: 20px;
        }

        .plan-block {
            height: 100%;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
            border-radius: 18px;
            background-color: $white;
            text-align: center;
            @include padding(60 25 45);
            min-height: 630px;
            transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
            -webkit-transition: all 0.6s cubic-bezier(0.33, 1, 0.68, 1);
            position: relative;
            border: 3px solid transparent;

            @media (max-width: 1199px) {
                @include padding(30 20);
                min-height: 620px;
            }

            @media (max-width: 991px) {
                max-width: 520px;
                margin: 0 auto 20px;
                min-height: inherit;
                height: auto;
            }

            @media (max-width: 767px) {
                @include padding(40 20 25);
            }

            &:hover {
                @include transform(scale(1.1));
                border-color: #a9cf3d;
                z-index: 2;

                @media (max-width: 1024px) {
                    @include transform(scale(1));
                    border-color: transparent;
                }
            }

            &.active-plan {
                background-color: #f9fcee;
            }

            &.multi-office-plan {
                .price {
                    h4 {
                        sub {
                            top: 15px;
                        }
                    }
                }
            }

            .plan-header {
                height: 100%;

                h3 {
                    @include fontsize(22);
                    line-height: 1;
                    color: #242f40;
                    font-weight: 600;
                    margin: 24px 0 0;

                    @media (max-width: 767px) {
                        @include fontsize(20);
                    }
                }
            }

            .price {
                margin-top: 24px;

                @media (max-width: 991px) {
                    margin-top: 16px;
                }

                h4 {
                    @include fontsize(65);
                    color: #242f40;
                    line-height: 1.1;
                    margin: 0;
                    font-weight: 600;

                    @media (max-width: 991px) {
                        @include fontsize(40);
                    }

                    @media (max-width: 767px) {
                        @include fontsize(40);
                    }

                    &.custom-font {
                        font-size: 40px;
                    }

                    &>span {
                        @include fontsize(30);
                        font-weight: 400;
                        line-height: 30px;
                        display: inline-block;

                        @media (max-width: 767px) {
                            @include fontsize(20);
                        }

                        &.custom-sub {
                            font-size: 22px;
                            color: #8f9bb2;
                            font-weight: 400;
                            line-height: 22px;
                            margin-left: -62px;
                           

                            @media (max-width: 767px) {
                                @include fontsize(12);
                            }
                        }
                    }

                    sub {
                        @include fontsize(22);
                        color: #8f9bb2;
                        font-weight: 400;
                        line-height: 22px;
                        bottom: 0;
                        max-width: 60px;
                        display: inline-block;

                        @media (max-width: 767px) {
                            @include fontsize(12);
                        }



                        span {
                            @include fontsize(12);
                            display: block;
                            position: relative;
                            left: 15px;

                            &.per-office {
                                position: absolute;
                                top: 18px;
                                min-width: 90px;
                                text-align: left;

                                @media screen and (max-width:767px) {
                                    top: 13px;
                                    left: 7px;
                                    font-size: 11px;
                                }
                            }
                        }
                    }

                    sup {
                        &.custom-office {

                            font-weight: 400;
                            font-size: 12px;
                            line-height: 22px;
                            color: #8F9BB2;
                            top: -1.9rem;
                            left: 6px;
                            white-space: nowrap;


                            @media (max-width: 767px) {
                                @include fontsize(12);
                            }
                        }
                    }
                }
            }

            .plan-content-layout {
                height: 100%;
                justify-content: space-between;

            }

            .feature-list {
                margin-top: 24px;
                text-align: left;

                @media (max-width: 767px) {
                    margin-top: 16px;
                }

                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        @include fontsize(18);
                        line-height: 1.6;
                        margin-bottom: 15px;
                        color: #243245;
                        text-align: center;

                        .price-bold {
                            color: #587e85;
                            font-weight: 600;
                        }

                        @media (max-width: 767px) {
                            @include fontsize(14);
                            margin-bottom: 12px;
                        }

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .button_block {
                padding: 0 10px;
                width: 100%;
                margin-top: 24px;

                @media (max-width: 991px) {
                    margin-top: 24px;
                }

                @media (max-width: 767px) {
                    padding: 0;
                }

                .button {
                    height: 55px;
                    max-width: 280px;
                    margin: 0 auto 16px;

                    span.ico {
                        display: inline-block;
                        margin-right: 10px;
                        @include fontsize(12);
                    }
                }
            }

            .contact-block {
                margin-top: 40px;

                @media (max-width: 991px) {
                    margin-top: 30px;
                }

                @media (max-width: 767px) {
                    margin-top: 20px;
                }

                h4 {
                    color: #587e85;
                    @include fontsize(22);
                    line-height: 28px;
                    font-weight: 500;
                    margin: 0 0 30px;

                    @media (max-width: 991px) {
                        margin: 0 0 20px;
                    }

                    @media (max-width: 767px) {
                        @include fontsize(16);
                        line-height: 18px;
                    }
                }

                p {
                    color: #5b6b82;
                    margin: 0;
                    @include fontsize(13);
                    line-height: 26px;
                }
            }
        }
    }
}

.home-plans-new {
    padding-top: 100px;
    padding-bottom: 90px;

    background: #ffffff;

    @media (max-width: 767px) {
        padding: 40px 15px;
    }

    .container {
        max-width: 1380px;

        h2 {
            font-weight: 600;
            font-size: 36px;
            line-height: 35px;
            text-align: center;
            color: #2D3245;
            margin-bottom: 60px;

            @media (max-width: 767px) {

                font-size: 20px;
                line-height: 22px;
                text-align: left;
                margin-bottom: 20px;
            }
        }

        .right-block {
            width: 100%;
            display: flex;
            justify-content: end;

            @media (max-width: 991px) {
                display: block;
            }

            h3 {
                font-weight: 600;
                font-size: 22px;
                line-height: 22px;
                color: #242F40;

                @media (max-width: 767px) {

                    font-size: 14px;
                    line-height: 22px;
                }

            }

            p {
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;

                color: #5B6B82;

                @media (max-width: 767px) {
                    font-size: 14px;
                }

            }

            span {
                font-weight: 500;
                font-size: 12px;
                line-height: 24px;
                color: #8F9BB2;
                display: block;
            }

            span.viewPricing {
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                text-decoration-line: underline;
                color: #587E85;
                display: block;
                cursor: pointer;

            }

            button {
                min-height: 40px;
                padding: 9px 30px;
            }

            .free {
                width: 246px;
                padding: 20px;
                background: #F9FCEE;
                border: 1px solid #DEE0E4;

                @media (max-width: 991px) {
                    padding: 15px;
                    width: 50%;
                    float: right;
                }

                @media (max-width: 576px) {
                    padding: 15px;
                    width: 75%;
                    float: right;
                }

                @media (max-width: 425px) {
                    padding: 15px;
                    width: 100%;
                    float: right;
                }

                h3 {
                    margin-bottom: 60px;

                    @media (max-width: 767px) {
                        margin-bottom: 18px;
                    }
                }

                p {
                    margin-bottom: 68px;

                    @media (max-width: 767px) {
                        margin-bottom: 18px;
                    }
                }
            }

            .basic-others {
                width: 246px;
                padding: 20px;
                background: #FFFFFF;
                border: 1px solid #DEE0E4;

                @media (max-width: 991px) {
                    width: 50%;
                    float: right;
                }

                @media (max-width: 576px) {
                    width: 75%;
                    float: right;
                }

                @media (max-width: 425px) {
                    width: 100%;
                    float: right;
                }

                h3 {
                    margin-bottom: 4px;
                }

                span {
                    margin-bottom: 8px;
                }

                a {
                    margin-bottom: 16px;
                    cursor: pointer;

                }

                p {
                    margin-bottom: 42px
                }

                .advance-para {
                    margin-bottom: 20px;
                }

            }
        }


        .feature-comparision-table {
            width: 100%;
            margin-bottom: 10px;


            tr {
                color: #5B6B82;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;

                .custom-width-heading {
                    @media (max-width: 991px) {
                        display: none;
                    }
                }

                &:nth-child(even) {
                    background-color: #FFFFFF;
                }

                &:nth-child(odd) {
                    background-color: #F2F3EF;
                }

                @media (max-width: 767px) {


                    font-size: 14px;

                }


                th {
                    padding: 18px 20px;
                    background-color: #587E85;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #FFFFFF;
                    width: 18%;

                    &:nth-child(1) {
                        width: 27%;
                    }

                    @media (max-width: 767px) {


                        font-size: 14px;
                        line-height: 16px;
                    }


                    .circle {
                        float: right;
                        border: 1px solid #3B5C62;
                        border-radius: 40px;
                        color: #fff;
                        background-color: #3B5C62;
                        padding: 7px 12px;
                        font-size: 16px;

                    }


                }

                td {
                    text-align: center;
                    padding: 15px 0px;

                    border: 1px solid #DADCE0;
                    border-radius: 10px;

                    &:nth-child(1) {
                        text-align: left;

                        padding-left: 20px;

                    }


                }
            }
        }






    }
}

.pricing-details-basic {
    max-width: 1020px;

    .modal-content {
        @media (max-width: 767px) {
            padding: 20px 15px 16px;
        }

        .currency-container {
            background: #F9FCEE;
            border-radius: 100px;
            width: 195px;
            margin: 0 auto 35px;
            display: flex;
            align-items: center;
            padding: 0px 16px 0px 16px;

            @media (max-width: 767px) {
                margin-bottom: 30px;
            }

            .currency {
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                margin-right: 12px;
                margin-left: 12px;
                margin-top: 8px;

                color: #2D3245;
            }
        }

        .features-pricing .plan-list-section .plan-list {
            margin-top: 0px;

            .content-row {
                margin-bottom: 30px;

                @media (max-width: 767px) {
                    margin-bottom: 12px;
                }

                .col-lg-6 {
                    @media (max-width: 767px) {
                        padding-left: 0px;
                        padding-right: 0px;
                    }

                    .plan-block {
                        padding-top: 40px;
                        padding-bottom: 40px;

                        &:hover {
                            transform: none;
                            border-color: transparent;
                            z-index: 0;
                        }
                    }
                }
            }
        }

        button {
            min-width: 222px;
            margin-top: 40px;

            @media (max-width: 767px) {
                margin-top: 16px;

            }
        }
    }
}

.testimonials-section {
    @include padding(160 0);
    background: #f2f3ef url(../../images/landing-pages/testimonial-bg.jpg) no-repeat center;
    background-size: cover;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        background: #f2f3ef url(../../images/landing-pages/testimonial-quotation.svg) no-repeat right top 15%;
        content: "";
        position: absolute;
        left: 0;
        width: 37%;
        top: 0;
        bottom: 0;
        z-index: -1;

        @media (min-width: 2200px) {
            width: 42%;
        }

        @media (max-width: 991px) {
            background-size: 75% auto;
            background-position: right top 15px;
            width: 33%;
            left: auto;
            right: 15px;
        }
    }

    @media (max-width: 991px) {
        @include padding(70 0 90px);
        background: #f2f3ef;
    }

    @media (max-width: 767px) {
        @include padding(40 0 82);
    }

    .title-col {
        @media (min-width: 992px) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
    }

    h2 {
        color: #2d3245;
        @include fontsize(36);
        line-height: 1.55;
        font-weight: 600;
        margin: 0;
        max-width: 315px;

        @media (max-width: 991px) {
            @include fontsize(24);
            margin-bottom: 20px;
            max-width: 100%;
        }

        @media (max-width: 767px) {
            @include fontsize(20);
        }
    }

    .testomonial-slider {
        max-width: 100%;
        margin-left: auto;
        position: relative;
        z-index: 2;

        @media (max-width: 991px) {
            max-width: 100%;
            padding: 0;
        }

        &.no-slider {
            .slick-list {
                margin-right: -20%;

                @media (max-width: 1300px) {
                    margin-right: 0;
                }
            }
        }

        &.no-slider-3 {
            .slick-list {
                margin-right: 0;
            }
        }

        .slick-slider {
            padding-left: 33%;

            @media (min-width: 2500px) {
                padding-left: 40%;
            }

            @media (max-width: 991px) {
                padding-left: 0;
            }
        }

        .slick-prev,
        .slick-next {
            opacity: 1;
            bottom: -40px;
            border-radius: 100%;
            text-align: center;
            top: auto;
            transform: none;
            left: 5%;

            @media screen and (min-width: 2501px) {
                left: 22%;
            }

            @media screen and (min-width: 3501px) {
                left: 31%;
            }

            @media (max-width: 2500px) {
                left: 16.5%;
            }

            @media (max-width: 2000px) {
                left: 12.5%;
            }

            @media screen and (max-width: 1850px) {
                left: 9.5%;
            }

            @media screen and (max-width: 1700px) {
                left: 6%;
            }

            @media screen and (max-width: 1500px) {
                left: 9%;
            }

            @media screen and (max-width: 1400px) {
                left: 7%;
            }

            @media screen and (max-width: 1300px) {
                left: 4%;
            }

            @media screen and (max-width: 1200px) {
                left: 15px;
            }

            @media (max-width: 991px) {
                left: 50%;
                margin-left: -42px;
                bottom: -60px;
            }
        }

        .slick-next {
            margin-left: 56px;

            @media (max-width: 991px) {
                margin-left: 10px;
            }
        }

        .slick-list {
            margin-right: -40%;
            padding-left: 10px;

            @media (max-width: 1300px) {
                margin-right: 0;
                padding-left: 0px;
            }
        }

        .slick-slide {
            transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
            padding: 30px 15px;
        }

        .testimonial-block {
            @include padding(30);
            box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.06);
            border-radius: 18px;
            background-color: $white;
            min-height: 374px;

            @media (max-width: 991px) {
                min-height: inherit;
            }

            @media (max-width: 767px) {
                @include padding(20);
            }

            .media {
                margin-bottom: 30px;

                @media (max-width: 991px) {
                    margin-bottom: 12px;
                    text-align: left;
                    align-items: center;
                }

                img {
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    object-fit: cover;
                    margin-right: 16px;

                    @media (max-width: 991px) {
                        margin-right: 10px;
                    }
                }

                h4 {
                    @include fontsize(30);
                    color: #2d3245;
                    margin: 0 0 5px;
                    font-weight: 500;
                    line-height: 1.4;
                    word-break: break-word;
                    text-transform: capitalize;

                    @media (max-width: 1500px) {
                        @include fontsize(26);
                    }

                    @media (max-width: 991px) {
                        @include fontsize(14);
                    }
                }

                p {
                    @include fontsize(20);
                    margin: 0;
                    color: rgba($color: #79869a, $alpha: 1);
                    text-transform: capitalize;

                    @media (max-width: 1500px) {
                        @include fontsize(18);
                    }

                    @media (max-width: 1500px) {
                        @include fontsize(12);
                    }
                }
            }

            ._txt {
                p {
                    @include fontsize(18);
                    margin: 0;
                    line-height: 1.77;
                    color: rgba($color: #494466, $alpha: 1);

                    @media (max-width: 1500px) {
                        @include fontsize(16);
                    }

                    @media (max-width: 767px) {
                        @include fontsize(14);
                        line-height: 1.7;
                    }
                }
            }
        }
    }
}

.app-about-section {
    padding: 100px 0;
    background: url("../../images/edited-about-bg.jpg") no-repeat;
    background-size: cover;
    background-position: center center;

    @media (max-width: 1700px) {
        background-position: center right;
    }

    @media (min-width: 2300px) {
        padding: 200px 0;
    }

    @media (max-width: 991px) {
        padding: 50px 0;
    }

    @media (max-width: 767px) {
        padding: 40px 0;
        background: #587e85;
    }

    .content-block {
        word-break: break-word;
        background-color: #587e85;
        padding: 40px;
        border-radius: 16px;

        @media (max-width: 767px) {
            padding: 0;
            border-radius: 0;
        }

        h2 {
            color: #a9cf3d;
            @include fontsize(36);
            line-height: 35px;
            font-weight: 600;
            margin: 0 0 24px;

            @media (max-width: 991px) {
                @include fontsize(24);
                line-height: 22px;
                margin: 0 0 18px;
            }
        }

        p {
            margin: 0;
            @include fontsize(18);
            color: $white;
            line-height: 30px;
            font-weight: 400;

            @media (max-width: 991px) {
                @include fontsize(16);
            }

            @media (max-width: 767px) {
                @include fontsize(14);
                line-height: 24px;
            }
        }

        h4 {
            margin: 24px 0 16px;
            @include fontsize(18);
            font-weight: 600;
            color: #a9cf3d;

            @media (max-width: 991px) {
                @include fontsize(16);
            }

            @media (max-width: 767px) {
                @include fontsize(14);
            }
        }

        ul {
            li {
                @include fontsize(15);
                line-height: 24px;
                font-weight: 500;
                color: $white;
                margin-bottom: 15px;
                background: url("../../images/check-mark-button.svg") no-repeat left top 5px;
                list-style: none;
                padding-left: 30px;
            }
        }
    }
}

.personnel-plan-section {
    @include padding(110 0);
    background: url("../../images/physician-and-computer.jpg") no-repeat;
    background-size: cover;

    h2 {
        color: $white;
        @include fontsize(36);
        line-height: 35px;
        font-weight: 600;
        margin: 0 0 24px;
        text-align: center;

        @media (max-width: 767px) {
            @include fontsize(24);
            line-height: 22px;
            margin: 0 0 18px;
        }
    }

    p {
        margin: 0;
        @include fontsize(20);
        color: $white;
        text-align: center;

        @media (max-width: 767px) {
            @include fontsize(16);
        }
    }

    .plan-block {
        max-width: 390px;
        margin: 70px auto 0;
        padding: 50px;
        border-radius: 26px;
        box-shadow: 0 12px 68px 0 rgba(0, 0, 0, 0.1);
        border: solid 3px #a9cf3d;
        background-color: $white;
        text-align: center;

        h3 {
            @include fontsize(22);
            line-height: 22px;
            color: #242f40;
            font-weight: 600;
            margin: 24px 0 20px;
        }

        h4 {
            text-transform: uppercase;
            @include fontsize(65);
            line-height: 65px;
            color: #242f40;
            font-weight: 600;
            margin: 0;
        }

        .button_block {
            margin-top: 45px;
        }
    }
}

.contact-banner {
    background: url("../../images/contact-banner-bg.jpg") no-repeat;
    min-height: 200px;
    background-size: cover;

    @media (max-width: 767px) {
        min-height: 80px;
    }
}

.contact-schedule {
    .schedule-block {
        max-width: 570px;
        margin: 0 auto;
        text-align: center;
    }
}

.support-block-section {
    @include padding(110 0);
    position: relative;
    background-color: $white;

    @media (max-width: 991px) {
        @include padding(50 0);
        background-color: #587e85;
    }

    @media (max-width: 767px) {
        @include padding(1 0 40);
        background-color: #587e85;
    }

    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        content: "";
        max-width: 50%;
        background-color: #587e85;
        z-index: 1;
        height: 100%;
        right: 0;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .support-content {
        position: relative;
        z-index: 2;
        color: $white;

        @media (max-width: 991px) {
            padding-left: 0;
        }

        h2 {
            color: $white;
            margin: 0 0 30px;
        }

        p {
            margin: 0;
            @include fontsize(16);
        }

        .contact-dtl {
            margin-top: 40px;

            ul {
                a {
                    color: $white;
                }

                li {
                    color: $white;
                }
            }
        }
    }

    .reach-us-content {
        @media (max-width: 767px) {
            margin-top: 0px;
        }

        .reach-us-block {
            @media (max-width: 991px) {
                padding-left: 0;
            }

            h2 {
                @media (max-width: 991px) {
                    color: $white;
                }
            }
        }

        .contact-dtl {
            margin-top: 40px;

            ul {
                li {
                    @media (max-width: 991px) {
                        color: $white;
                    }
                }

                a {
                    @media (max-width: 991px) {
                        color: $white;
                    }
                }
            }
        }
    }
}

.create-free-account {
    @include padding(160 0);
    background: #587e85 url(../../images/landing-pages/create-free-account-bg.jpg) no-repeat center right;
    background-size: cover;
    color: $white;
    line-height: 1.66;
    font-size: 24px;
    background-position: bottom right;

    @media screen and (max-width: 1800px) {
        background-size: auto 85%;
    }

    @media screen and (max-width: 1500px) {
        background-position: bottom right;
        background-size: auto 70%;
    }

    @media screen and (max-width: 1280px) {
        padding-top: 100px;
    }

    @media screen and (max-width: 1024px) {
        background-size: auto 62%;
    }

    @media screen and (max-width: 767px) {
        font-size: 14px;
        @include padding(40 0 0);
        background: #587e85;
        overflow: hidden;
    }

    &.personnel-account {
        text-align: center;
        @include padding(100 0);
        background: #fff;
        color: #2d3245;

        @media screen and (max-width: 767px) {
            @include padding(40 0);
        }

        h2 {
            font-size: 36px;

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
        }

        .account-card {
            padding: 65px 30px;
            background: #ffffff;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
            border-radius: 18px;
            max-width: 460px;
            margin: 60px auto 0;
            color: #2d3245;

            @media screen and (max-width: 767px) {
                margin-top: 20px;
                padding: 40px 25px;
            }

            h3 {
                font-weight: 600;
                font-size: 22px;
                line-height: 1;
                margin: 24px 0 0;

                @media screen and (max-width: 767px) {
                    font-size: 20px;
                }
            }

            .free-heading {
                font-size: 65px;
                margin: 24px 0 0;
                line-height: 1.1;

                @media screen and (max-width: 767px) {
                    font-size: 40px;
                    margin-top: 16px;
                }
            }

            .button {
                margin: 24px 0 0;
                min-width: 275px;
                font-size: 14px;

                @media screen and (max-width: 767px) {
                    min-width: auto;
                }
            }
        }
    }

    h2 {
        font-weight: 600;
        font-size: 46px;
        line-height: 1.3;
        margin-bottom: 10px;

        @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 8px;
        }
    }

    .button {
        margin-top: 60px;
        font-size: 18px;
        box-shadow: 4px 5px 14px 0 rgba(0, 0, 0, 0.1);

        @media screen and (max-width: 767px) {
            font-size: 14px;
            margin-top: 8px;
            margin-bottom: 30px;
        }
    }

    .mobile-img {
        margin-right: -15px;
        text-align: right;
    }
}

.about-blog-section {
    background: #f2f3ef;
    @include padding(100 0);

    &.blogs-resources-section {
        background: #F2F3EF;

        .container {
            max-width: 1470px;

            .blog-slider {
                margin: 0 -15px;
                width: calc(100% + 30px);
                position: relative;
            }

            a {
                text-decoration: none;

                .blog-block {
                    padding: 0 15px;
                    border: 0;

                    img {
                        max-width: 100%;
                        border-radius: 10px;
                        margin-bottom: 30px;
                        width: 100%;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        @include padding(40 0 90);
    }

    .title-col {
        margin-bottom: 60px;

        @media screen and (max-width: 767px) {
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 600;
            font-size: 36px;
            line-height: 1.1;
            color: #587e85;

            @media screen and (max-width: 767px) {
                font-size: 20px;
                margin-bottom: 24px;
            }
        }
    }

    .blog-slider {
        .slick-dots {
            bottom: -30px;
        }

        .slick-dots li button {
            padding: 0;
            width: 14px;
            height: 14px;

            @media (max-width: 767px) {
                width: 10px;
                height: 10px;
            }
        }

        .slick-dots li button:before {
            color: transparent !important;
            background-color: #cad3c0;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            line-height: 14px;

            @media (max-width: 767px) {
                width: 10px;
                height: 10px;
                line-height: 10px;
            }
        }

        .slick-dots li.slick-active button:before {
            background-color: $theme;
        }

        .slick-prev,
        .slick-next {
            top: -95px;
            background-size: 22px auto;
            background-position: center;
            bottom: auto;
            background-color: #F2F3EF;

            transform: none;
            left: auto;
            right: 15px;

            @media screen and (max-width: 767px) {
                top: auto;
                bottom: -60px;
            }
        }

        .slick-prev {
            margin-right: 60px;

        }

        .slick-slide {
            transition: all 0.6s cubic-bezier(0.34, 1.56, 0.64, 1);
        }
    }

    .blog-block {
        padding: 0 30px;
        border-left: 1px solid #dddeda;
        min-height: 240px;
        font-size: 22px;
        line-height: 1.66;
        color: #587e85;

        @media screen and (max-width: 767px) {
            font-size: 14px;
            border: 0;
            min-height: auto;

            padding: 20px 0;
            border-bottom: 1px solid #dddeda;
        }
    }

    .date-block {
        font-weight: 600;
        font-size: 24px;
        line-height: 1.66;
        color: #2d3245;
        margin-bottom: 5px;

        @media screen and (max-width: 767px) {
            font-size: 16px;
        }
    }

    .tag-box {
        line-height: 1;

        .tag-single {
            font-weight: 500;
            font-size: 13px;
            line-height: 1.5;
            color: #587e85;
            border-radius: 10px;
            margin-right: 5px;
            margin-bottom: 5px;
            background: #faffeb;
            padding: 4px 12px;
            cursor: pointer;
        }
    }
}

.platform-overview-banner {
    min-height: 1120px;
    background: #f2f3ef;
    @include padding(180 0 80);
    font-size: 28px;
    line-height: 1.7;
    color: #2d3245;
    background-size: 100% auto;
    background: #f2f3ef url(../../images/landing-pages/platform-banner-web.jpg) no-repeat center;

    @media screen and (max-width: 1700px) {
        font-size: 18px;
        min-height: 920px;
        background-size: 99.5% auto;
    }

    @media screen and (max-width: 991px) {
        min-height: 650px;
        background-size: auto 84%;
        padding-top: 100px;
    }

    @media screen and (max-width: 767px) {
        min-height: 450px;
        @include padding(40 0 10);
        font-size: 13px;
        background-position: right top 20px;
        background-size: auto 90%;
        background-image: url(../../images/landing-pages/platform-banner-mobile.jpg);
    }

    .banner-caption {
        @media screen and (max-width: 767px) {
            max-width: 370px;
        }
    }

    h2 {
        font-weight: 700;
        font-size: 56px;
        line-height: 1.3;
        letter-spacing: -1px;
        color: #587e85;
        margin-bottom: 25px;

        @media screen and (max-width: 1400px) {
            font-size: 45px;
        }

        @media screen and (max-width: 991px) {
            font-size: 30px;
        }

        @media screen and (max-width: 767px) {
            font-size: 20px;
            margin-bottom: 10px;

            br {
                display: none;
            }
        }
    }

    p {
        max-width: 540px;

        @media screen and (max-width: 1700px) {
            max-width: 380px;
        }

        @media screen and (max-width: 767px) {
            max-width: 100%;
        }
    }

    .banner-right-col {
        .images-grid {
            position: relative;
            min-height: 600px;

            @media screen and (max-width: 991px) {
                min-height: 500px;
            }

            @media screen and (max-width: 767px) {
                min-height: 250px;
            }

            .img-single {
                position: absolute;
                right: 0;
                top: 100px;
                transition: 0.5s;

                @media screen and (max-width: 991px) {
                    top: 20px;
                }

                &:not(:last-child) {
                    @media screen and (max-width: 1700px) {
                        max-width: 160px;
                    }

                    @media screen and (max-width: 991px) {
                        max-width: 130px;
                    }

                    @media screen and (max-width: 767px) {
                        max-width: 60px;
                    }

                    img {
                        filter: drop-shadow(0px 0px 36.2694px rgba(0, 0, 0, 0.1));
                        max-width: 100%;
                    }
                }

                &.img-2 {
                    margin-top: 30px;
                    margin-right: 82px;

                    @media screen and (max-width: 1024px) {
                        margin-right: 60px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 25px;
                        margin-top: 10px;
                    }
                }

                &.img-3 {
                    margin-top: 60px;
                    margin-right: 164px;

                    @media screen and (max-width: 1024px) {
                        margin-right: 120px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 50px;
                        margin-top: 20px;
                    }
                }

                &.img-4 {
                    margin-top: 90px;
                    margin-right: 246px;

                    @media screen and (max-width: 1024px) {
                        margin-right: 180px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 75px;
                        margin-top: 30px;
                    }
                }

                &.img-5 {
                    margin-top: 120px;
                    margin-right: 328px;

                    @media screen and (max-width: 1024px) {
                        margin-right: 240px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 100px;
                        margin-top: 40px;
                    }
                }

                &.img-6 {
                    margin-top: 150px;
                    margin-right: 410px;
                    z-index: 1;

                    @media screen and (max-width: 1024px) {
                        margin-right: 300px;
                    }

                    @media screen and (max-width: 767px) {
                        margin-right: 125px;
                        margin-top: 50px;
                    }
                }

                &.img-7 {
                    margin-top: 280px;
                    margin-right: 580px;
                    width: 500px;

                    &:hover {
                        top: 100px;

                        @media screen and (max-width: 767px) {
                            top: 20px;
                        }
                    }

                    @media screen and (max-width: 1700px) {
                        margin-right: 520px;
                        margin-top: 220px;

                        img {
                            width: 550px;
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        margin-right: 400px;
                        margin-top: 200px;
                    }

                    @media screen and (max-width: 991px) {
                        max-width: 350px;
                    }

                    @media screen and (max-width: 767px) {
                        max-width: 220px;
                        margin-right: 180px;
                        margin-top: 60px;
                    }
                }

                &:hover {
                    top: -80px;

                    @media screen and (max-width: 1700px) {
                        top: -50px;
                    }

                    @media screen and (max-width: 767px) {
                        top: -20px;
                    }
                }
            }
        }
    }
}

.overview-green-bg-section {
    background: #587e85;
    padding-bottom: 20px;
    position: relative;

    @media screen and (max-width: 767px) {
        background-color: transparent;
        padding-bottom: 50px;

        .container {
            padding: 0;
        }
    }

    .overview-slider {
        position: relative;
        top: -60px;

        @media screen and (max-width: 767px) {
            top: 0;
        }

        .slick-slider .slick-track {
            display: flex;
        }

        .slick-slider .slick-dots {
            bottom: -10px;

            li {
                width: auto;
                height: auto;
            }
        }

        .slick-slide {
            padding: 0 15px 80px;
            height: inherit;

            >div {
                height: 100%;
            }

            @media screen and (max-width: 767px) {
                padding: 0 15px 70px;
            }
        }

        .card-block {
            background-color: $white;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.06);
            border-radius: 18px;
            @include padding(40 40 90 40);
            position: relative;
            height: 100%;

            @media screen and (max-width: 991px) {
                @include padding(40 40 60 40);
            }

            @media screen and (max-width: 767px) {
                @include padding(25 25 60 25);
                box-shadow: 0px 6.30915px 18.9274px rgba(0, 0, 0, 0.06);
            }

            .heading-title {
                font-size: 30px;
                color: #2d3245;
                font-weight: 500;
                position: relative;
                padding-bottom: 40px;
                line-height: 1.5;
                margin: 0;

                @media screen and (max-width: 1800px) {
                    font-size: 25px;
                    max-width: 90%;
                }

                @media screen and (max-width: 991px) {
                    max-width: 100%;
                }

                @media screen and (max-width: 767px) {
                    font-size: 20px;
                    padding-bottom: 20px;

                }

                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 40px;
                    height: 3px;
                    background: #a9cf3d;
                }
            }

            img {
                position: absolute;
                right: 40px;
                bottom: -60px;
                max-width: 100%;
                border-radius: 18px;
                filter: grayscale(100%);
                transition: 0.5s;

                @media screen and (max-width: 1800px) {
                    max-width: 70%;
                }

                @media screen and (max-width: 1600px) {
                    right: 25px;
                    bottom: -50px;
                }

                @media screen and (max-width: 767px) {
                    max-width: 60%;
                }
            }

            &:hover {
                img {
                    filter: grayscale(0%);
                    transform: scale(1.07);
                }
            }
        }
    }

    &.features-green-sec {
        .overview-slider {
            .slick-slide {
                padding: 0 15px;
            }

            .slick-slider .slick-dots {
                bottom: -40px;
            }

            .card-block {
                padding-bottom: 0;
                display: inline-flex !important;
                flex-direction: column;
                justify-content: space-between;

                img {
                    position: relative;
                    filter: grayscale(0%);
                    max-width: 100%;
                    transform: none;
                    margin-left: auto;
                    margin-right: -20px;
                    bottom: -4px;
                    right: 0;

                    @media screen and (max-width: 767px) {
                        margin-right: 0;
                    }

                    &.img-1 {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.width-50 {
    @media (max-width: 991px) {
        width: 50%;
    }
}

.sub-menu-list {

    margin-top: 16px;
    @include pos(ab, 100% 0 null 0);
    min-width: 195px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: $white;
    border-radius: 16px 16px;


    @media (max-width: 991px) {

    }

    ul {
        padding: 0 20px 5px;

        @media (max-width: 991px) {
            padding: 0 20px 0;

        }

        li {
            border-bottom: 1px solid rgba($color: #dae2e2, $alpha: 0.5);

          

            &:last-child {
                border-bottom: 0;
            }

            a {
                @include fontsize(14);
                line-height: 14px;
                font-weight: 500;
                color: #79869a;
                padding: 14px 0;
                text-align: left;
                margin: 0;
                display: block;
                text-decoration: none;

              

                &:hover,
                &:focus {
                    color: $theme;
                }

                &.active {
                    color: $theme;
                }
            }
        }
    }

    .submenu-triangle {
        margin-top: -28px;
        margin-left: 20px;
        @media screen and (max-width: 991px) {
            margin-top: -32px;
        }
    }

}

.header-section {
    nav {
        .custom-menu-list {
            @media screen and (max-width: 991px) {
                overflow-y: visible;

                .customer-menu {
                    top: 100%;
                }
            }
        }
    }
}

#blg-resource {
    padding-top: 0px;
}

#mob-blg-res {
    padding-top: 0px;
}

