.helpdesk-search {
    min-width: 196px;
    width: 196px;
}

.helpdesk-card {
    padding: 30px 30px 5px !important;

    a {
        text-decoration: none;
    }

    .card-status-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
       

        .timer-clock {
            font-weight: 300;
            font-size: 12px;
            color: #6f7788;
        }

        .status-box {
            background: #66accf;
            border-radius: 3px;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            line-height: 1;
            padding: 5px 10px;
            display: inline-block;

            &.pending {
                background: #66accf;
            }

            &.resolved {
                background: #a9cf3d;
            }

            &.progress {
                background: #66accf;
            }

        }
    }

    hr {

        margin-bottom: 12px;
        margin-top: 6px;

    }

}

.chat-box-wrapper {
    margin-bottom: 30px;
    max-height: 500px;
    overflow-y: auto;


    .chat-card {
        background: #f2f3ef;
        border-radius: 6px;
        padding: 20px 20px 5px;
        margin-bottom: 10px;

        &.customer-chat {
            margin-left: 180px;
            background: #f2f3ef;
        }

        &.helpdesk-chat {
            margin-right: 180px;
            background: #f9fcee;
        }
    }
}

.write-message-wrapper {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.083998);
    margin: 0 -30px -5px;
    border-radius: 0 0 10px 10px;
    padding: 15px 30px;

    .input-btn-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
            border: 0;
            width: calc(100% - 130px);
            border-bottom: 1px solid rgba(151, 151, 151, 0.4);
            font-weight: 600;
            font-size: 14px;
            color: #102c42;
            height: 40px;
            padding: 2px 0;
            outline: 0;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #102c42;
                opacity: 0.5;
                /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #102c42;
                opacity: 0.5;
            }

            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #102c42;
                opacity: 0.5;
            }
        }

        button {
            min-width: 11px;
        }
    }
}