.request-form{
    max-width: 450px;
    .white-col-list{
        li{
            margin-bottom: 25px;
            
        }
    }
    .mt-60{
        margin-top: 60px;
        @media screen and (max-width:767px) {
            margin-top: 40px;
        }
    }
    .checkbox{
       margin-bottom: 0;

        span{
            font-weight: 500;
            color: #79869A;
           a{
               font-size: 14px;
           }
        }
    }
    .terms-text{
        @media screen and (max-width:767px) {
            margin-left: 30px;
            margin-top: -8px;
        }
    }
    .family-member-label{
        font-weight: 400;
font-size: 12px;
line-height: 12px;
color: #6F7788;
margin-top: 20px;
margin-bottom: 6px;
    }
    .family-member-value{
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #102C42;
        margin-bottom: 60px;
    }

}
.doctor-detail-card{
    padding-right: 60px;
  
    @media screen and (max-width:991px){
      padding-right: 0;
      border-right:0;
      border-bottom: solid 1px rgba(151, 151, 151, 0.4);
      padding-bottom: 40px;
      margin-bottom: 40px;
      min-height: auto;
      }
      @media screen and (max-width:767px){
        padding-bottom: 20px;
        margin-bottom: 20px;
        }
    .intro-box{
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        @media screen and (max-width:767px){
            display:block
        }
        img{
            border-radius: 100%;
            width: 60px;
            height: 60px;
            margin-right: 18px;
            @media screen and (max-width:767px){
              margin-bottom: 10px;
            }
        }
    }
    
}

.term-cus-text{
    display: inline-flex;
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
    top: -2px;
}

.terms-error{
    margin-left: 30px;
}