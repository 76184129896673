.edit-pan-block{
    .container{
        max-width: 1170px;
        .title{
            margin-top:9px;
        }

        h3{
            font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        color: #587E85;
        margin-bottom: 25px;
        }
        .left-edit{
            width: 24.1%;
          li{
                font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 14px;
margin-bottom: 66px;


color: #102C42;
&:first-child{
    margin-top: 100px;
}

            }

        }

        .middle-edit{
            width: 37.9%;
    padding-right: 30px;
    .middle-edit-inner{
        background: #F9FCEE;
        padding: 30px 30px 0px 30px;
        input{
            background: #F9FCEE;
        }
    }
}
.right-edit{
    width: 37.9%;
    .right-edit-inner{
        background: #F9FCEE;
        padding: 30px 30px 0px 30px;
        input{
            background: #F9FCEE;
        }

    }
}

        .btn-field{
            margin-top: 30px;
        }
    }
}