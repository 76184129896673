
        .custom-box {
            margin-top: 15px;
            input {
                background-color: transparent;
            }
            input.custom-input {
                background-color: #fff;
                width: 100%;
                outline: 0;
                border: 1px solid #dadada;
                font-size: 14px;
                height: 48px;
                padding: 4px 15px;
                border-radius: 5px;
                color: #102c42;
                font-weight: 600;
                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #979d9f;
                    opacity: 0.5; /* Firefox */
                    font-weight: 400;
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #979d9f;
                    font-weight: 400;
                    opacity: 0.5;
                }

                &::-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #979d9f;
                    font-weight: 400;
                    opacity: 0.5;
                }
            }
        }