.vendor-page-pt-16 {
  padding-top: 16px;
}

.vendor-layout {
  a.button {
    line-height: 1.4;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .button.btn-height-40 {
    min-height: 40px;
    padding: 8px 30px;
  }
  .search-box input {
    padding: 15px 15px 15px 41px;
  }
  .member-filter .react-select-container {
    float: none;
  }
  @media screen and (max-width: 767px) {
    .data-table-block .pagnation-block {
      margin-top: 30px;
      margin-bottom: 4px;
    }
    .member-filter .react-select-container .react-select__control {
      min-height: 50px;
    }
  }
  .d-flex.inputdate .react-datepicker-wrapper {
    max-width: 160px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  .d-flex.inputdate.add-promotion-datepicker .react-datepicker-wrapper {
    max-width: 252px;
    @media screen and (max-width: 767px) {
      max-width: 100%;
    }
  }
  .d-flex.inputdate.date-width-100 .react-datepicker-wrapper {
    max-width: 100%;
  }
  .common-tabs .nav-tabs {
    border-color: transparent;
    .nav-link {
      color: #87928d;
      &.active {
        color: #587e85;
      }
    }
  }
  .td-first-col-color {
    .data-table-block .data-table-container .table tbody tr td {
      &:first-child {
        color: #535b5f;
      }
    }
  }
  .profile-setup-block .profile-form .file-upload-field .ch-upload-button {
    box-shadow: 5px 11px 27px rgba(24, 84, 85, 0.248825);
    border-radius: 100%;
  }
}
.tooltip-inner {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
}
.new-item-card-catalogue-tooltip {
  .tooltip-inner {
    max-width: 285px;
    background: #2f3245;
    color: #fff;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    font-family: "Poppins", sans-serif;
    text-align: left;
  }
  .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #2f3245;
  }

  &.default-tooltip {
    .tooltip-inner {
      max-width: 183px;
      text-align: center;
    }
  }
}
.yellow-alert-box {
  background: #fcc539;
  padding: 10px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #2f3245;
  margin-bottom: 30px;
  margin-top: -20px;
  border: 0;
  border-radius: 0;
  width: 100%;

}
.yellow-alert-box-font14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 13px 20px;
  background: #ffba00;
  border-radius: 6px;
  color: #000000;
}
@media screen and (max-width: 767px) {
  .vendor-delete-office-modal {
    .modal-content {
      padding-top: 50px;
      padding-bottom: 10px;
    }
  }
  textarea {
    height: 140px;
    resize: none;
  }
  .new-item-card-catalogue-tooltip {
    .tooltip-inner {
      max-width: 137px;
      padding: 0.25rem 0.5rem;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      border-radius: 0.25rem;
      font-size: 10px;
    }
  }
}

.location-input-wrapper {
  position: relative;
  .location-list {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0628824);
    border-radius: 10px;
    // padding: 12px 20px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 3;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
    li {
      font-weight: 400;
      font-size: 13px;
      color: #111b45;
      padding: 10px 0px;
      margin: 0px 20px;
      border-bottom: 1px solid #eeeff1;
      cursor: pointer;
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
.common-fw-400 {
  @media screen and (max-width: 767px) {
    .data-table-block .data-table-container .table tbody tr td {
      font-weight: 400;
    }
  }
}
.manage-customer-table {
  .data-table-block .data-table-container .table tbody tr td::before {
    width: 42%;
  }
}
.table-td-last-50-invoices {
  @media screen and (max-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
    .data-table-block .data-table-container .table tbody tr {
      margin: 10px;
    }
  }
}
.shadow-responsive {
  @media screen and (max-width: 767px) {
    margin-left: -10px;
    margin-right: -10px;
    .data-table-block .data-table-container .table tbody tr {
      margin: 10px;
    }
  }
}
.common-tabs.tab-left.common-tabs-scroll .nav-tabs {
  @media screen and (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
}

.vendor-order-history-form {
  .select-common-dropdown {
    .selected-item {
      font-size: 14px;
      width: 100%;
      justify-content: space-between;
      border-bottom: solid 1px rgba(151, 151, 151, 0.4);
      padding-bottom: 15px;
    }

    .dropdown-menu {
      padding: 0;
      width: 100%;
      overflow-x: hidden;
      max-height: 300px;

      .dropdown-item {
        padding: 0 20px;
      }

      //For hover and slected colors
      .dropdown-item.selected,
      .dropdown-item.selected:hover {
        background-color: #2977ff;
        span {
          color: #fff;
        }
      }
      .dropdown-item:hover {
        background-color: #6cb5f9;
        span {
          color: #fff;
        }
      }
      ///////////////////////////////////////
      .custom-dropdown-loader.dropdown-item {
        display: flex;
        justify-content: center;
        align-items: center;

        div {
          margin: 9px;
          padding: 9px;
        }
      }
    }
  }
  .d-flex.inputdate .react-datepicker-wrapper {
    max-width: 100%;
  }
}

.link-btn-14 {
  font-size: 14px;
  font-weight: 500;
  color: #587e85;
}

.manage-cust-order-list-table {
  tr {
    th:first-child {
      width: 216px;
    }
    th:nth-last-child(1) {
      width: 148px;
    }
  }
  @media screen and (max-width: 767px) {
    .link-btn-14 {
      font-size: 12px;
      white-space: pre-wrap !important;
    }
  }
}
