.data-table-block .data-table-container {
    box-shadow: unset ;
}

.pms_subtitle{
    color: #000;
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0 !important;
}

.pms_heading{
    color: #111B45;
    font-size: 25px;
    font-weight: 600;
    line-height: 25px;
}

.header_disabled_btn{
    background-color: #EBEBEB;
    color: #B8B8B8;
    border: 2px solid #DDD;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    pointer-events: none;
    white-space: nowrap;

    &:hover{
        background-color: #EBEBEB;
        text-decoration: none;
        outline: none;
        color: #B8B8B8
    }
}

.table thead tr th:first-child::after {
    left: 30px;
}

.table thead tr th:last-child::after {
    left: -30px;
}

.table thead tr th::after {
    left: 0px;
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #dadce0;
}

th{
    position: relative;
}

.ch-checkbox {
    span{
        min-height: 18px;
    }
}