.delete-contract-modal{
    &.modal-dialog{
        max-width: 660px;
        .modal-content{
            padding:70px 30px 60px;
            text-align: center;
            @media screen and (max-width:767px) {
                padding:50px 20px 30px
            }
        }
    }
    .btn-box{
        
        align-items: center;
        justify-content: space-evenly;
        .button {
            min-width: 220px;
           
        }
    }
}