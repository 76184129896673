.steps-tracker {
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    li {
        line-height: 44px;
        position: relative;
        padding: 0;
        margin: 0 26px;
        height: 44px;
        width: 44px;
        cursor: pointer;
        text-align: center;
        @media screen and (max-width: 767px) {
            margin: 0 5px;
        }
        &::before {
            color: #fff;
            position: absolute;
            top: 0%;
            bottom: auto;
            left: 0%;
            float: none;
            width: 44px;
            height: 44px;
            border-radius: 100%;
            display: block;
            line-height: 42px;
            padding: 0;
            background-color: #daddd1;
            border: 2px solid transparent;
            content: "";
        }
        &::after {
            left: 100%;
            content: "";
            height: 2px;
            width: 52px;
            background-color: #daddd1;
            top: 50%;
            position: absolute;
            margin-top: -1px;
            @media screen and (max-width: 767px) {
                width: 10px;
            }
        }
        &:last-child {
            &::after {
                display: none;
            }
        }
        span {
            color: #fff;
            position: absolute;
            padding: 0;
            left: 0;
            font-size: 14px;
            display: block;
            top: 0;
            width: 44px;
            height: 44px;
            border-radius: 100%;
            line-height: 44px;
            font-weight: 600;
        }
        &.doing-list {
            &::before {
                background-color: #fff;
                border-color: #a9cf3d;
            }
            span {
                color: #2f3245;
            }
        }
        &.done-list {
            span {
                font-size: 0;
            }
            &::before {
                // content: "✓";
                content: "";
                background-color: #a9cf3d;
                background-image: url("./../../../../../assets/images/icon-check-white.svg");
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        &.todo-list {
            &::before {
                content: "";
            }
        }
    }
}

.prev-next-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 50px;
    position: relative;
    @media screen and (max-width: 767px) {
        flex-direction: column;
        .button {
            width: 100%;
        }
        > div {
            width: 100%;
        }
    }
    .next-step-col {
        display: flex;
        align-items: center;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            .button {
                margin: 10px 0;
            }
        }
        &.export-step-col{
            @media screen and (max-width: 767px) {

            // .export-btn{
            //     order: 3;
            // }
        }
        }
        .cancel-btn-box{
            @media screen and (max-width: 767px) {
                order: 2;
                margin-top: 10px;
            }
        }
        .cancel-btn {
            margin-right: 25px;
            padding: 6px 25px;
            border-right: 1px solid #d6d8d0;
            @media screen and (max-width: 767px) {
                margin: 0;
                border: 0;
            }
        }
    }
}
.multi-step-form {
    .pell-container {
        margin-bottom: 30px;
        .my-custom-class {
            font-family: "Poppins";
            padding: 4px 10px;
            border: solid 1px #eff3e1;
            background-color: #f9fcee;
            &.no-toolbar {
                display: none;
            }
            .pell-button {
                color: #848b92;
                width: 18px;
                height: 18px;
                margin-right: 10px;
                outline: 0;
                position: relative;
                padding: 0;
                text-align: center;
                font-size: 16px;
                line-height: 18px;
                .color-picker-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    z-index: 1;
                    cursor: pointer;
                    padding: 0;
                    width: 18px;
                    height: 18px;
                }
            }
        }
        .pell-content {
            padding: 14px 0;
            min-height: 160px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.43;
            color: #102c42;
            font-family: "Poppins";
            border: 0;
            border-bottom: 1px solid #dfdfe4;
            height: auto;
            p {
                margin-bottom: 15px;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .vacation-editor1 {
        .ql-editor {
            max-height: 45px;
        }
    }

    .completed-services-row {
        .c-field.mb-0 label {
            text-transform: capitalize;
            @media screen and (max-width: 767px) {
                min-height: 62px;
            }
        }
    }
    .select-sec-card {
        .select-sec-input {
            border-bottom: solid 1px rgba(151, 151, 151, 0.4);
            padding-bottom: 15px;
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
        }
        .section-save-btn {
            padding: 0 33px;
            height: 40px;
            line-height: 38px;
        }
    }
}

.sign-img {
    margin-bottom: 25px;
    img {
        max-width: 250px;
        height: auto;
    }
}
.react-datepicker-popper {
    z-index: 111;
}

.new_contract_step1_associate_CF {
    width: 100%;
    background-color: #f2f3ef;
    margin: 40px 0px;
    .Rectangle {
        width: 3px;
        height: 40px;
        margin: 0 17px 0 0;
        border-radius: 1.5px;
        background-color: #a9cf3d;
        float: left;
    }
    .Job-Title {
        width: 427px;
        height: 20px;
        margin: 51px 0px 10px 17px;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.11;
        letter-spacing: normal;
        color: #111b45;
    }
}
