/*!
*********
license

comman css classes
****************
*/
.font-semibold {
  font-weight: 600;
}

.error-msg {
  color: #ff4550;
  display: block;
  margin-top: 5px;
  font-weight: 400;
  animation: none !important;
  @include fontsize(12);
}

@media (min-width: 992px) {
  .container-smd {
    max-width: 1140px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.back-btn {
  @include fontsize(12);
  font-weight: 500;
  line-height: 10px;
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;

  a {
    color: #587e85;
  }

  ._link {
    color: #587e85;
    text-decoration: underline;
    cursor: pointer;
  }

  span.ico {
    @include margin-right(8);
    display: inline-block;
    vertical-align: middle;
  }
}

.c-field {
  margin-bottom: 30px;

  .inputdate {
    position: relative;
    display: block !important;

    .react-datepicker-wrapper {
      display: block;
    }

    .react-datepicker__input-container {
      display: block;

      &::before {
        content: "";
        right: 0;
        bottom: 15px;
        background: url(./../../images/calendar.svg) no-repeat center;
        z-index: 1;
        width: 20px;
        height: 20px;
        position: absolute;
      }

      .c-form-control {
        position: relative;
        background: transparent;
        z-index: 1;
      }
    }
  }

  & > label {
    color: #79869a;
    padding-bottom: 0;
    margin: 0;
    @include fontsize(13);
    font-weight: 400;
  }

  .helper-label {
    border-bottom: solid 1px rgba($color: #979797, $alpha: 0.4);
  }

  .custom-dropdown-only {
    .select-common-dropdown {
      .selected-item {
        width: 100% !important;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: solid 1px rgba(151, 151, 151, 0.4);
        padding-bottom: 15px;
        box-shadow: none;
        color: #102c42;
        padding-left: 0;
        font-size: 14px;
        font-size: 0.875rem;
        font-weight: 600;
        justify-content: space-between;
      }

      .dropdown-menu {
        transform: none !important;
        width: 100%;
        max-height: 220px;
        margin-top: 21px !important;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 3px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background-color: #e0e0e0;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #587e85;
          border-radius: 10px;
        }
      }
    }
  }

  .c-form-control {
    width: 100%;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: solid 1px rgba($color: #979797, $alpha: 0.4);
    padding-bottom: 15px;
    box-shadow: none;
    color: #102c42;
    padding-left: 0;
    @include fontsize(14);
    font-weight: 600;

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #e7e7e7;
      opacity: 1;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #e7e7e7;
      opacity: 1;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #e7e7e7;
      opacity: 1;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #e7e7e7;
      opacity: 1;
    }

    &:disabled {
      background-color: #e7e7e7;
    }
  }

  &.c-field-lg-none {
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }

  &.error-input {
    .c-form-control {
      border-color: #ff4550;
    }

    .helper-label {
      border-color: #ff4550;
    }
  }

  .has-ico {
    position: relative;

    input {
      padding-right: 30px;
    }

    .eye-ico {
      @include pos(ab, 5px 0 null null);
      cursor: pointer;
    }
  }

  &.c-field-sm {
    margin-bottom: 15px;
  }
}

.filter-section {
  @include margin-top(30);
  display: table;

  width: 100%;
  position: relative;

  .search-box {
    float: right;

    @media (max-width: 767px) {
      position: absolute;
      right: 0;
    }
    @media (max-width: 500px) {
      position: static;
      width: 100%;
      margin-top: 10px;
    }
    &.open {
      left: 0;
      z-index: 1;

      input {
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }

  &.custom-filter-section {
    @media (max-width: 767px) {
      margin-top: 16px;
    }
    .search-box input {
      @media (max-width: 767px) {
        min-height: 50px;
      }
    }
    .custom-search span {
      @media (max-width: 767px) {
        top: 12px;
      }
    }
    .member-filter .react-select-container {
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
      .react-select__control {
        @media (max-width: 767px) {
          min-height: 50px;
        }
      }
    }
    .member-filter span.ico {
      @media (max-width: 767px) {
        top: 11px;
      }
    }
  }
}

.member-filter {
  position: relative;

  span.ico {
    @include pos(ab, 10px null null 15px);
    z-index: 1;

    @media (max-width: 767px) {
      top: 5px;
    }
  }

  .react-select-container {
    width: 207px;
    float: left;

    @media (max-width: 767px) {
      width: 191px;
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    .react-select__control {
      @include padding(0 0 0 30);
      border-radius: 5px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
      background-color: $white;
      border: 0;
      @include fontsize(14);
      font-weight: 500;
      width: 100%;
      min-height: 50px;

      @media (max-width: 767px) {
        min-height: 40px;
      }
    }

    .react-select__single-value {
      color: #77928b;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__dropdown-indicator {
      padding: 0;
      margin-right: 15px;
      color: #77928b;
    }
  }
}

.search-box {
  position: relative;
  width: 263px;

  @media (max-width: 767px) {
    width: auto;
  }

  input {
    @include padding(15 15 15 41);
    border-radius: 5px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
    background-color: $white;
    border: 0;
    @include fontsize(14);
    font-weight: 500;
    width: 100%;

    &:focus {
      outline: none;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #979d9f;
      opacity: 0.4;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: #979d9f;
      opacity: 0.4;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: #979d9f;
      opacity: 0.4;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: #979d9f;
      opacity: 0.4;
    }

    @media (max-width: 767px) {
      padding: 10px 0px 10px 40px;
    }
  }

  span.ico {
    @include pos(ab, 14px null null 15px);

    @media (max-width: 767px) {
      left: 13px;
      top: 13px;
    }
  }
}

.react-datepicker__day--outside-month {
  visibility: hidden;
  h4 {
    margin: 0;
    color: #111b45;
    @include fontsize(25);
    line-height: 25px;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.preloader {
  @include pos(fix, 0 0 0 0);
  overflow: hidden;
  background-color: rgba($color: #23383b, $alpha: 0.5);
  z-index: 20;

  .lds-default {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  }

  span.loading-text {
    position: absolute;
    top: 100%;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    @include fontsize(20);
    font-weight: 500;
    color: #fff;
  }
}

.toast-container {
  max-width: 625px;
  width: 100%;
  margin: 0 auto 35px;
  background-color: #009c50;
  @include padding(18 20 18 68);
  border-radius: 6px;
  box-shadow: 0 2px 26px 0 rgba(0, 0, 0, 0.07);
  position: relative;

  span.ico {
    @include pos(ab, 50% null null 20px);
    @include translateY(-50%);
  }

  p {
    margin: 0;
    color: #ffff;
    @include fontsize(15);
    line-height: 18px;
    padding-right: 15px;
  }

  .close-ico {
    @include pos(ab, 50% 20px null null);
    @include translateY(-50%);
    cursor: pointer;
  }

  &.toast-error {
    background-color: #ff002d;
  }
}

.empty-block {
  text-align: center;
  margin: 50px 0;

  @media (max-width: 767px) {
    margin: 20px 0;
  }

  img {
    margin-bottom: 30px;

    @media (max-width: 767px) {
      margin-bottom: 20px;
      max-width: 60%;
    }
  }

  h4 {
    margin: 0;
    color: #111b45;
    @include fontsize(25);
    line-height: 25px;
    font-weight: 500;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.empty-list-block {
  margin: 0 auto;
  text-align: center;

  a {
    display: inline-block;
    margin-top: 20px;
  }

  .empty-block {
    margin: 50px auto 0;
  }
}

.infinite-scroll-component {
  overflow: inherit !important;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #a9cf3d;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

.mobile-app-container {
  margin: 50px 0;

  .mobile-app-block {
    background-color: #fff;
    border-radius: 25px;
    padding: 100px 50px;
    text-align: center;

    h2 {
      @include fontsize(20);
      color: #102c42;
      font-weight: 600;
      margin: 0 0 30px;

      @media (max-width: 767px) {
        @include fontsize(14);
      }
    }

    .button-block {
      a {
        margin: 0 10px;

        @media (max-width: 767px) {
          display: block;
          margin: 0 0 20px;
        }
      }
    }
  }
}

.button {
  line-height: 1.2;
}

.main-content {
  @include experimental-value(min-height, calc(100vh - 154px));

  @media (max-width: 767px) {
    @include experimental-value(min-height, calc(100vh - 170px));
  }
}

.static-page-content {
  @include padding(50 0);
  @include experimental-value(min-height, calc(100vh - 78px));

  @media (max-width: 767px) {
    @include padding(20 0);
  }

  .title {
    @include fontsize(25);
    line-height: 25px;
    color: #111b45;
    font-weight: 600;

    @media (max-width: 767px) {
      @include fontsize(20);
      line-height: 20px;
    }
  }

  .content-container {
    @include margin-top(30);
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    padding: 30px;

    @media (max-width: 767px) {
      padding: 20px;
    }

    h1 {
      @include fontsize(30);
      margin-bottom: 16px;
    }

    h2 {
      @include fontsize(20);
      color: #102c42;
      margin-bottom: 16px;
      font-weight: 600;
    }

    h3 {
      @include fontsize(18);
      color: #102c42;
      margin-bottom: 16px;
      font-weight: 600;
    }

    h4 {
      @include fontsize(16);
      color: #102c42;
      margin-bottom: 16px;
      font-weight: 600;
    }

    h5 {
      @include fontsize(14);
      color: #102c42;
      margin-bottom: 14px;
      font-weight: 600;
      line-height: 1.2;
    }

    h6 {
      @include fontsize(12);
      color: #6f7788;
      margin-bottom: 18px;
      font-weight: 400;
      line-height: 1.2;
    }

    b,
    strong {
      font-weight: 700;
    }

    ul {
      list-style: disc;
      padding-left: 25px;
      margin-bottom: 20px;
    }

    li {
      margin-bottom: 8px;
    }

    ol {
      list-style: decimal;
      padding-left: 25px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
      @include fontsize(16);
      font-weight: 300;
      line-height: 1.5;
      color: #2d3245;

      @media (max-width: 767px) {
        @include fontsize(14);
        line-height: 1.42;
      }
    }
  }
}

.ck-text {
  ul {
    list-style: disc;
    padding-left: 25px;
    margin-bottom: 20px;
  }

  ol {
    list-style: decimal;
    padding-left: 25px;
    margin-bottom: 20px;
  }
}

.custom-modal {
  .modal-content {
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.03);
    padding: 40px 30px;

    @media (max-width: 767px) {
      padding: 20px 15px 30px;
    }

    .modal-body {
      padding: 0;
      @media (max-width: 767px) {
        padding: 20px 15px 30px;
      }

      .modal-body {
        padding: 0;
      }
    }
  }

  .change-modal-content {
    @media (max-width: 767px) {
      margin-top: 10px;
    }
  }

  .close-btn {
    @include pos(ab, 30px 30px null null);
    cursor: pointer;
    z-index: 1;

    @media (max-width: 767px) {
      @include pos(ab, 10px 10px null null);
    }
  }

  .add-role-modal {
    .add-role-form {
      .title {
        @include fontsize(25);
        line-height: 25px;
        color: #111b45;
        font-weight: 500;
        margin: 0 0 40px;

        @media (max-width: 767px) {
          @include fontsize(20);
          line-height: 20px;
          margin: 0 0 30px;
        }
      }

      .btn-field {
        margin-top: 40px;

        @media (max-width: 767px) {
          margin-top: 30px;

          button {
            width: 100%;
          }
        }
      }
    }
  }

  .delete-role-modal {
    .delete-content {
      p {
        @include fontsize(18);
      }
    }
  }

  .change-plan-modal {
    .delete-content {
      p {
        @include fontsize(18);
      }
    }
  }

  .deactivate-modal {
    .content-block {
      margin-top: 30px;

      p {
        @include fontsize(16);
      }
    }
  }

  .account-deactivate-modal {
    .title {
      @include fontsize(20);
      line-height: 20px;
      color: #111b45;
      font-weight: 500;
      margin: 0 0 20px;
      line-height: 30px;
    }
  }
}

.status-modal {
  h2 {
    @include fontsize(20);
    line-height: 35px;
    color: #111b45;
    font-weight: 500;
    margin: 30px 0 20px;
    text-align: center;
  }
}

.free-trial-modal {
  @media (min-width: 992px) {
    &.modal-lg,
    &.modal-xl {
      max-width: 700px;
    }
  }

  .modal-content {
    @include padding(50);

    @media (max-width: 767px) {
      @include padding(30);
    }
  }

  .modal-body {
    text-align: center;

    .trial-block {
      .title {
        @include fontsize(25);
        line-height: 25px;
        color: #111b45;
        font-weight: 500;
        margin: 20px 0 10px;

        @media (max-width: 767px) {
          @include fontsize(20);
          line-height: 20px;
        }
      }

      p {
        @include fontsize(16);
        font-weight: 300;
      }

      .button-block {
        margin: 40px 0 20px;

        @media (max-width: 767px) {
          margin: 30px 0 20px;
        }
      }
    }
  }
}

.ck-editor__editable:not(.ck-editor__nested-editable) {
  min-height: 400px;
}

.link-btn {
  font-weight: 500;
  color: #587e85 !important;
  font-size: 12px;
  text-decoration: underline !important;
  cursor: pointer;

  &.font-regular {
    font-weight: 400;
  }

  &.font-11 {
    font-size: 11px;
  }

  &.font-color-red {
    color: #e76f2a;
  }
}

.modal-content-padding-small .modal-content {
  @media (max-width: 767px) {
    padding-bottom: 6px;
  }
}

.page-error-block {
  @include padding(100 0);
  text-align: center;

  @media (max-width: 767px) {
    @include padding(30 0);
  }

  .page-error-container {
    position: relative;
    min-height: 450px;
    margin-bottom: 40px;

    @media (max-width: 767px) {
      min-height: 400px;
      margin-bottom: 0;
    }

    .bg-image {
      max-width: 100%;
      @include pos(ab, 0 0 0 50%);
      @include translateX(-50%);
    }

    .page404Wrapper {
      position: relative;
      padding-top: 100px;

      h5 {
        @include fontsize(18);
        color: #556d7d;
        margin: 20px 0 0;
        font-weight: 700;
        line-height: 18px;
      }
    }
  }
}

.modal-backdrop {
  background-color: #23383b;
}

.modal-backdrop.show {
  opacity: 0.8;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */

  .member-filter .react-select__value-container {
    margin-top: 12px;
  }

  .member-filter .react-select__indicator {
    margin-top: 10px;
  }
}

/*
green: #3FAF82

*/
.mobile-pagination-center {
  .pagination {
    @media screen and (max-width: 991px) {
      align-items: center;
      justify-content: center;
    }
  }
}
.w-md-50 {
  @media screen and (min-width: 768px) {
    width: 50% !important;
  }
}

.header-section .box {
  border-radius: 2px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 15px;
  display: none;

  @media (max-width: 991px) {
    display: block;
  }

  .btn {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translate(0%, -50%);
    width: 20px;
    cursor: pointer;

    &.active {
      span {
        &:nth-child(1) {
          animation: ease 0.7s top forwards;
        }

        &:nth-child(3) {
          top: -4px;
        }
      }
    }
  }

  .btn span {
    display: block;
    width: 100%;
    border-radius: 3px;
    height: 2px;
    background: #587e85;
    transition: all 0.3s;
    position: relative;
  }

  .btn span + span {
    margin-top: 5px;
  }

  .active span:nth-child(1) {
    animation: ease 0.7s top forwards;
  }

  .not-active span:nth-child(1) {
    animation: ease 0.7s top-2 forwards;
  }

  .active span:nth-child(2) {
    animation: ease 0.7s scaled forwards;
  }

  .not-active span:nth-child(2) {
    animation: ease 0.7s scaled-2 forwards;
  }

  .active span:nth-child(3) {
    animation: ease 0.7s bottom forwards;
  }

  .not-active span:nth-child(3) {
    animation: ease 0.7s bottom-2 forwards;
  }
}

@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }

  50% {
    top: 10px;
    transform: rotate(0);
  }

  100% {
    top: 10px;
    transform: rotate(45deg);
  }
}

@keyframes top-2 {
  0% {
    top: 5px;
    transform: rotate(45deg);
  }

  50% {
    top: 5px;
    transform: rotate(0deg);
  }

  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }

  50% {
    bottom: 5px;
    transform: rotate(0);
  }

  100% {
    bottom: 5px;
    transform: rotate(135deg);
  }
}

@keyframes bottom-2 {
  0% {
    bottom: 5px;
    transform: rotate(135deg);
  }

  50% {
    bottom: 5px;
    transform: rotate(0);
  }

  100% {
    bottom: 0;
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes scaled-2 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}
.custom-table {
  margin-top: 30px;
}

.custom-pagnation {
  margin-top: 40px;
  float: right;

  @media (max-width: 767px) {
    float: none;
    text-align: center;
  }

  ul {
    display: block;
  }

  li {
    display: inline-block;
  }

  li.active {
    a {
      color: #587e85;
      text-decoration: underline;
      background-color: transparent;
      border: 0;
    }
  }

  li.previous,
  li.next {
    a {
      background-color: #a9cf3d;
      color: $white;
      @include fontsize(18);
    }
  }

  a {
    width: 32px;
    height: 32px;
    text-align: center;
    color: #102c42;
    @include fontsize(14);
    font-weight: 500;
    line-height: 31px;
    border-radius: 50%;
    padding: 0;
    background-color: transparent;
    display: block;
    border: 0;
    text-decoration: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

.ck.ck-editor__main > .ck-editor__editable {
  max-height: 300px;
}

/*react-switch-checkbox*/
.toggle-switch {
  position: relative;
  width: 40px;
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
  top: 4px;
  

  &-checkbox {
    display: none;
  }

  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0;
    border-radius: 20px;
    margin: 0;
    width:40px;
  }

  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;

    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 20px;
      padding: 0;
      line-height: 20px;
      color: white;
    }

    &:before {
      content: "";
      padding-left: 10px;
      background-color: #a9cf3d;
      color: #fff;
    }
  }

  &-disabled {
    background-color: #dae1e9;
    cursor: not-allowed;

    &:before {
      background-color: #dae1e9;
      cursor: not-allowed;
      width:100px;

    }
  }

  &-inner:after {
    content: "";
    padding-right: 10px;
    background-color: #dae1e9;
    color: #fff;
    text-align: right;
  }

  &-switch {
    display: block;
    width: 16px;
    height: 16px;
    margin: 2px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    border: 0;
    border-radius: 100%;
    transition: all 0.3s ease-in 0s;
  }

  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
      width:100px;
    }

    .toggle-switch-switch {
      right: 0px;
    }
  }
}
.common-back-margin {
  margin-top: -19px !important;
}
.custom-overflow-unset {
  overflow: unset;
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}

.no-underline {
  text-decoration: none;
}

.gm-style .gm-style-iw .gm-ui-hover-effect {
  display: none !important;
}

.request-appointment-table {
  table {
    th:last-child {
      width: 25%;
    }
  }

  th {
    border-bottom: 1px solid #dadce0;

    &:focus {
      border-bottom: 1px solid #dadce0 !important;
    }

    &:nth-last-child(2) {
      width: 10%;
    }

    &:nth-last-child(3) {
      width: 17%;
    }

    &:first-child {
      padding-left: 30px !important;
      width: 15%;
    }

    .ch-checkbox {
      top: 7px;

      label {
        margin-bottom: 0;
      }

      span {
        padding-top: 2px;
        min-height: 20px;
        color: #102c42;
        font-size: 12px;
      }
    }
  }
}

.common-search-box {
  position: relative;

  input {
    padding: 13px 15px 13px 41px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    height: 50px;
    border: 1px solid #dadada;
  }

  span.ico {
    position: absolute;
    top: 15px;
    left: 15px;
  }
}

button:focus {
  outline: 0;
}

.button-top-margin {
  margin-top: 10px;
}
.custom-error-top {
  margin-top: -24px;
  margin-bottom: 25px;
}
.custom-scroll {
  .react-select__menu > div {
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
  }
}

.alert-img-icon {
  background: #ffba00;
  border-radius: 6px;
  width: 100%;
  padding: 9px 20px;
  display: flex;

  .alert-image {
    margin-right: 16px;

    img {
      width: 18px;
    }
  }

  .alert-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    width: 98%;
    color: #000000;
  }
}

.button-top-margin {
  margin-top: 10px;
}
.yellow-alert-popup {
  padding: 10px 20px;
  background: #fcc539;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #2f3245;
}
.mb-30 {
  margin-bottom: 0px;
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
  }
}

.text-decoration-link {
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-decoration-line: underline;
  color: #587e85;
}

@media screen and (max-width: 767px) {
  .w-sm-100 {
    width: 100%;
  }

  .btn-mobile-link {
    border: 0;
    text-decoration: underline;
    margin: 0 auto;
    width: auto;
    display: block;
    font-weight: 500;
    font-size: 12px;
  }

  .page-title.page-title-button {
    margin-top: -19px;
  }

  .modal-custom-title {
    max-width: 80%;

    p {
      font-size: 20px;
      line-height: 25px;
    }

    &.title-location-center {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.margin-top-negative {
  @media screen and (max-width: 767px) {
    margin-top: -14px !important;
  }
}

@media screen and (max-width: 991px) {
  .table-td-last-50
    .data-table-block
    .data-table-container
    .table
    tbody
    tr
    td:last-child {
    padding-left: 50% !important;
  }
}

@media screen and (max-width: 991px) {
  .table-td-last-50
    .data-table-block
    .data-table-container
    .table
    tbody
    tr
    td:last-child {
    padding-left: 50% !important;
  }
}

/* Chrome, Safari, Edge, Opera */
// To hide up and down arrow for number inputs
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.notification-dot {
  color: red !important;
  font-size: 20px !important;
  line-height: 1 !important;
  margin: 0px 10px !important;
}

.notification-red-dot img {
  margin-top: -24px;

  margin-left: -5px !important;
}

.disable-btns {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.text-grey {
  color: #b1b1b1;
}

.custom-select-scroll {
  .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }
}

.custom-disabled-field {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}

.custom-cursor-pointer {
  .react-select__control {
    cursor: pointer;
  }
}

.removeCursorPointer {
  cursor: unset !important;
}

.text-decoration-underline {
  text-decoration: underline;
}

.z-index2 {
  z-index: 2;
}
header {
  @media screen and (max-width: 767px) {
    padding: 8px 0px !important;
  }
  .custom-logo {
    .logo img {
      @media screen and (max-width: 767px) {
        width: 89px;
      }
    }
  }
}

.menu-hover-effect {
  &:hover {
    color: #587e85 !important;
  }
}

.w30 {
  width: 30%;
}

.w50 {
  width: 50%;
}

.mw-170 {
  min-width: 170px;
}

.mt-20 {
  margin-top: 20px;
}

.no-wrap-field {
  white-space: nowrap !important;
}