.sam-header {
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: #111b45;
    margin-bottom: 12px;
}

.sam-body-text {
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    color: #535b5f;
}

.sam-button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .button {
        width: 220px;
    }

    .button-shadow {
        margin-right: 25px;
    }

    @media (max-width: 767px) {
        margin-top: 30px;
        flex-direction: column;

        .button-shadow {
            width: 100%;
            display: block;
            margin-bottom: 10px;
            margin-right: 0;
        }

        .button-border {
            border: 0;
            font-size: 12px;
            font-size: .75rem;
            font-weight: 500;
            text-decoration: underline;
            color: #577d84;
        }
    }
}