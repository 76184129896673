$Teal: #587e85;
$HeadingColor: #111b45;
$white: #ffffff;
$blueColor: #66ACCF;
$redColor: #EE4F4F;
$greenColor: #A9CF3D;
$lightGray: #6f7788;
$darkNavyBlue: #102c42;
$PrimaryText: #587e85;
$lightGreen: #F9FCEE;
$LightGray: #dbdbdb;

@mixin buttonDesign($textColor, $textSize, $textWeight, $textPadding, $backgroundColor, $borderRadius, $border) {
    color: $textColor;
    font-size: $textSize;
    font-weight: $textWeight;
    padding: $textPadding;
    background-color: $backgroundColor;
    border-radius: $borderRadius;
    border: $border;
}

@mixin textDesign($textColor, $textSize, $textWeight) {
    color: $textColor;
    font-size: $textSize;
    font-weight: $textWeight;
}

.global-main-page {
    .account-preferences-heading {
        @include textDesign(#111B45, 25px, 500);
    }

    .manage-card-container {
        flex-wrap: wrap;
        gap: 30px;

        @media screen and (max-width:768px) {
            gap: 10px;
        }

        .card-con {
            padding: 30px;
            width: 31%;
            min-width: 290px;
            border-radius: 15px;
            background-color: #FFFFFF;
            text-decoration: none;

            @media screen and (max-width:768px) {
                margin-top: unset;
                padding: 20px;
                width: 100%;
            }

            .account-preference-card {
                border: none;
                gap: 20px;

                .account-preference-text {
                    @include textDesign($PrimaryText, 16px, 600);
                }
            }
        }
    }

    .message-templates {
        .message-templates-heading {
            // margin-bottom: 30px;

            @media screen and (max-width:768px) {
                flex-direction: column;
                gap: 20px;
                margin-bottom: 20px;

            }

            .select-office {
                @include textDesign(#111B45, 25px, 500);

            }
        }

        .message-templates-card {
            padding: 30px;
            margin-bottom: 10px;

            .schedule-update {
                display: flex;
                justify-content: space-between;

            }
            .show-message{
                .show-message-box{
                    margin-top: 10px;
                  background-color: $lightGreen;
                  padding: 15px;
                  .file-icon-box {
                    display: flex;
                    margin-top: 18px;
                    gap: 10px;
                    // margin-bottom: 20px;

                    span {
                        font-size: 14px;
                        font-weight: 600;
                        color: #102C42;
                    }
                }
                }
            }

        }

        .office-dropdown-menu {
            box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.06);
            padding: 20px;
            border: none;
            border-radius: 10px;

            .office-dropdown-item {
                border-bottom: 1px solid #D8D8D8;
                font-size: 13px;
                font-weight: 400;
                padding: 12px 0;
                background-color: transparent;
                color: #111b45;

                &:last-child {
                    border-bottom: none;
                    padding-bottom: 0px
                }

                &:first-child {

                    padding-top: 0px
                }
                .office-dropdown-link{
                    text-decoration: none;
                }

            }
        }

        .global-preference-form {

            .message-form-container {
                display: flex;
                flex-direction: column;
                width: 100%;

                @media (max-width: 767px) {
                    width: 100%;
                }

                .message-form {
                    background-color: #F9FCEE;
                    padding: 20px;

                    .form-input-box {
                        width: 100%;
                        font-size: 13px;
                        font-weight: 400;
                        color: #79869A;

                        .form-icon-box {
                            margin-top: 18px;
                            display: flex;
                            justify-content: space-between;



                            



                        }



                    }

                    .file-input-container {
                        span {
                            font-size: 13px;
                            font-weight: 400;
                            color: #79869A;
                        }

                        border-bottom: 1px solid #C4C4C3;

                        .file-icon-box {
                            display: flex;
                            margin-top: 18px;
                            gap: 10px;
                            // margin-bottom: 20px;

                            span {
                                font-size: 14px;
                                font-weight: 600;
                                color: #102C42;
                            }
                        }

                        .attach-file-btn {
                            background-color: unset;
                            border: none;
                            text-decoration: underline;
                            font-size: 12px;
                            font-weight: 500;
                            color: #587E85;
                        }

                        .file-input-box {
                            visibility: hidden;
                            width: 0;
                        }
                    }


                }


            }
        }

        .btn-container {
            margin-top: 30px;
        }
    }
    .preview-btn {
        background-color: unset;
        border: none;
        text-decoration: underline;
        font-size: 12px;
        font-weight: 500;
        color: #587E85;
    }
}