.pms_doctors_table{

     th{
         &:first-child{
             padding-left: 30px !important;          
         }
 
         padding-top: 16px !important;
         padding-bottom: 10px !important;
         padding-right: 0px !important;
         background-color: white !important;
         border-bottom:none !important;
     }
 
     tr{
         td{
             @media (min-width: 992px) {
                width:20% !important;
                
                &:first-child{
                    width:0% !important;   
                        
                }
                &:nth-child(4){
                    width:25% !important;
                }
                            
             }
         }
     }
 
     tbody{
         border:none !important;
     }
   
 }
 .scheduler-tabs{
    .text-decoration-none{
        top:3px !important;
        left:9px !important;
    }
 }

 .patient_checkbox{
    position: absolute !important;
    top:3px !important;
    left:9px !important
 }


  .table thead tr th:first-child::after {
    left: 30px;
    position: absolute;
    right: 0;
    bottom: 0;
    content: "";
    height: 1px;
    width: 100%;
    background-color: #dadce0;
}

.globalNotificationText {
    color: #79869A;
    font-weight: 400;
    font-size: 13px;
}