/*Modal CSS*/

.complete-appointment-modal-dialog{
  max-width: 660px;
  button{
      min-width: 220px;
      @media screen and (max-width:767px) {
          min-width: auto;
          margin-bottom: 20px;
      }
  }
}