.data-table-block {
    &.support-table-block {
        .pagnation-block {
            margin-top: 80px;

        }
    }
}


.c-field-label {
    label {
        font-size: 12px;
        color: #6F7788;
    }
}