.vendor-table {
    .data-table-block {

        .pagnation-block {
            margin-top: 80px;
        }
    }
}

.manage-vendor-card{
    margin-top: 37px;
    
}

.vendor-profile-title {
    font-weight: 500;
    margin-bottom: 30px;
}