.hour-rate-table{
    tbody{
        @media screen and (max-width:991px) {
            td:last-child{
                text-align: left !important;
            }
        }
    }
}

.change-modal-content {
    b {
        font-weight: bold;
    }
}

.email-content-container {
    background: #F9FCEE;
    padding: 20px;
    margin-bottom: 30px;
}

.preview-close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    z-index: 1
}