.appointment-calendar-wrapper {
    position: relative;

    .show-calendar {
        .appointment-calendar {
            display: block;
        }
    }

    .appointment-calendar {
        position: absolute;
        right: 0;
        top: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
        width: 336px;
        border-radius: 15px;
        background-color: #fff;
        padding: 10px 15px;

        @media screen and (max-width: 767px) {
            left: 0;
        }

        .react-calendar__navigation button {
            min-width: 25px;

            &:disabled {
                background-color: unset;
            }

            &:enabled:hover {
                background-color: unset;
            }

            &:enabled:focus {
                background-color: unset;
            }
        }

        .react-calendar__navigation {
            height: 40px;
            margin-bottom: 0;

            .react-calendar__navigation__label {
                font-size: 14px;
                font-weight: 600;
                color: #587e85;
            }

            .react-calendar__navigation__arrow {
                font-size: 0;
                background-repeat: no-repeat;
                background-position: center;

                &.react-calendar__navigation__prev2-button {
                    background-image: url(./../../../../assets/images/calendar-arrow-left-double.svg);
                }

                &.react-calendar__navigation__next2-button {
                    background-image: url(./../../../../assets/images/calendar-arrow-right-double.svg);
                }

                &.react-calendar__navigation__prev-button {
                    background-image: url(./../../../../assets/images/calendar-arrow-left.svg);
                }

                &.react-calendar__navigation__next-button {
                    background-image: url(./../../../../assets/images/calendar-arrow-right.svg);
                }
            }
        }

        .react-calendar__month-view__weekdays {
            text-align: center;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 13px;
            color: #2f3245;

            .react-calendar__month-view__weekdays__weekday {
                abbr {
                    font-size: 14px;
                    color: #2f3245;
                    text-decoration: none;
                }
            }
        }

        .react-calendar__month-view__days {
            button {
                position: relative;

                .disabled-tooltip {
                    font-family: "Poppins";
                    font-size: 10px;
                    line-height: 1.4;
                    white-space: nowrap;
                    top: -20px;
                    opacity: 1;
                    background: #2f3245;
                    padding: 5px 10px;
                    border-radius: 6px;
                    left: 50%;
                    transform: translateX(-50%);
                    display: none;
                    color: #fff;

                    &::after {
                        border-width: 10px 10px 0;
                        content: "";
                        border-color: transparent;
                        border-style: solid;
                        border-top-color: #2f3245;
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        margin-left: -10px;
                    }
                }

                &:disabled {
                    overflow: visible !important;

                    &:hover {
                        .disabled-tooltip {
                            display: block;
                        }
                    }
                }

                &.react-calendar__tile--active {
                    abbr {
                        background-color: #587e85;
                        border-color: transparent;
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

.request-appointment-time {
    .rc-time-picker-input {
        background-color: transparent;
        width: 100%;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: solid 1px rgba(151, 151, 151, 0.4);
        padding-bottom: 15px;
        box-shadow: none;
        color: #102c42;
        padding-left: 0;
        font-size: 14px;
        outline: 0;
        font-weight: 600;
        height: auto;

        &:disabled {
            background-color: transparent;
        }
    }
}

.button {
    min-height: 52px;
    height: auto;
    line-height: 1.2;
    padding: 14px 30px;

    &.custom-btn {
        @media screen and (max-width:991px) {
            min-height: 36px;
            padding-top: 0px;
            padding-bottom: 0px;
        }
        @media (min-width:426px) and (max-width:444px) {
            min-height: 36px;
            padding: 0px 25px;
           
        }
    }
    &.manage-subscription-btn{
        @media screen and (max-width:991px) {
            min-height: 52px;
          
        }
    }
    &.manage-subscription-btn2{
        @media screen and (max-width:991px) {
            min-height: 52px;
            padding-top: 14px;
         
        }
    }
}

a.button {
    line-height: 1.4;
}

.bookAppointmentBtn {
    min-height: 52px;
    height: auto;
    line-height: 1.2;
}

.react-calendar {
    .react-calendar__navigation button {
        min-width: 34px;
    }

    .react-calendar__month-view__weekdays__weekday {
        padding: 0.1em;
    }
}

.custom-outer-div {
    padding-left: 70px;
    margin-left: auto;

    @media screen and (max-width:991px) {
        padding-left: 0px;
    }

    .yellow-alert-box-font14 {
        padding-left: 14px;
        padding-right: 14px;
    }

    .family-member-label {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #6F7788;
        margin-top: 20px;
        margin-bottom: 6px;
    }

    .family-member-value {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #102C42;
        margin-bottom: 30px;
    }

}