.into-card {
    line-height: 2;
    @media screen and (max-width: 767px) {
        padding: 20px !important;
    }
}
.expand-collapse-wraper {
    margin: 40px 0;
    display: flex;
}
.contract-detail-accordion {
    border: 0;
    border-radius: 0;
    .accordion__item {
        background-color: #fff;
        margin-bottom: 10px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        & + .accordion__item {
            border: 0;
        }
    }
    .accordion__button {
        background: transparent;
        position: relative;
        font-size: 16px;
        font-weight: 600;
        color: #587e85;
        padding: 25px 50px 25px 30px;
        @media screen and (max-width: 767px) {
            padding: 20px 40px 20px 20px;
        }
        &::before {
            position: absolute;
            right: 20px;
            transform: rotate(45deg);
            top: 30px;
            width: 8px;
            height: 8px;
            border-bottom-color: #77928b;
            border-right-color: #77928b;
            @media screen and (max-width: 767px) {
                right: 10px;
                top: 25px;
            }
        }
        &[aria-expanded="true"]::before {
            transform: rotate(-135deg);
        }
        &:hover {
            background: transparent;
        }
    }
    .accordion__panel {
        padding: 0 30px 30px;
        font-size: 14px;
        font-weight: 600;
        color: #102c42;
        @media screen and (max-width: 767px) {
            padding: 0 20px 20px;
        }
        .c-field {
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        p {
            &:last-child {
                margin: 0;
            }
        }
    }
    &.associate-accordion{
       .accordion__panel{
           position: relative;
           @media screen and (max-width:767px) {
            &:nth-child(2){
                padding-top: 20px;
                &:before{
                    position: absolute;
                    content:"";
                    left:20px;
                    right: 20px;
               background: #dadce0;
                    height: 1px;
                    top: 0;
                }
            }
        }
           .panel-body{
            border-top: solid 1px #dadce0;
            padding-top: 30px;
            @media screen and (max-width:767px) {
                background: #f9fcee;
                border: 0;
                padding:20px;
                border-radius: 20px;
                label{
                    border-bottom: solid 1px #eee;
                    display: block;
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                }
            }
           }
       
       } 
    }
}
.review-card-container {
    font-size: 14px;
    font-weight: 600;
    color: #102c42;
    @media screen and (max-width: 767px) {
        padding: 20px !important;
    }
    p {
        &:last-child {
            margin: 0;
        }
    }
    .c-field {
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .sign-img {
        margin-bottom: 25px;
        img {
            max-width: 250px;
       height: auto;
        }
    }
}
.btn-wraper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 40px 0;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
        justify-content: space-between;
    }
    .btn-box {
        padding: 3px 25px;
        border-right: 1px solid #d6d8d0;
        @media screen and (max-width: 767px) {
            order: 2;
            border: 0;
            padding: 3px 10px;
        }
    }
    .button {
        margin-left: 25px;
        @media screen and (max-width: 767px) {
            width: 100%;
            order: 1;
            margin-bottom: 30px;
            margin-left: 0;
        }
    }
}
