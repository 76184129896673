.table th {
    border:none;
}
.pms_sent_invite{
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-decoration-line: underline;
    color:#587E85
}

.pms_table_data{
    color: #535B5F;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.pms_table_heading{
    color: #102C42;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px; 
    white-space: no-wrap;
}

        .pms_doctors_table{

             th{
                 &:first-child{
                     padding-left: 30px !important;          
                 }
         
                 padding-top: 16px !important;
                 padding-bottom: 10px !important;
                 padding-right: 0px !important;
                 background-color: white !important;
                 border-bottom:none !important;
             }
         
             tr{
               
                 td{
                     @media (min-width: 992px) {
                         width:29% !important;              
                     }
                 }
             }
         
             tbody{
                 border:none !important;
                 tr{
                    &:first-child{
                    //  border-top: 1px solid #dee2e6 !important;
                     }
                 }
             }
           
         }
   

 

