.catalogue-add-new-item .page-title{
margin-top: 3px !important;
margin-bottom: 30px !important;
}
#upload-error-modal{
    .modal-content{
        padding: 50px 30px 30px;
        .list-group{
            max-height: 345px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #E0E0E0;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #587E85;
        border-radius: 10px;
    }








        .list-group-item{
            border: none;
    border-bottom: 1px solid #DADCE0;
    margin-bottom: 16px;
    padding-left: 0px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #535B5F;
    padding-bottom: 13px;
    word-break: break-word;

    &:nth-last-child(1){
        margin-bottom: 0px !important;
    }
    img{
        margin-right: 8px;
    }
        }

    }
    button{
        margin-top: 40px;
    }
}}

.import-catalogue-modal{
    .modal-content{
        padding: 50px 30px 30px;
        @media screen and (max-width: 767px) {
            padding: 40px 20px 20px;
        }
        .sub-content{
            margin-left: 25px;

        }
    }
}