.timesheet-page {
  padding-bottom: 80px;
  @media screen and (max-width: 991px) {
    padding-bottom: 40px;
  }
  .sub-head {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;

    color: #000000;
    margin-top: 10px;
    margin-bottom: 13px;
    @media screen and (max-width: 991px) {
      margin-bottom: 30px;
      margin-top: 6px;
    }
    &.date-head {
      margin-bottom: 30px;
    }
  }
  .left-main {
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
    .left-container {
      @media screen and (max-width: 767px) {
        flex-direction: column;
        order: 2;
        width: 100%;
      }
      .fix-index {
        z-index: 2;
      }

      .calendar-box {
        display: flex;

        @media screen and (max-width: 991px) {
          justify-content: space-between;
          width: 100%;
        }

        > div {
          margin-left: 30px;

          @media screen and (max-width: 991px) {
            margin-left: 15px;
            margin-bottom: 15px;
            width: calc(50% - 15px);
          }
          @media screen and (max-width: 767px) {
            margin-left: 0px;
          }

          input {
            background: transparent;
          }
        }
      }
    }
    .search {
      @media screen and (max-width: 767px) {
        order: 1;
        width: 100%;
      }
    }
  }
  .holidays-description {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #535b5f;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }

    .holidays {
      font-weight: 500;
    }
  }

  .export-timesheet-btn {
    margin-bottom: 30px;

    @media screen and (max-width: 767px) {
      width: 100%;
      min-height: 50px;
    }
  }

  .timesheet-detail-img {
    width: 180px;
    margin-top: -50px;
    float: right;
    border-radius: 50%;
    margin-bottom: 30px;
    max-height: 180px;
    @media screen and (max-width: 767px) {
      order: 1;
      width: 84px;
      margin-top: 0px;
      float: none;
      margin-bottom: 10px;
      max-height: 84px;
    }
  }

  .timesheet-detail-card {
    background: #587e85;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
      display: block;
    }

    .left-side-card {
      padding: 23px 100px 23px 30px;
      width: 24%;
      border-right: 1px solid #698b91;

      @media screen and (max-width: 1180px) {
        padding-right: 30px;
      }
      @media screen and (max-width: 991px) {
        padding: 20px 20px 20px 20px;
        width: 100%;
        border-bottom: 1px solid #698b91;
        border-right: none;
      }

      .total-hours {
        p {
          line-height: 24px;
          @media screen and (max-width: 991px) {
            margin-bottom: 20px;
          }
        }
      }

      &.left-side-detail-card {
        padding: 23px 55px 23px 30px;
        width: 32%;

        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1180px) {
          padding-right: 30px;
        }
        @media screen and (max-width: 991px) {
          padding: 20px 20px 0px 20px;
          width: 100%;
          display: block;
        }
      }

      &.left-side-date-card {
        padding-right: 3%;
        width: 18.9%;
        @media screen and (max-width: 1180px) {
          padding-right: 5px;
        }
        @media screen and (max-width: 991px) {
          padding: 20px 20px 0px 20px;
          width: 100%;
        }
      }
    }

    .right-side-card {
      padding: 23px 30px 23px 30px;
      width: 90%;
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1180px) {
        padding-left: 30px;
      }
      @media screen and (max-width: 991px) {
        display: block;
        width: 100%;
        padding: 20px 20px 0px;
      }

      div {
        @media screen and (max-width: 991px) {
          float: left;
          width: 50%;
          p {
            &:nth-child(2) {
              @media screen and (max-width: 991px) {
                margin-bottom: 20px;
              }
            }
          }
        }
        &:last-child {
          @media screen and (max-width: 991px) {
            float: none;
            width: 50%;
          }
        }
      }

      &.right-side-detail-card {
        padding-left: 55px;
        width: 59.5%;
        @media screen and (max-width: 991px) {
          width: 100%;
          padding: 20px 20px 0px;
        }
      }

      &.right-side-date-card {
        padding-left: 3%;
        width: 81.1%;
        @media screen and (max-width: 1180px) {
          padding-left: 5px;
        }
        @media screen and (max-width: 991px) {
          padding: 20px 20px 0px;

          width: 100%;
        }
      }
    }
  }

  .action-container {
    margin-bottom: 30px;
    .timesheet-id{
      color:#274642;
      font-size: 16px;
      font-weight: 600;
    }

    @media screen and (max-width: 991px) {
      margin-bottom: 20px;
    }
    .ch-checkbox {
      span {
        font-size: 16px;
        color:#274642 !important;

        &::before{
          top:2px;
       
        }

        &::after{
          top:6px !important;
  
        }

      }
    }
  }

  .checkbox_link_wrapper{
    display: flex;
    gap: 10px;
    align-items: start;
    .width20{
      min-width: 20px;
    }
  }

  .timesheet-task-card {
    @media screen and (max-width: 991px) {
      padding: 20px;
    }
    .task p {
      @media screen and (max-width: 991px) {
        margin-bottom: 0px;
      }
    }
    .card-content-main {
      margin-bottom: 30px;

      .card-content {
        margin-right: 3.2%;
        display: inline-block;
        vertical-align: top;

        &.card-content-small {
          width: 11.6%;
          @media screen and (max-width: 991px) {
            width: 46%;
          }
        }

        &.card-content-large {
          width: 26.2%;
          @media screen and (max-width: 991px) {
            width: 100%;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .label-head p {
      line-height: 13px;
      @media screen and (max-width: 991px) {
        margin-top: 20px;
      }
    }
    &:last-child {
      @media screen and (max-width: 991px) {
        margin-bottom: 0px;
      }
    }
  }
}
.rejection-modal {
  .modal-custom-title p {
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .button-container {
    margin-top: 35px;
  }
}
