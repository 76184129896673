.leaves-table {
    .data-table-block {
        .data-table-container {
            border-radius: 0px;
            box-shadow: none;
            background-color: none;

            .table {

                margin-top: 30px;
                thead {
                    th {
                        background-color: transparent;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        &:first-child{
                            padding-left: 0;
                        }

                    }
                }

                tbody {
                    tr {
                        @media screen and (max-width: 991px) {
                            background-color: #F9FCEE;
                            box-shadow: none;
                        }
                        &:last-child {
                            border-bottom: 1px solid #dee2e6 !important;
                        }

                        td {
                            &:last-child {
                                width: 16%;

                                @media screen and (max-width: 1180px) {
                                    width: 20%;
                                    padding: 20px 20px 20px;
                                }
                                &:after {
                                    left: 0;
                                }
                            }

                            &:first-child {
                                padding-left: 0px;
                                font-weight: 400;
                                font-size: 14px;
                                color: #535B5F;
                                &:after {
                                    left: 0;
                                }
                            }
                            &::before{
                                @media screen and (max-width: 991px) {
                                width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .pagnation-block {
            margin-top: 70px;
            margin-bottom: 36px;
            @media screen and (max-width: 991px) {
margin-top: 30px;
margin-bottom: 20px;
            }
        }
    }


}

.leaves-page{
    .member-filter  .react-select-container {
        width: 90px;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-bottom: 16px;
        }

       .react-select__control {

        background-color: #FBFBFB;
        box-shadow: none;
        }
    }
    .status-div{
    .status{
        display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;



    }

}}
.apply-leaves-page{
    .heading{
        margin-top: -16px;
        @media screen and (max-width: 767px) {
            margin-top: -5px;
            margin-bottom: 10px;
            font-weight: 600;
            line-height: 18px;
        }
    }
    .input-disabled{
        input[type="text"]:disabled {
            opacity: 0.4;
        }
    }
}

.backup-modal{
    .modal-content{
        padding: 50px 30px 30px;
        @media screen and (max-width: 767px) {
            padding: 20px 15px 16px;

        }
    }
    .add-top{
        margin-top: 10px;
        @media screen and (max-width: 767px) {
            margin-top: 0px;
        }
    }
}