



.manage-feature-card{
    padding: 30px;
    .main-border-container{
        margin-top: 44px;
        margin-bottom: 44px;
        label{
            font-weight: 400;
font-size: 10px;
line-height: 12px;
color: #87928D;
margin-bottom: 0px;
margin-right: 6px;
        }
        .border{
            margin-right: 4px;
        }
        .inner-border-container{
            margin-right: 25px;
            display: flex;
            align-items: center;
        }
    }
    .border{
        border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 2px;
width: 12px;
height: 12px;
display: inline-block;

&.green{
    background: #A9CF3D;
}
&.blue{
    background: #66ACCF;
}
&.orange{
    background: #FFA630;
}
    }
    .upper-table{
        width: 100%;
        th {
            padding: 30px 20px;
            background: #F9FCEE;
            border: 1px solid #DEE0E4;
            font-weight: 600;
            font-size: 22px;
            line-height: 22px;
            /* identical to box height, or 100% */

            text-align: center;

            color: #242F40;

            width: 21%;

            &:nth-child(1) {
                width: 37%;
                background: #FFFFFF;
                border: none;
            }






        }

    }
.manage-feature-table {
    width: 100%;
    margin-bottom: 10px;

label{
margin-bottom: 0px;
}

    tr {
        color: #2D3245;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;

        &:nth-child(even) {
            background-color: #FFFFFF;
        }

        &:nth-child(odd) {
            background-color: #F2F3EF;
        }

        th {
            padding: 18px 20px;
            background-color: #587E85;
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            color: #FFFFFF;
            width: 21%;

            &:nth-child(1) {
                width: 37%;
            }






        }

        td {
            text-align: center;
            padding: 15px 0px;

            border: 1px solid #DADCE0;
            border-radius: 10px;


            &:nth-child(1) {
                text-align: left;

                padding-left: 20px;


            }


        }
    }
}
button{
    margin-top: 30px;
}
}