.rating-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 12px;

    > img {
        width: 14px;
        height: 14px;
    }
}