.manage-feature-page{
    .container{
        max-width: 1170px;

        .page-title{
            margin-bottom: 30px !important;
            margin-top: 9PX !important;
        }
        .ch-checkbox span:before{
            top:4px;
            cursor: pointer;
        }
    }

    .disabled-check{
        opacity: 0.5;
    }
}