.add-tax {

    a {

        line-height: 1.4;

    }
}

.tax-table {
    .data-table-block {
        .data-table-container {
            .table {
                tbody {
                    tr {
                        td {


                            &:first-child {
                                font-weight: 400;
                                color: #535b5f;
                                width: 39.2%;

                            }

                            &:nth-child(2) {

                                width: 41.5%;

                            }

                            &:nth-child(3) {

                                width: 10.7%;

                            }

                            &:nth-child(4) {

                                width: 8.6%;

                            }

                            span {
                                font-size: 12px;

                                &:hover {
                                    color: #a9cf3d;
                                }

                            }


                        }



                    }

                }

            }

        }

        .pagnation-block {
            margin-top: 80px;

        }

    }
}

.tax-detail-modal {
    .modal-content {
        .small-icon {
            position: absolute;
            top: 30px;
            right: 90px;
            cursor: pointer;

            z-index: 1;

            &.divider {
                height: 30px;
                right: 72px;
                border-right: 1px solid #DADCE0;
                cursor: initial;
            }

        }



        .modal-body {


            .ch-radio {
                margin-bottom: 30px;
            }

            .last-field {
                padding-bottom: 10px;
            }

            table {
                font-size: 14px;
                width: 100%;

                margin-top: 25px;
            }

            th {
                width: 70%;
                border-bottom: 1px solid #dadce0;
                padding: 15px 0;
                font-weight: 600;
                font-size: 12px;
                color: #102c42;
            }

            td {
                border-bottom: 1px solid #dadce0;
                padding: 15px 0;
                color: #535b5f;
            }


        }
    }
}