.parent-currency-container {
  display: flex;
  align-items: center;

  .currency-container {
    white-space: nowrap;
    align-items: center;
    padding: 3px 16px 0px 16px;
    border-radius: 100px;
    background-color: #ffffff;
    
    .currency {
      font-weight: 600;
      font-size: 12px;
      color: #102c42;
    }
  }
}
