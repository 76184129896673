.dialer-modal-dialog{
    max-width: 383px;
    .close-btn{
        right: 0;
        top: 0;
    }
    .modal-content{
        border-radius: 10px;
        background: #42414C;
        padding: 20px;
        color: #BFBFC8;
        font-size: 12px;
        h5{
            font-size: 12px;
            font-weight: 700;
            color: #FBFBFE;
            margin-bottom: 15px;
        }
        .ch-checkbox {
            margin-bottom: 15px;
            & >label{
                color: #BFBFC8;
            }
     
                span{
                    color: #BFBFC8;
                    font-size: 12px;
                    &:before{
                       border-color: #BFBFC8;
                    }
                }
               
        }
        .ch-checkbox input[type="checkbox"]:checked + span, .ch-checkbox input[type="checkbox"]:checked ~ span, .ch-checkbox input[type="radio"]:checked + span, .ch-checkbox input[type="radio"]:checked ~ span,
        .ch-checkbox input[type="checkbox"]:checked + span::after, .ch-checkbox input[type="checkbox"]:checked ~ span::after, .ch-checkbox input[type="radio"]:checked + span::after, .ch-checkbox input[type="radio"]:checked ~ span::after{
            color: #BFBFC8; 
        }
        .btn-box{
            text-align: right;
            button{
                background: #2B2A32;
                border-radius: 4px;
                font-weight: 700;
                font-size: 12px;
                color: #FBFBFE;
                border: 0;
                margin-left: 8px;
                padding: 9px 16px;
                &:hover, &:focus{
                    border: 0;
                }
            }
        }
    }
}


// Book Appointment Modal scss

.book-appointment-modal {
    &.modal-dialog {
        max-width: 660px;

        .modal-content {
            padding: 50px 30px 30px;

            @media screen and (max-width:767px) {
                padding: 50px 20px 30px
            }

            .modal-body {
                .infinite-scroll-component {
                    overflow: auto !important;
                    max-height: 242px;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                p:nth-child(1) {
                    line-height: 32px;
                    @media screen and (max-width:420px) {
                       font-size:20px;
                       line-height: 25px;
                       margin-bottom: 20px;
                    }
                }

                li {
                    padding: 10px;
                    border-bottom: 1px solid #DADCE0;

                    &:last-child {
                        border: none;
                    }

                    .image-profile {
                      max-width: 40px;
                        img {
                            width: 40px;
                            height: 40px;
                            border-radius: 9px;
                        }
                    }

                    .relationship {
                        width: 87%;

                        p:nth-child(1) {
                            line-height: 14px;
                            @media screen and (max-width:420px) {
                                font-size:14px;
                                line-height: 20px;
                                margin-bottom: 2px;
                             }
                        }

                        p:nth-child(2) {
                            line-height: 12px;
                            @media screen and (max-width:420px) {
                              
                                line-height: 12px;
                             }
                        }
                    }
                }
            }
        }
    }
}