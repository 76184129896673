.questionnaire-page {

  @media screen and (max-width: 767px) {
      margin-top: 0;
  }
  .card-box {
      padding: 30px !important;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.083998) !important;
      border-radius: 10px !important;
      margin-bottom: 10px;
      cursor: default !important;
      @media screen and (max-width: 767px) {
          padding: 20px !important;
      }
      .question-box {
          margin-bottom: 20px;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.33;
          color: #587e85;
      }
      .option-list {
          li {
              padding: 10px 0;
              border-bottom: 1px solid #dadce0;
              &:first-child {
                  padding-top: 0;
              }
          }
          .sub-option-box {
              background: #f9fcee;
              border-radius: 10px;
              padding: 10px 20px;
              margin: 5px 0;
              .arrow-img {
                  width: 8px;
                  margin-left: 7px;
                  &.arrow-up {
                      transform: rotate(180deg);
                  }
              }
              .sub-option-list {
                  padding: 5px 0;
              }
          }
      }
      .select-box {
          display: flex;
          justify-content: space-between;
          position: relative;
          padding-bottom: 15px;
          border-bottom: 1px solid #dadce0;
          font-weight: 600;
          font-size: 14px;
          color: #102c42;
          cursor: pointer;
      }
      .select-list {
          position: absolute;
          top: 100%;
          background: #ffffff;
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0629);
          border-radius: 10px;
          z-index: 11;
          padding: 20px;
          left: 0;
          right: 0;
          padding-top: 5px;
          .text-wrapper {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid #eeeff1;
              line-height: 1.2;
              .text {
                  font-weight: 400;
                  font-size: 13px;
                  color: #111b45;
              }
          }
      }
      .time-text {
          font-weight: 400;
          font-size: 12px;
          color: #87928d;
          white-space: nowrap;
      }
      .flex-list{
          display: flex;
          justify-content: space-between;
          align-items: center;
      }
  }
  .btn-box{
      margin-top: 70px;
      margin-bottom: 50px;
      @media screen and (max-width: 767px) {
          margin-top: 30px;
          margin-bottom: 10px;
          text-align: center;
          .btn-green{
              width: 100%;
          }
          .btn-border{
border: 0;
padding: 0;
margin-top: 30px;
text-decoration: underline;
min-height: auto;
          }
      }
  }
}