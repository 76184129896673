:root {
    --color-white: #fff;

    --color-primary: #a9cf3d;
    --color-primary-dark: #8cae2b;

    --color-secondary: #587e85;
    --color-secondary-dark: #446166;
    --color-secondary-dark-1: #2a4642;

    --color-cool-gray: #979d9f;
    --color-gray: #eee;
    --color-gray-dark-1: #ddd;
    --color-gray-dark-2: #ccc;

    --color-timesheet-non-working-day: #E5E7DF;
    --color-timesheet-filled: #66ACCF;
    --color-timesheet-not-filled: #EE4F4F;
    --color-timesheet-leave: #FFB700;
    --color-timesheet-paid: #A9CF3D;
    --color-timesheet-holiday: #587E85;
    --color-timesheet-blue: #66ACCF;
    --color-timesheet-red: #EE4F4F;

    --color-error: #ff002d;
    --color-danger: #ff002d;
    --color-danger-dark: #b10020;
}