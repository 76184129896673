.nav-tab {
    margin-top: 45px;

    li {
        margin-right: 60px;

        a {
            background-color: transparent !important;
            padding: 18px 0 !important;
        }
    }
}


.vendor-profile-card {
    padding: 30px !important;

    .profile-dropdown {
        margin-bottom: 10px;

        .dropdown-btn {
            width: 32px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            border-radius: 16px;
            cursor: pointer;
            margin-left: auto;
            background-color: #e6eced;
        }

        .dropdown-menu {
            min-width: 185px;
            padding: 5px 20px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
            border: 0;
            background-color: #fff;

            .dropdown-item {
                font-size: 13px;
                color: #111b45;
                font-weight: 400;
                line-height: 13px;
                padding: 0px;
                background-color: #fff;
                position: relative;
                border-bottom: 1px solid #f2f3ef;
                padding: 14px 0;
                outline: 0;

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .profile-image-col {
        padding-right: 30px;
        text-align: center;

        .profile-pic {
            width: 180px;
            height: 180px;
            overflow: hidden;
            border-radius: 100%;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                object-fit: cover;
            }
        }

        .profile-name {
            margin-top: 10px;
            font-weight: 600;
            font-size: 18px;
            color: #587e85;
            line-height: 14px;
        }
    }

    .profile-form-col {
        border-left: solid 1px rgba(151, 151, 151, 0.4);
        padding-left: 60px;
        padding-right: 40px;

        .add-btn {
            display: flex;
            margin-bottom: 30px;

            img {
                width: 15px;
                margin-right: 5px;
            }
        }

        .card-box {
            position: relative;

            span {
                position: absolute;
                right: 0;
                top: 25px;
            }

            input {
                padding-right: 110px;
            }
        }
    }
}

.c-field {
    margin-bottom: 28px;
    line-height: 14px;

    label {
        color: #6F7788;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        margin-bottom: 10px;
    }

    .field-name {
        color: #102c42;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
    }
}

.vendor-subscription-details{
    .previousInvoices{
        margin-top: 48px;
        margin-bottom: 30px;
    }
}
.tag{
    width: 70px;
height: 10px;

/* type/web/tags 2 */

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 9px;
line-height: 10px;
/* identical to box height, or 111% */

text-transform: uppercase;

/* mir_p_white */

color: #FFFFFF;


/* Inside auto layout */

white-space: nowrap;
background: #EE4F4F;
border-radius: 4px;
padding: 4px;
margin-left: 16px;
flex: none;
order: 0;
flex-grow: 0;
}