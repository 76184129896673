.subsciption-management{.subscription-management-container{
    max-width: 1170px;
    h2{
        margin-top: 9px;
    }
    .data-box{
        padding: 64px 38px 56px;
        margin-bottom: 30px;
        h3{
            font-size: 22px;
          color: #242F40;
     
        
        }

    }
    .feature-btn{
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        text-decoration-line: underline;
        color: #587E85;
        border: none;
        margin-top: 10px;
    }
}
}