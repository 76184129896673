.basic-price-detail-modal {
  max-width: 1150px;

  .modal-content {
    padding: 50px 30px 40px;

    @media (max-width: 767px) {
      padding: 20px 15px 30px;
    }

    .modal-custom-title p {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }
}

.comparision-feature-modal {
  max-width: 1170px;

  .modal-content {
    padding: 50px 30px 30px;

    @media (max-width: 767px) {
      padding: 20px 15px 30px;
    }

    .modal-custom-title p {
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }

  .feature-comparision-types {
    width: 100%;

    tr {
      th {
        border: 1px solid #dadce0;
        border-radius: 10px;
        background: #f9fcee;
        padding: 18.5px 30px;
        width: 18%;
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 22px;
        color: #242f40;

        @media (max-width: 767px) {
          width: 50%;
          font-size: 14px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          width: 20%;
          font-size: 14px;
        }


        &:nth-child(1) {
          width: 28%;
          background: transparent;
          border: none;

          @media (max-width: 767px) {
            width: 50%;
          }
        }
      }
    }
  }

  .feature-comparision-table {
    width: 100%;
    margin-bottom: 10px;

    tr {
      color: #5b6b82;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;

      &:nth-child(even) {
        background-color: #ffffff;
      }

      &:nth-child(odd) {
        background-color: #f2f3ef;
      }

      @media (max-width: 767px) {
        font-size: 14px;
      }

      th {
        padding: 8px 20px;
        background-color: #587e85;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        color: #ffffff;
        width: 18%;

        &:nth-child(1) {
          width: 28%;
        }

        @media (max-width: 767px) {
          font-size: 14px;
        }

        .circle {
          float: right;
          border: 1px solid #3b5c62;
          border-radius: 40px;
          color: #fff;
          background-color: #3b5c62;
          padding: 3px 8px;
          font-size: 16px;
          width:32px;
        }
      }

      td {
        text-align: center;
        padding: 15px 0px;

        border: 1px solid #dadce0;
        border-radius: 10px;

        &:nth-child(1) {
          text-align: left;

          padding-left: 20px;
        }
      }
    }
  }

  .feature-comparision-pricing {
    width: 98%;

    tr {
      th {
        border: 1px solid #dadce0;
        border-radius: 10px;
        background: #f9fcee;
        padding: 7.5px 30px;
        width: 18%;
        text-align: center;

        &:nth-child(1) {
          width: 25%;
          background: transparent;
          border: none;
        }

        button {
          font-weight: 600;
          font-size: 13px;
          padding: 10px 11px;
        }

        .button-border:focus,
        .button-border:hover {
          background-color: transparent;
          color: #587e85;
        }
      }
    }
  }
}

.new-subscription.ch-radio input[type="radio"]:checked+span,
.ch-radio input[type="radio"]:checked~span {
  font-weight: 600;
}

.add-subscription-block .add-block .button-block.custom-button-block {
  margin-top: 30px;
}

.custom-subsription-plan .c-field {
  margin-bottom: 0px;

  .pricing-plan-modal {
    &.modal-dialog {
      .modal-content {
        padding: 50px 30px 30px;

        @media screen and (max-width: 420px) {
          padding: 20px 15px;
        }

        .aod-dc-title {
          @media screen and (max-width: 420px) {
            font-size: 14px;
          }
        }

        .aod-dc-value {
          word-break: break-word;

          @media screen and (max-width: 420px) {
            font-size: 14px;
          }
        }

        .confirm-subscription-body {
          @media screen and (max-width: 420px) {
            .modal-mobile-para p {
              font-size: 12px;
              line-height: 20px;
            }

            .modal-mobile-para-line p {
              margin-bottom: 30px;
            }
          }
        }
      }
    }

    &.confirm-subscription-modal {
      .modal-content {
        padding: 70px 30px 30px;

        @media screen and (max-width: 420px) {
          padding: 50px 15px 16px;
        }
      }

      button {
        min-width: 222px;
      }
    }
  }
}

.custom-mb-40 {
  margin-bottom: 40px;

  .alert-image img {
    width: 22px;
  }
}