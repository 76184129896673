.busy-timeslots-form{
  max-width: 420px;
}
.c-field-label{
  color: #79869a;
  padding-bottom: 0;
  margin: 0;
  font-size: 13px;
  font-weight: 400;
}

