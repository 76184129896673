.add-staff-block {
  @include padding(30 0);

  @media (max-width: 767px) {
    @include padding(16 0);
  }

  .title {
    color: $heading-color;
    font-weight: 500;
    @include fontsize(25);
    line-height: 25px;
    margin: 0px 0 10px;

    @media (max-width: 767px) {
      @include fontsize(20);
      font-weight: 600;
      //   margin-top: 26px;
    }
  }
  .sub-title {
    font-size: 14px;
    font-weight: 300;
    color: #000;
  }
  .form-wrapper {
    @include margin-top(14);
    @media (max-width: 767px) {
      padding-bottom: 20px;
    }
    .add-staff-form {
      max-width: 420px;

      @media (max-width: 991px) {
        max-width: 100%;
      }

      .radio-list {
        @include margin-top(16);

        ul {
          li {
            display: inline-block;
            margin-right: 39px;

            @media (max-width: 767px) {
              margin-right: 34px;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      .c-field {
        .helper-label {
          font-size: 14px;
          font-weight: 600;
          color: #102c42;
          strong {
            font-weight: 600;
          }
        }
      }
    }

    .account-checkbox {
      margin-bottom: 20px;
      .ch-checkbox {
        span {
          color: #102c42;
          font-size: 14px;
        }
      }
    }

    .schedule-list {
      margin-bottom: 30px;

      & > label {
        color: #79869a;
        padding-bottom: 0;
        margin: 0;
        @include fontsize(13);
        font-weight: 400;
      }

      ul {
        display: table;
        width: 100%;
        margin-top: 12px;

        li {
          float: left;
          margin-right: 18px;

          @media (max-width: 767px) {
            margin-right: 12px;
          }

          &:last-child {
            margin-right: 0;
          }
          label {
            margin: 0;
            display: block;
            overflow: hidden;
            position: relative;
            input[type="checkbox"],
            input[type="radio"] {
              @include pos(ab, 0 null null 0);
              @include opacity(0);

              &:checked {
                & + span,
                & ~ span {
                  color: $white;
                  &::before {
                    background-color: #587e85;
                    border-color: #587e85;
                  }
                }
              }
            }

            span {
              display: block;
              width: 31px;
              height: 31px;
              position: relative;
              line-height: 30px;
              text-align: center;
              //color: #dbded4;
              font-weight: 500;
              cursor: pointer;
              @include fontsize(14);
              z-index: 0;

              @media (max-width: 767px) {
                width: 24px;
                height: 24px;
                line-height: 24px;
                @include fontsize(11);
              }

              &::before {
                //border: 1px solid #e5e7df;
                border: 1px solid #535b5f;
                border-radius: 7px;
                @include pos(ab, 0 0 0 0);
                content: "";
                z-index: -1;
              }
            }
          }
        }
      }
    }

    .btn-field {
      margin-top: 40px;

      @media (max-width: 767px) {
        margin-top: 30px;
        text-align: center;

        .button {
          width: 100%;
          display: block;
          margin-bottom: 10px;
          &.button-border {
            margin-bottom: 0;
          }
        }

        .button-border {
          border: 0;
          @include fontsize(12);
          font-weight: 500;
          text-decoration: underline;
          color: #577d84;

          &:hover,
          &:focus {
            background: transparent;
            border: 0;
            color: #577d84;
          }
        }
      }
    }
  }
}

.staff-listing-block {
  @include padding(30 0);

  @media (max-width: 767px) {
    @include padding(16 0);
  }

  .add-button {
    @media (max-width: 767px) {
      width: 100%;
      display: block;
      margin-top: 20px;
    }
  }
  .back-btn {
    color: #587e85;
    text-decoration: underline;
  }

  .title {
    color: $heading-color;
    font-weight: 600;
    @include fontsize(25);
    line-height: 25px;
    margin-bottom: 10px;
    @media (max-width: 767px) {
      @include fontsize(20);
      //   margin: 26px 0 0;
    }
  }
  .sub-title {
    font-size: 14px;
    font-weight: 300;
    color: #000;
  }

  .data-list {
    margin-top: 30px;

    a {
      text-decoration: none;
      display: block;
      height: 100%;
    }

    [class*="col-"] {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    @media (max-width: 767px) {
      margin-top: 20px;
      height: auto;
    }

    .staff-card {
      border-radius: 15px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
      background-color: $white;
      text-align: center;
      position: relative;
      min-height: 272px;
      height: 100%;
      word-break: break-word;
      @media (max-width: 767px) {
        min-height: inherit;
        height: auto;
      }

      .staff-img {
        position: relative;
        width: 79px;
        height: 79px;
        margin: 0 auto 20px;
        ._img {
          img {
            margin-top: 20px;
            width: 79px;
            height: 79px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        span.ico {
          @include pos(ab, null -5px -10px null);
        }
      }

      h4 {
        @include fontsize(16);
        line-height: 18px;
        color: #587e85;
        font-weight: 600;
        margin: 0 0 12px;
      }

      .profile {
        margin-bottom: 10px;
        line-height: 18px;
        span {
          @include fontsize(12);
          line-height: 18px;
          color: #6f7788;
          display: inline-block;
          margin-left: 7px;
        }
      }

      .process {
        span {
          @include fontsize(10);
          color: #6f7788;
          line-height: 12px;
          strong {
            font-weight: 500;
            color: #2a4642;
            display: inline-block;
            margin-left: 7px;
            @include fontsize(12);
          }
        }
      }

      .process-pending {
        span {
          @include fontsize(12);
          line-height: 18px;
          color: #e76f2a;
          display: inline-block;
          vertical-align: middle;
          margin-left: 7px;
        }
      }

      .process-decline {
        span {
          @include fontsize(12);
          line-height: 18px;
          color: #ff0017;
          display: inline-block;
          vertical-align: middle;
          margin-left: 7px;
        }
      }

      &.pending-card {
        background-color: #f9fcee;
      }

      &.deactivated-card {
        background-color: #f9fcee;
      }

      .admin-access-ico {
        @include pos(ab, 20px 20px null null);
        z-index: 1;
      }

      &.decline-card {
        background-color: #f9fcee;
      }
    }
  }
  @media (max-width: 767px) {
    .custom-search {
      position: relative;
      span {
        left: 13px;
        top: 7px;
      }
    }
  }
}

.staff-detail-block {
  @include padding(30 0);

  @media (max-width: 767px) {
    @include padding(16 0);
  }

  .title {
    color: $heading-color;
    font-weight: 600;
    @include fontsize(25);
    line-height: 1.1;
    margin: 0px 0 10px;

    @media (max-width: 767px) {
      @include fontsize(20);
      //   margin-top: 26px;
    }
  }
  .sub-title {
    font-size: 14px;
    font-weight: 300;
    color: #000;
  }

  .staff-detail-content {
    @include padding(70);
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    margin-top: 14px;
    position: relative;
    min-height: 530px;

    @media (max-width: 991px) {
      @include padding(30 30 50);
      min-height: inherit;
    }

    @media (max-width: 767px) {
      padding: 0;
    }

    .staff-pic {
      width: 180px;
      text-align: center;

      @media (max-width: 991px) {
        width: 150px;
      }

      @media (max-width: 767px) {
        width: 100%;
        text-align: left;
        padding: 20px;
      }

      img {
        width: 180px;
        height: 180px;
        object-fit: cover;
        border-radius: 50%;

        @media (max-width: 991px) {
          width: 150px;
          height: 150px;
        }

        @media (max-width: 767px) {
          width: 84px;
          height: 84px;
        }
      }

      h3 {
        @include fontsize(18);
        color: #587e85;
        margin: 12px 0 0;
        font-weight: 600;

        @media (max-width: 767px) {
          @include fontsize(16);
          margin: 15px 0 0;
        }
      }
    }

    .staff-job-detail {
      padding-left: 50px;
      border-left: 1px solid #e0e0e0;

      @media (max-width: 767px) {
        padding: 30px 20px;
        border-left: 0;
        border-top: 1px solid #f2f3ef;
      }

      .data-box {
        margin-bottom: 30px;
        .media {
          img {
            margin-right: 12px;

            @media (max-width: 767px) {
              margin-right: 8px;
            }
          }

          label {
            @include fontsize(12);
            color: #6f7788;
            font-weight: 400;
            line-height: 12px;
            margin-bottom: 10px;
            padding: 0;

            @media (max-width: 767px) {
              margin-bottom: 4px;
            }
          }

          h4 {
            font-weight: 600;
            color: #102c42;
            @include fontsize(14);
            line-height: 14px;
            margin: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .schedule-list {
          margin-bottom: 30px;

          ul {
            list-style: none;
            display: table;
            width: 100%;
            li {
              width: 26px;
              height: 26px;
              border-radius: 6px;
              border: 1px solid #e5e7df;
              text-align: center;
              line-height: 24px;
              @include fontsize(12);
              color: #dbded4;
              float: left;
              margin-right: 10px;
              // cursor: pointer;
              @media (max-width: 767px) {
                margin-bottom: 5px;
                margin-right: 5px;
                width: 24px;
                height: 24px;
              }
              &:last-child {
                margin-right: 0;
              }

              &.active {
                background-color: #e3edc3;
                border-color: #274642;
                color: #274642;
              }
            }
          }
        }
      }
    }

    .staff-dropdown {
      @include pos(ab, 35px 20px null null);

      @media (max-width: 767px) {
        right: 15px;
        top: 20px;
      }

      span.ico {
        cursor: pointer;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        background-color: transparent;
        display: inline-block;

        &:hover {
          background-color: #e6eced;
        }
      }

      .dropdown-menu {
        min-width: 120px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
        background-color: $white;
        border: 0;
        padding: 0 15px;

        .dropdown-item {
          padding: 10px 0;
          background-color: transparent;
          color: #535b5f;
          @include fontsize(13);
          border-bottom: 1px solid #f2f3ef;

          &:last-child {
            border: 0;
          }

          a {
            display: block;
            text-decoration: none;
          }

          &:hover,
          &:focus {
            outline: none;
            background-color: transparent;
            box-shadow: none;
            color: $theme;
          }
        }
      }
    }
  }
}

.staff-roles-block {
  @include padding(55 0 30);

  @media (max-width: 767px) {
    @include padding(16 0 70);
  }

  .button {
    @media (max-width: 374px) {
      padding: 0 15px !important;
      float: right;
    }
  }

  .main-title {
    color: $heading-color;
    font-weight: 600;
    @include fontsize(25);
    line-height: 25px;
    margin: 16px 0 30px;

    @media (max-width: 767px) {
      @include fontsize(20);
      margin: 0 0 20px;
    }
  }
  .accordion {
    border: 0;
  }
  .accordion__item {
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;
    + .accordion__item {
      border: 0;
    }
    padding: 20px 30px;
    margin-bottom: 10px;

    @media (max-width: 991px) {
      @include padding(20);
    }

    @media (max-width: 767px) {
      @include padding(10);
    }

    .accordion__heading {
      .accordion__button {
        background-color: transparent;
        padding: 0;
        &::before {
          display: none;
        }
        &:focus {
          outline: none !important;
        }

        &[aria-expanded="true"] {
          .arrow_ico {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
          }
        }
      }

      .title {
        color: $heading-color;
        font-weight: 600;
        @include fontsize(16);
        line-height: 20px;
        margin: 0;

        @media (max-width: 767px) {
          @include fontsize(14);
        }
      }

      .arrow_ico {
        float: right;
        margin-left: 30px;
        margin-top: 5px;

        @media (max-width: 767px) {
          margin-left: 0;
        }

        @media (max-width: 360px) {
          margin-left: 5px;
        }
      }

      .button {
        height: 35px;
        line-height: 35px;
        @include fontsize(13);
        padding: 0 15px;
      }
    }
    .accordion__panel {
      padding: 0;
    }
  }

  .staff-dropdown {
    display: none;

    @media (max-width: 991px) {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      //  @include translateY(-50%);
    }

    span.ico {
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      line-height: 32px;
      background-color: transparent;
      display: inline-block;

      &:hover {
        background-color: #e6eced;
      }
    }

    .dropdown-menu {
      min-width: 170px;
      border-radius: 10px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
      background-color: $white;
      border: 0;
      padding: 0 15px;
      // z-index: 10;

      .dropdown-item {
        padding: 10px 0;
        background-color: transparent;
        color: #111b45;
        @include fontsize(13);
        border-bottom: 1px solid #f2f3ef;

        img {
          display: inline-block;
          margin-right: 8px;
          position: relative;
          top: -2px;
        }

        &:last-child {
          border: 0;
        }

        &:hover,
        &:focus {
          outline: none;
          background-color: transparent;
          box-shadow: none;
          color: $theme;
        }
      }
    }
  }

  .staff-roles-list {
    margin-top: 20px;

    @media (max-width: 767px) {
      margin-top: 15px;
    }

    .data-box {
      position: relative;
      border-bottom: 1px solid #dadce0;
      padding-bottom: 15px;
      margin-bottom: 15px;

      &:last-of-type {
        border: 0;
        padding-bottom: 0;
      }

      .data-box-content {
        @media (max-width: 767px) {
          display: inline-block;
          vertical-align: middle;
        }

        button {
          background: transparent;
          border: 2px solid #587e85;
          border-radius: 20px;
          padding: 9px 14px;
          @include fontsize(13);
          font-weight: 600;
          color: #587e85;
          position: relative;
          outline: none !important;

          &:disabled {
            pointer-events: none;
            opacity: 0.6;
          }
          span {
            margin-right: 5px;
            position: relative;
            top: -2px;
          }

          .img-star-hover {
            display: none;
          }

          &:hover {
            background-color: #587e85;
            border-color: #587e85;
            color: $white;

            .img-star {
              display: none;
            }

            .img-star-hover {
              display: inline-block;
            }
          }
        }
      }

      h3 {
        @include fontsize(15);
        color: #102c42;
        line-height: 22px;
        font-weight: 400;
        margin: 0;
        float: left;
      }

      .action-block {
        float: right;

        button {
          display: inline-block;
        }
      }

      .action-links {
        display: inline-block;
        padding-left: 30px;
        ul {
          li {
            display: inline-block;
            vertical-align: middle;
            padding: 0 15px;

            span {
              cursor: pointer;
            }

            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  .manage-staff-role-list,
  .physican-role-list {
    .mange-role-list,
    .physican-data-list {
      margin-top: 20px;
      @media (max-width: 767px) {
        margin-top: 15px;
      }
      .data-box {
        position: relative;
        border-bottom: 1px solid #dadce0;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:last-of-type {
          border: 0;
          padding-bottom: 0;
        }

        .data-box-content {
          @media (max-width: 767px) {
            display: inline-block;
            vertical-align: middle;
          }
          button {
            background: transparent;
            border: 2px solid #587e85;
            border-radius: 20px;
            padding: 9px 14px;
            @include fontsize(13);
            font-weight: 600;
            color: #587e85;
            position: relative;
            outline: none !important;

            &:disabled {
              pointer-events: none;
              opacity: 0.6;
            }

            span {
              margin-right: 5px;
              position: relative;
              top: -2px;
            }

            .img-star-hover {
              display: none;
            }

            &:hover {
              background-color: #587e85;
              border-color: #587e85;
              color: $white;

              .img-star {
                display: none;
              }

              .img-star-hover {
                display: inline-block;
              }
            }
          }
        }

        h3 {
          @include fontsize(15);
          color: #102c42;
          line-height: 22px;
          font-weight: 400;
          margin: 0;
          float: left;
        }
        .action-block {
          float: right;

          button {
            display: inline-block;
          }
        }

        .action-links {
          display: inline-block;
          padding-left: 30px;
          ul {
            li {
              display: inline-block;
              vertical-align: middle;
              padding: 0 15px;

              span {
                cursor: pointer;
              }

              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }

    .button {
      @media (max-width: 374px) {
        padding: 0 15px !important;
        float: right;
      }
    }
  }
}
