.delete-chat-modal {
    font-size: 14px;
    font-weight: 200;
    color: #535b5f;
    .modal-body {
        margin: 0 auto;
        max-width: 430px;
    }
    .text-box {
        margin-bottom: 40px;
    }
    button {
        min-width: 220px;
    }
}
.update-subscription-modal {
    font-size: 14px;
    font-weight: 200;
    color: #535b5f;
    .text-box {
        margin-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
    }
    button {
        min-width: 220px;
    }
}
.new-grop-modal {
    .search-select-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0;
        @media screen and (max-width: 767px) {
            display: block;
            margin: 20px 0;
        }
        .search-box input,
        .member-filter .react-select-container .react-select__control {
            border: 1px solid #dadada;
        }
        .member-filter .react-select-container {
            width: auto;
            float: none;
            min-width: 210px;
            .react-select__control {
                padding-left: 15px;
                min-height: 50px;
            }
        }
        .search-box {
            width: 60%;
            @media screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 10px;
            }
            input {
                padding-top: 14px;
                padding-bottom: 13px;
                height: 50px;
            }
        }
    }
    .group-list {
        margin-bottom: 30px;
        max-height: 35vh;
        overflow-y: auto;
        @media screen and (max-width: 767px) {
            margin-bottom: 30px;
        }
        li {
            padding: 12px 0;
            border-bottom: solid 1px rgb(218, 220, 224);
            .ch-checkbox span {
                color: #102c42;
                display: flex;
                align-items: center;
                small {
                    display: block;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 1;
                    color: #87928d;
                }
            }
        }
    }
    .btn-box {
        @media screen and (max-width: 567px) {
            text-align: center;
            button {
                min-width: 170px;
                margin-right: 0 !important;
                width: 100%;
            }
        }
    }
}
.new-conversation-popup {
    .input-wrapper {
        margin-top: 40px;
    }
    .share-app-btn{
        line-height: 1.4;
    }
    .search-box{
        width: 100%;
        margin: 15px 0 40px;
        input{
            border: 0;
            border-bottom: 1px solid #dadce0;
            padding-left: 0;
            padding-right: 35px;
            box-shadow: none;
            border-radius: 0;
        }
        span.ico {
            position: absolute;
            top: 15px;
            left: auto;
            right: 0;
            cursor: pointer;
            z-index: 1;
            @media screen and (max-width:767px) {
                top: 10px;
            }
        }
        &.green-border{
            margin-bottom: 13px;
            input{
                border: 0;
                border-bottom: 1px solid #a9cf3d;
            }
        }
        &.red-border{
            input{
                border-bottom: 1px solid #ff4550;
            }
        }
    }
    .group-list {
        // margin-top: -30px;
        margin-bottom: 40px;
        padding-top: 10px;
        max-height: 200px;
        overflow-y: auto;
        li {
            padding: 12px 0;
            border-bottom: solid 1px #dadce0;
            .list-box {
                display: flex;
                align-items: center;
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                    margin-right: 15px;
                }
                .user-name {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 1;
                    color: #102c42;
                }
            }
        }
    }
    .empty-contact-wrapper {
        font-size: 12px;
        line-height: 1.4;
        text-align: center;
        color: #6f7788;
        padding-top: 20px;
        max-width: 500px;
        margin: 0 auto 40px;
        @media screen and (max-width:767px) {
            margin-bottom: 10px;
        }
    }
}
.create-group-modal {
    .profile-setup-block .profile-form .file-upload-field {
        text-align: center;
        width: auto;
    }
    .profile-setup-block .profile-form .upload-help-text {
        text-align: center;
    }
    .remove-pic {
        margin: 0 auto;
        display: block;
        max-width: 140px;
        font-weight: 500;
        font-size: 12px;
        color: #ee4f4f;
        text-decoration: underline;
        cursor: pointer;
        text-align: center;
        margin-bottom: 30px;
        margin-top: -10px;
    }
}
.disable-btns {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}
.image-preview-modal{
    .modal-content {
        padding: 80px 30px 30px;
    }
}
