.review-rating-filter
{
    &.member-filter 
    {
        .react-select-container 
        {
            .react-select__control
            {
                padding-left:10px;
            }
        }
        &.helpdesk-filter{
            .react-select__control
            { 
                padding-left:0px;
            }
            @media screen and (max-width:767px) {
                .react-select-container .react-select__control{
                    min-height: 50px;
                }
            }
        }
    }
   
}
.review-filter-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.3rem;
    margin-bottom: 1.9rem;
    .patient-heading{
      font-size: 18px;
      font-weight: 600;
      color: #587e85;
      line-height: 1;
    }
    .patient-desc{
        color: #535b5f;
        line-height: 1;
        font-size: 14px;
        font-weight: 300;
    }
}

.review-rating-wrapper{
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    margin-bottom: 10px;
    font-size: 12px;
    color: #2f3245;
    font-weight: 500;
    position: relative;
    .review-title1{
        font-size: 10px;
        font-weight: 600;
        color: #587e85;
        line-height: 1.2;
        text-transform: uppercase;
    }
    .review-title2{
        font-size: 10px;
        font-weight: 400;
        color: #87928d;
        line-height: 1;
        letter-spacing: -0.2px;
    }
    .green-review-box{
        border-radius: 6px;
        background-color: #f9fcee;
        margin-bottom: 10px;
        margin-right: 130px;
        padding: 20px;
    }
    .grey-review-box{
        border-radius: 6px;
        background-color: #f2f3ef;
        margin-bottom: 10px;
        margin-right: 100px;
        padding: 20px;
        margin-left: 30px;
    }
    .status-inactive-btn{
        background-color:#e5e7df ;
        color: #bbbdb5;
        border-radius: 6px;
        padding: 4px 9px;
        position: absolute;
        right: 30px;
        top: 30px;
    }
    .status-active-btn{
        background-color:#a9cf3d ;
        color: #fff;
        border-radius: 6px;
        padding: 4px 9px;
        position: absolute;
        right: 30px;
        top: 30px;
    }
}
.review-rating-modal{
    max-width: 660px;
    .modal-title {
        font-size: 25px;
        margin-bottom: 40px ;
        color: #111b45;
        font-weight: 500;
        @media screen and (max-width:767px){
            font-size: 20px;
        }
    }
    .review-rating-wrapper{
        padding: 0;
        box-shadow: none;
        .green-review-box{
            margin-right: 30px;
        }
        .grey-review-box{
            margin-right: 0;
        }
    }
}