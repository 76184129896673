.broadcast-userlisting{
    .search-wrapper{
        display: flex;
        justify-content: flex-end;
    }
    .search-input-card{
        position: relative;
        margin-bottom: 30px;
        margin-top: -60px;
      max-width: 260px;
        .search-input{
            border-radius: 5px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
            background-color: #fff;
            height: 50px;
            font-size: 14px;
            font-weight: 500;
            padding: 15px 15px 15px 40px;
            border: 0;
            outline: 0;
        }
        img{
            position: absolute;
            left: 15px;
            top: 17px;
        }
    }
    .userlisting-card{
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        padding: 15px 30px 30px;
        margin-bottom: 40px;
        .user-detail-list{
            margin-bottom: 40px;
            li{
                border-bottom: solid 1px rgb(218, 220, 224);
                display: flex;
                padding: 15px 0;
                
                font-size: 14px;
                color: #535b5f;
                .owner-name{
                    width: 50%;
                }
                .send-owner{
                    width: 30%;
                    padding: 0 30px;
                }
                .send-staff{
                    width: 20%;
                }
                &.user-header{
                    font-size: 12px;
                    font-weight: 600;
                    color: #102c42;
                    .checkbox{
                        color: #102c42;
                        span{
                            color: #102c42;
                            font-weight: 600;
                        }
                    }
                }
                label{
                    margin-bottom: 0;
                }
                .checkbox{
                    color: #535b5f;
                    line-height: 1.3;
                    span{
                        color: #535b5f;
                        font-weight: 400;
                    }
                }
            }
        }
    }
   
}
.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
}