@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap");
@import "./assets/icons/style.css";
@import "./assets/scss/style.scss";

.page {
    @include padding(30 0);

    @media (max-width: 767px) {
        @include padding(16 0);
    }
}

.page-title {
    color: $heading-color;
    font-weight: 600;
    @include fontsize(25);
    line-height: 25px;

    @media (max-width: 767px) {
        @include fontsize(20);
        margin: 26px 0 0;
        font-weight: 500;
    }
}

.page-backdrop {
    @include margin-top(30);
    padding: 70px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: $white;

    @media (max-width: 767px) {
        @include margin-top(20);
        padding: 16px;
    }
}

.back-btn-text {
    transition: all 300ms ease-in-out;
    color: #587e85;
    text-decoration: underline;
    outline: none !important;
    display: inline-block;
    cursor: pointer;
}

.round-image {
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

.button-loading {
    display: flex;
    align-items: center;

    .loader {
        margin-left: 20px;
        font-size: 3px;
    }
}

.table-row-main-link {
    font-weight: 500;
    color: #587e85;
    text-decoration: underline !important;
}

/* Card */
.app-card {
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    border: none;
}

.app-card-body {
    padding: 30px;
}

/* Loader */
.loader,
.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
}

.loader {
    font-size: 5px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #587e85;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.addressField {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

/* Shimmer */
.shimmer-animation {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    animation-timing-function: linear;
}

@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }

    100% {
        background-position: 1000px 0;
    }
}

/* Placeholders */
.text-placeholder-150 {
    width: 150px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}

.text-placeholder-100 {
    width: 100px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}

.text-placeholder-50 {
    width: 50px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}

.page-title-placeholder {
    width: 200px;
    height: 24px;
    border-radius: 4px;
}

/* Tooltips */
.app-tooltip {
    position: relative;
    display: inline-block;

    .app-tooltip-text {
        visibility: hidden;
        top: -4px;
        left: 50%;
        transform: translate(-50%, -100%);
        min-width: 200px;
        background-color: rgb(19, 19, 19);
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        position: absolute;
        z-index: 10;
    }
}

.app-tooltip:hover .app-tooltip-text {
    visibility: visible;
}

/* Select */
.app-select {
    position: relative;

    span.ico {
        @include pos(ab, 10px null null 15px);
        z-index: 1;

        @media (max-width: 767px) {
            top: 5px;
        }
    }

    .react-select-container {
        width: 207px;
        float: left;

        @media (max-width: 767px) {
            width: 191px;
        }

        .react-select__control {
            @include padding(0 0 0 30);
            border-radius: 5px;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.04);
            background-color: $white;
            border: 0;
            @include fontsize(14);
            font-weight: 500;
            width: 100%;
            min-height: 50px;

            @media (max-width: 767px) {
                min-height: 40px;
            }
        }

        .react-select__single-value {
            color: #77928b;
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__dropdown-indicator {
            padding: 0;
            margin-right: 15px;
            color: #77928b;
        }
    }
}

.page-warning-container {
    width: 100%;
    margin-bottom: 30px;
}

.page-warning-bg {
    width: 100%;
    margin: 0 auto;
    padding: 10px 20px;
    text-align: left;

    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: #ffba00;
    border-radius: 6px;

    @media (max-width: 767px) {
        width: 100%;
        align-items: flex-start;
    }
}

.page-warning-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.page-warning-text {
    color: #000;
    font-size: 14px;

    &:hover {
        color: #000;
    }
}

.no-underline {
    text-decoration: none;

    &:hover {
        text-decoration: none !important;
    }
}

.h-50vh {
    min-height: 50vh;
}

.pointer {
    cursor: pointer;
}

.center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

button.react-calendar__tile.react-calendar__month-view__days__day.cus_class abbr {
    background: #8cae2b !important;
    color: #fff;
}

button.react-calendar__tile.react-calendar__month-view__days__day.cus_class_remove abbr {
    background-color: #ffffff;
    color: #4a625f;
}

.scheduler-calendar .react-calendar__tile.cus_class_remove:disabled abbr {
    color: #274642;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
}



.cus_page {
    min-height: calc(100vh - 145px);
}

.border-right-none {
    border-right: unset !important;
}


.whiteSpace {
    white-space: pre-wrap
}



// editor css

.ck-content .text-tiny {
    font-size: 0.7em;
}

.ck-content .text-small {
    font-size: 0.85em;
}

.ck-content .text-big {
    font-size: 1.4em;
}

.ck-content .text-huge {
    font-size: 1.8em;
}


.document-editor {
    border: 1px solid var(--ck-color-base-border);
    border-radius: var(--ck-border-radius);
    max-height: 700px;
    display: flex;
    flex-flow: column nowrap;
}


.mark-cus-Class {
    background-color: #ffff00;
    padding: 0;
}


.card-section label {
    width: 100%;
}

// stripe payment card css start



#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}

/* Buttons and links */

.payment-stripe-form.StripeElement {
    height: auto;
}

.payment-stripe-form-btn {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}

button:hover {
    filter: contrast(115%);
}

button:disabled {
    opacity: 0.5;
    cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    form {
        width: 80vw;
        min-width: initial;
    }
}


// stripe payment card css END


.unset-word-rap {
    word-wrap: unset;
}

.disabled-element {
    opacity: 0.5;
}



.page-step {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #587e85;
    margin-bottom: 30px;
}


.link-btn-with-out-font {
    font-weight: 500;
    color: #587e85 !important;
    text-decoration: underline !important;
    cursor: pointer;
}

// Pricing sub plan scss start
.sub_wrapper {
    max-width: 956px;
    padding: 50px;
    border-radius: 18px;
    gap: 30px;
    background: #FFFFFF;
    margin: auto;

    @media (max-width: 767px) {
        padding: 20px;
    }

}

.sub_wrapper .nav-link {
    height: 48px;
    padding: 12px 30px 12px 30px;
    border-radius: 18px;
    gap: 10px;
    cursor: pointer;
}


.sub_wrapper .button-row {
    width: 710px;
    height: 48px;
    gap: 30px;
    margin: auto !important;

    @media (max-width: 767px) {
        display: block;
        width: 100%
    }
}

.nav-tabs .nav-item {

    @media (max-width: 767px) {
        margin-bottom: -1px;
        width: 100%;
        text-align: center;
    }

}

.sub_wrapper .nav-link.active {
    width: 155px;
    height: 48px;
    padding: 12px 30px 12px 30px;
    border-radius: 18px;
    gap: 10px;
    border: 2px solid #A9CF3D;
    white-space: nowrap;

    @media (max-width: 767px) {
        width: 100%
    }
}

.pricing-custom-btn {
    white-space: nowrap;
    min-width: 150px;
}

.pricing-custom-card {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 856px;
    border: none;

    @media (max-width: 767px) {
        margin-top: 115px;
    }
}

.viewFeatureBtn {
    color: #587E85;
    font-size: 15px;
    font-weight: 600;
}

.custom-font-price {
    font-size: 60px;

    @media (max-width: 767px) {
        font-size: 40px;
    }
}

.price-per-month {
    color: #8F9BB2;
    font-size: 22px;
    font-weight: 400;
}

.price-dollar-sign {
    color: #111B45;
    font-size: 30px;
    font-weight: 400;
}

.tooltip-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items:flex-start;

    .tooltip-image {
        margin-left: 15px;
        min-width: 15px;
        margin-top: -3px;
    }

    .tooltip-text {
        text-align: left;
        max-width: 800px;
        margin-left: 5px;
    }
}

// Pricing sub plan scss End