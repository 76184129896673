.doctor-profile-page{
    padding-top: 30px;
    min-height: calc(100vh - 80px);
    @media screen and (max-width:767px){
        margin-top: 20px;
        min-height: calc(100vh - 52px);
        padding-top: 15px;
    }
    .doctor-profile-block{
       margin: 30px 0;
        padding: 70px;
        border-radius: 10px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
        background-color:#fff;
        @media screen and (max-width:991px){
          padding: 40px;
        }
        @media screen and (max-width:767px){
            padding: 20px;
          }
          .anchor-link{
            text-decoration: underline;
            font-size: 12px;
            font-weight: 500;
            color: #587e85;
            cursor: pointer;
        }
        .doctor-detail-card{
            padding-right: 60px;
            border-right: solid 1px rgba(151, 151, 151, 0.4);
            min-height: 350px;
            height: 100%;
            @media screen and (max-width:991px){
              padding-right: 0;
              border-right:0;
              border-bottom: solid 1px rgba(151, 151, 151, 0.4);
              padding-bottom: 40px;
              margin-bottom: 40px;
              min-height: auto;
              height: auto;
              }
              @media screen and (max-width:767px){
                padding-bottom: 20px;
                margin-bottom: 20px;
                }
            .intro-box{
                display: flex;
                align-items: center;
                margin-bottom: 40px;
                @media screen and (max-width:767px){
                    display:block
                }
                img{
                    border-radius: 100%;
                    width: 60px;
                    height: 60px;
                    margin-right: 18px;
                    @media screen and (max-width:767px){
                      margin-bottom: 10px;
                    }
                }
            }
            
        }
        .review-rating-wrapper{
           .review-img{
                width: 36px;
                height: 36px;
                border-radius: 100%;
                margin-right: 15px;
            }
            .green-review-box{
                border-radius: 6px;
                background-color: #f9fcee;
                margin-bottom: 10px;
                margin-right: 30px;
                padding: 20px;
                @media screen and (max-width:767px){
                   margin-right:0 ;
                  }
            }
            .grey-review-box{
                border-radius: 6px;
                background-color: #f2f3ef;
                margin-bottom: 10px;
                padding: 20px;
                margin-left: 30px;
                &:last-child{margin-bottom: 0;}
                @media screen and (max-width:767px){
                    margin-left:0 ;
                   }
            }
        }
        .doctor-contact-card{
            margin-left: 70px;
            padding: 30px 67px;
            border-radius: 6px;
            background-color: #f9fcee;
            text-align: center;
            @media screen and (max-width:991px){
               margin-left: 0px;
              }
            
        }
        .book-appointment-card{
            margin-left: 70px;
            padding: 30px;
            border-radius: 6px;
            background-color: #f9fcee;
            @media screen and (max-width:991px){
               margin-left: 0px;
              }
              @media screen and (max-width:767px){
                padding: 20px;
                 }
              .border-row{
                  border-bottom: 1px solid rgba(177, 199, 152, 0.3);
                  margin-bottom: 12px;
              }
              .timelist{display: flex;
                margin: 0 -5px 0px;
                flex-wrap: wrap;
            
            li{
                width: 70px;
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                color: #BBBDB5;;
                border: 1px solid #E5E7DF;
                background-color: #fff;
                margin: 4px;
                border-radius: 6px;
                padding: 3px 7px;
                cursor: pointer;
                @media (max-width:767px) {
                    padding: 3px 5px;
                    margin: 3px;
                    width: 66px;
                }
                &.active{
                    border-color: #587e85;
                    background-color: #587e85;
                    color: #fff;
                    }
                }
               
            }
        }
        .appointment-booked{
            margin-left: 70px;
            padding: 30px 60px;
            border-radius: 6px;
            background-color: #f9fcee;
            text-align: center; 
            @media screen and (max-width:991px){
                margin-left: 0px;
            }
            @media screen and (max-width:767px){
                padding:30px 15px;
            }
            .download-app{
                .img-box{
                    cursor: pointer;
                    margin: 10px 10px 0;
                    img{
                        max-width: 130px;
                    }
                }     
            }
        }
    }
}

.textarea {
    width: 100%;
    border: none;
    outline: none;
    background-color: #f9fcee;
    font-size: 14px;
    color: #102c42;
    font-weight: 600;
    @media screen and (max-width:767px){
      height: 150px;
    }
}

.calendar-day {
    background-color: white !important;
    color: #bbbdb5 !important;
    font-size: 14px;
    padding: 7px;

    abbr {
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:disabled {
        abbr {
            border: 1px solid #e5e7df;
            border-radius: 6px;
            color: #bbbdb5 !important;
        }
    }
}

.calendar-day-active {
    abbr {
        border: 1px solid #a9cf3d;
        border-radius: 6px;
        color: #274642 !important;
    }
}

.different-timelist-alldays{
    li{
        display: flex;
        justify-content: space-between;
        .day-box{
            width: 31px;
            height: 31px;
            border-radius: 7px;
            background: #587E85;
            font-weight: 500;
            font-size: 15.68px;
            color: #fff;
            text-align: center;
            line-height: 31px;
            margin-right: 20px;
            &.weekend{
                border: 1px solid #E5E7DF;
                color: #DBDED4;
                background-color: transparent   ;            }
        }
        .time-select-box{
            width: 60%;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width:767px){
                width: calc(100% - 60px);
            }
            >div{
                width: 44%;
                margin-bottom: 15px;
                @media screen and (max-width: 767px) {
                 margin-bottom: 20px;
                 input{
                    padding-bottom: 10px;
                    padding-top: 0;
                }
                }
               
               
            }
        }
    }
}
.cursor-pointer {
    cursor: pointer;
}