.app-page {
    .banner-block {
        &.get-started-banner {
            background-image: url("./../../assets/images/landing-pages/get-started-banner.jpg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
             min-height: 964px;
            
             padding-bottom: 30px;

            @media (max-width: 767px) {
                background-image: url("./../../assets/images/landing-pages/get-started-banner-mobile.jpg");
                min-height: 826px;

                background-position: center;
            }

            .container {
             

                .get-started-header {
                    margin-top: 50px;

                    @media (max-width: 767px) {
                        position: relative;
                        background-color: #ffffff;
                        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
                        padding: 19px 16px;
                        top: 0px;
                        margin-top: 0px;
                        margin-left: -15px;
                        margin-right: -15px;
                    }
                }

                .banner-caption {
                    text-align: center;
                    @media (max-width: 767px) {
                        text-align: left;
                    }

                    h2 {
                        font-size: 56px;

                        font-weight: 700;
                        line-height: 80px;
                        letter-spacing: -1.18519px;
                        margin-bottom: 16px;
                        margin-top: 200px;
                        color: #2D3245;

                        @media (max-width: 767px) {
                            font-size: 20px;
                            margin-bottom: 10px;
                            line-height: 24px;
                            margin-top: 30px;
                        }
                    }

                    p {
                        font-weight: 500;
                        font-size: 22px;
                        line-height: 24px;
                        color: #2d3245;
                        margin-bottom: 60px;

                        @media (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 35px;
                            line-height: 24px;
                        }
                    }
                }

                .get-started-tabs {
                    flex-wrap: wrap;
                    border: 0;
                    justify-content: center;

                    @media (max-width: 767px) {
                        margin-top: 20px;
                        flex-direction: column;
                     max-width: 326px;
                     margin: 0 auto;
                    }

                    .nav-item {
                       margin-right: 30px;
                       margin-bottom: 30px;
                        position: relative;
                        z-index: 1;
                        @media (max-width: 991px) {
                            margin-right: 0px;
                        }
                        @media (max-width: 767px) {
                            margin-bottom: 35px;
                            margin-right: 0px;
                        }
                        &:nth-last-child(1){
                            margin-right: 0px;  
                        }
                        &::before {
                            content: "";
                            width: 100%;
                            height: 50%;
                            background-color: #a9cf3d;
                            position: absolute;
                            bottom: -6px;
                            left: 0;
                            border-radius: 18px;
                            transition: 0.3s;
                            z-index: -1;
                        
                        }
                        &:hover {
                            &::before {
                                width: 0;
                            }
                        }
                    }

                    .nav-link {
                        text-align: center;
                        padding: 40px 0;
                        background-color: #fff;
                        min-width: 460px;
                        width: 100%;
                        font-weight: 600;
                        font-size: 26px;
                        line-height: 48px;
                        box-shadow: 0px 9.69125px 29.0738px rgba(0, 0, 0, 0.06);
                        border-radius: 17.4443px;
                        position: relative;
                        color: #587e85;
                        cursor: pointer;
                        display: block;
                        z-index: 2;
                        border: 0;

                        @media (max-width: 767px) {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            text-align: center;
                            min-width: 326px;

                            padding-top: 35px;
                            padding-bottom: 35px;
                            color: #587e85;
                        }

                       

                        img {
                            display: block;
                            margin: 0 auto 24px;

                            @media (max-width: 767px) {
                                margin: 0 auto 15px;
                                width: 70px;
                            }
                        }

                        .icon-green {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
