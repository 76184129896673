.loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >div{
        width: 25px;
        height: 25px;
    }
}