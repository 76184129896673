.card {
    margin: 30px 0 33px;
    padding: 70px;
    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--color-white);

    @media (max-width: 767px) {
        padding: 20px;
    }
}

.map-container {
    width: 100%;
    height: 400px;

    margin-bottom: 30px;
}

.button-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .save-button {
        margin-right: 20px;
    }

    @media (max-width: 767px) {
        width: 100%;
        flex-direction: column;

        button {
            width: 100%;
        }

        .save-button {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.map {
    width: 100%;
    height: 100%;
}

.map-action-button {
    background: none;
    border: none;
    outline: none;
margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-secondary);
    text-decoration: underline;

    &:disabled {
        color: var(--color-cool-gray);
    }
}

.instructions {
    list-style: unset;
    margin-left: 18px;
    font-size: 14px;

    li {
        margin-bottom: 6px;
    }
}