.promotion-page{
    .custom-select-promotion{
        .select-common-dropdown{
    
    .right-content{
        color: #ff4550;
    }
    .selected-item{
        display: flex;
    justify-content: space-between;
    color: #102c42;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: solid 1px rgba(151, 151, 151, 0.4);
    padding-top: 5px;
        span{
            min-width: 96%;
        }
    }
    .dropdown-menu{
        width: 100%;
        transform: none;
    }
}
}


#promoCode {
    overflow: auto;
    height: 100%;
    max-height: 150px !important;
    padding: 10px;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #587e85;
        border-radius: 10px;
    }
}

}