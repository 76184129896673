.global-preference-custom-input{
    background-color: transparent;
}

.top-right-text {
    text-align: right;
    @media screen and (max-width: 767px) {
        text-align: left;
        margin-top: 10px;
    }
    .show-text {
        color: #6f7788;
        font-size: 12px;
    }
    .notification-red-dot img {
        margin-left: 5px !important;
    }
}

.pell-container {
    margin-bottom: 30px;
    .my-custom-class {
        font-family: "Poppins";
        padding: 4px 10px;
        border: solid 1px #eff3e1;
        background-color: #f9fcee;
        &.no-toolbar {
            display: none;
        }
        .pell-button {
            color: #848b92;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            outline: 0;
            position: relative;
            padding: 0;
            text-align: center;
            font-size: 16px;
            line-height: 18px;
            .color-picker-input {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 1;
                cursor: pointer;
                padding: 0;
                width: 18px;
                height: 18px;
            }
        }
    }
    .pell-content {
        padding: 14px 0;
        min-height: 160px;
        font-size: 14px;
        line-height: 1.43;
        color: #102c42;
        font-family: "Poppins";
        border: 0;
        border-bottom: 1px solid #dfdfe4;
        height: auto;
        p {
            margin-bottom: 15px;
            &:last-child {
                margin: 0;
            }
        }
    }
}

.attachemnt-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.template-action {
    width: 20px;
    text-align: center;
    cursor: pointer;
}
