.header-section + .messenger-page-content{
    margin-top: 90px;
    @media screen and (max-width:767px) {
        margin-top: 0;
    }
}
.messenger-page-content{
    margin-top: 10px;
  .active  span{
        animation: none;
    }
    .container{
        max-width: 1170px;
        @media screen and (min-width:1800px) {
            max-width: 2200px;
            padding-left: 100px;
            padding-right: 100px;
        }
    }
}
.team-chat-card{
    margin-top: 30px;
    padding: 10px 60px 70px !important;
    margin-bottom: 50px !important;
    @media screen and (max-width:767px) {
        padding: 10px 20px 20px !important;
        margin-bottom: 30px !important;
      }
}
.messenger-tabs {
    > .nav-tabs {
        border-bottom: 0;
        margin-bottom: 33px;
        @media screen and (max-width: 767px) {
           margin-bottom: 20px;
        }
        > .nav-item {
            width: 50%;
            text-align: center;
            > .nav-link {
                font-weight: 500;
                font-size: 20px;
                text-transform: none;
                color: #dbdbdb;
                letter-spacing: 0;
                padding: 15px 30px;

                @media screen and (max-width: 767px) {
                    font-size: 13px;
                    padding: 12px 10px;
                }
                &::before {
                    background-color: #a9cf3d;
                    left: 0;
                    width: 100%;
                    margin-left: 0;
                    height: 2px;
                    border-radius: 0;
                }
                &.active {
                    color: #111b45;
                }
            }
        }
    }
  
}
.sendbird-notification {
    display: none !important;
}
.sendbird-connection-status {
    display: flex;
    font-size: 12px;
}