.page {
    a {
        text-decoration: none;
    }
    .page-subheading {
        margin-top: -15px;
        padding-bottom: 30px;
        font-size: 14px;
        font-weight: 300;
        color:#000;
    }
}

.card {
    margin: 0 0 10px;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--color-white);
    display: flex;
    align-items: center;
    .tooltip-icon {
        position: absolute;
        top: 10px;
        right: 25px;
    }
    h4 {
        color: #587e85;
        font-size: 16px;
        font-weight: 600;
        margin: 0;
    }
}

.icon {
    width: 100px;
    height: 100px;

    object-fit: contain;
    object-position: center;
}
