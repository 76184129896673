.sai-sd-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #587e85;
    margin-bottom: 45px;
}
.sai-sd-card-value {
    font-weight: 600;
font-size: 16px;
color: #587E85;
margin-bottom: 45px;
}


.sai-sd-title {
    font-size: 15px;
    color: #5b6b82;
}

.sai-sd-value {
    font-size: 15px;
    font-weight: 600;
    color: #102c42;
}

.sai-pi-invoice-modal {
    max-width: 700px;
}

.sai-pi-invoice-modal-key {
    font-size: 15px;
    color: #5b6b82;
}

.sai-pi-invoice-modal-value {
    font-size: 15px;
    font-weight: 600;
    color: #102c42;
}

.sai-pi-invoice-modal-title {
    font-size: 25px;
    font-weight: 500;
    color: #111b45;
    margin-bottom: 40px;
}

.sai-seperator{
    margin-right: 20px;
    border-right: 1px solid#DADCE0;
    margin-left: 20px;

}