.page {
    min-height: calc(100vh - 80px);
}

.card {
    padding: 70px;

    border-radius: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08);
    background-color: var(--color-white);

    @media (max-width: 767px) {
        padding: 20px;
        border-radius: 15px;
    }
}

.container {
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.doctor-profile-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 40px;
    }
}

.doctor-profile-image {
    width: 60px;
    height: 60px;
    margin-right: 18px;

    border-radius: 50%;

    object-position: center;
    object-fit: contain;

    @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 15px;
    }
}

.placeholder-bg {
    background-color: #777;
}

.review-container {
    width: 65%;
    
    @media (max-width: 767px) {
        width: 100%;
    }
}

.review-item {
    padding: 20px;
    border-radius: 6px;
}

.review-placeholder {
    height: 130px;
}

.feedback-margin {
    margin-right: 30px;

    @media (max-width: 767px) {
        margin-right: 0;
    }
}

.comment-margin {
    margin-left: 30px;

    @media (max-width: 767px) {
        margin-left: 0;
    }
}

.review-separator {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    background-color: #dadce0;
    opacity: 0.5;
}

.feedback-bg {
    background-color: #f9fcee;
}

.comment-bg {
    background-color: #f2f3ef;
}

.review-profile-image {
    width: 36px;
    height: 36px;
    margin-right: 15px;
    border-radius: 50%;
}

.patient-info-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.patient-name-container {
    display: flex;
    flex-direction: column;
    
    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
}