.page {
  a {
      text-decoration: none;
  }
}

.card {
  margin:0 0 33px;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  position: relative;

  h4{
      color: #587e85;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
  }
}

.icon {
  width: 100px;
  height: 100px;

  object-fit: contain;
  object-position: center;
}

.event-detail-card{
  padding: 70px !important;
  cursor: default !important;
  @media screen and (max-width:767px) {
      padding: 20px !important;
   }
   .align-items-top{
       align-items: flex-start;
   }
  .delete-edit-icons{
      margin-top: 5px;
      display: flex;
      img{
          cursor: pointer;
          margin-left: 27px;
          width: 18px;
          height: auto;
          @media screen and (max-width:767px) {
              margin-left: 15px;
           }
      }
  }
  .white-col-list{
      li{
          margin-bottom: 25px;
          .tag-list{
              display: flex;
              span{
                  color: rgb(47, 50, 68);
                  font-size: 12px;
                  font-weight: 600;
                  margin: 10px  5px 0 0;
                  padding: 6px 15px 9px;
                  border-radius: 14px;
                  background-color: rgb(242, 243, 239);
                  animation: none;
              }
          }
      }
  }
  .invitation-status{
      margin: 0  0 30px;
      padding: 20px 30px ;
      border-radius: 6px;
      background-color: rgb(249, 252, 238);
      @media screen and (max-width:767px){
          padding: 20px ;
          margin-top: 15px;
      }
      .invitation-list{
          overflow: hidden;
          li{
              display: flex;
              border-bottom: solid 1px rgb(238, 239, 241);
              padding: 15px 0;
              margin: 0 -10px;
              flex-wrap: wrap;
              &:last-child{
                  border: 0;
              }
              > p{
                  padding: 0px 10px;
              }
          }
      }
  }
  .request-join-tab{
      .join-single{
          display: flex;
         
          padding-left: 55px;
          padding-top: 20px;
          padding-bottom: 10px;
          border-bottom: solid 1px rgb(224, 224, 224);
          justify-content: space-between;
          position: relative;
          @media screen and (max-width:767px) {
             flex-wrap: wrap;
          }
          img{
          position: absolute;
          left: 0;
          top: 20px;
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 9px;
          }
      }
  }
  
  .status-tag{
      font-weight: 500;
      font-size: 12px;
      color: #fff;
      background: #66ACCF;
      border-radius: 6px;
      padding: 4px 8px;
      &.bg-red{
          background: #EE4F4F;
      }
  }
}