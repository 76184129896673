.modal-header {
  border-bottom: none;
  padding: 0;
}
.brand-email-template-container {
  padding: 0;
  background-color: #f2f3ef;
}

.modal_body_topbar {
  background-color: #587e85;
  display: flex;
  gap: 60px;
  padding: 20px;
  min-height: 195px;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    min-height: 340px;
  }

  .left_topbar_wrapper {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    img {
      height: 80px;
      width: 80px;
    }

    .email_address {
      max-width: 230px !important;
      font-size: 13px;
      font-weight: 700;
      color: #fff;
      margin-top: 10px;

      @media screen and (max-width: 767px) {
        max-width: 100% !important;
        margin-top: 0px;
        text-align: center;
      }
    }
  }

  .lifeline_wrapper {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }

    .lifeline_text {
      font-size: 18px;
      font-weight: 700;
      color: #a9cf3d;
    }
    .visit_website {
      font-size: 13px;
      font-weight: 700;
      color: #fff;
      line-height: 20px;
    }
    .contact_text {
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      line-height: 24px;
    }
  }
}

.modal_bottom_body {
  display: flex;
  justify-content: center;

  .modal_middle_body {
    width: 83%;
    margin-top: -50px;
    background-color: white;
    padding: 20px 30px;

    @media screen and (max-width: 767px) {
      padding: 15px 20px;
    }

    .user_name_text {
      font-size: 18px;
      font-weight: 700;
      color: #587e85;
      margin-top: 20px;
    }
    .date_text {
      font-size: 24px;
      font-weight: 700;
      color: #2d3245;
      padding-bottom: 15px;
      border-bottom: 1px solid #eaeaea;
    }

    .calendar_text_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #eaeaea;
      margin-top: 20px;
      padding-top: 20px;
    }
  }
}

.general_text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #2d3245;
  .appointent_date_text {
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    color: #2d3245;
  }
}

.add_to_calendar{
  cursor: default !important;
  background-color: #a9cf3d;
  max-width: max-content;
  border-radius:5px;
  
  p{
    font-size:14px;
    font-weight:600;
    margin-top: 15px;
    padding: 10px 25px;
    color:white;
  }
}
.subscribe_text {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;

  a {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 400;
    color: #587e85;
  }
}
.social_media_wrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 40px 0;
}

