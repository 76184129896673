.google-radio-list {
    max-height: 250px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #E0E0E0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #587e85;
        border-radius: 10px;
    }
    label{
        padding: 8px 0;
    }
    span{
        animation: none !important;
    }
}
.show-on-map {
    margin-bottom: -20px;
    position: relative;
    z-index: 1;
}
.location-input-wrapper {
    position: relative;
    .location-list {
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0628824);
        border-radius: 10px;
        // padding: 12px 20px;
        max-height: 150px;
        overflow-y: auto;
        z-index: 3;
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #E0E0E0;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #587e85;
            border-radius: 10px;
        }
        li {
            font-weight: 400;
            font-size: 13px;
            color: #111B45;
            padding: 10px 0px;
            margin: 0px 20px;
            border-bottom: 1px solid #eeeff1;
            cursor: pointer;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

.staff_whats_this_text{
    color: #587E85;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
}

.staff_tooltip{
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 153px;
}

.staff_verify_btn{
    display: flex;
    justify-content: end;
    color: #587E85;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-decoration-line: underline;
}

.staff_verified_btn{
    display: flex;
    justify-content: end;
    align-items: center;
    color:  #A9CF3D;
    text-align: right;
    font-size: 12px;
    font-weight: 500;
    line-height: 8.514px

}

.verified_text{
    margin-top: 2px;
}
.staff_custom_dropdown_only{
    width:100% !important;
}

.practice_tooltip_redirection{
     cursor: pointer;
     text-decoration-line: underline !important;
     color: #fff;
}

.practice_add_tooltip_redirection{
    cursor: pointer;
    text-decoration-line: underline !important;
    color: #a9cf3d;
}

.staff_yellow_heading{
    background-color: #FFBA00;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap:5px;

    @media screen and (max-width: 768px) {
        margin-top: 16px;
      }

    .staff_alert_text{
        color: #2F3245;
        font-size: 10px;
        font-weight: 500;
        line-height: 16px
    }
}

