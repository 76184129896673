.currency-confirmation-modal {

    &.modal-dialog {


        .modal-content {
            padding: 70px 30px 40px;
            text-align: center;

            @media screen and (max-width:767px) {
                padding: 50px 20px 14px
            }

            .modal-body {

button{
    min-width: 222px;
}

            }
        }
    }
}