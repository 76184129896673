.pd-dc-title {
    font-size: 15px;
    color: #5b6b82;
}

.pd-dc-value-placeholder {
    width: 200px;
    height: 20px;
    border-radius: 2px;
    background-color: #777;
}

.pd-dc-value {
    font-size: 15px;
    font-weight: 600;
    color: #102c42;
}

.pd-o-placeholder1 {
    width: 150px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}

.pd-o-placeholder2 {
    width: 210px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}

.pd-o-placeholder3 {
    width: 50px;
    height: 18px;
    border-radius: 2px;
    background-color: #777;
}