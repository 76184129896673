.sale-search-box{

    width:362px;
}
.salesRepo-table {
    .data-table-block {
        .data-table-container {
            .table {
                tbody {
                    tr {
                        td {


                            &:first-child {
                                font-weight: 400;
                                color: #535b5f;
                             

                            }

                          

                            span {
                                font-size: 12px;

                            }


                        }



                    }

                }

            }

        }

        .pagnation-block {
            margin-top: 80px;

        }

    }
}

