.helpdesk-search {
    min-width: 320px;
    @media screen and (max-width:767px) {
    min-width: auto;
    }
    input{
        min-height: 50px;
    }
    @media screen and (max-width:767px) {
        margin-bottom: 16px;
    }
}


.add-employee-modal-dialog{
    max-width: 660px;
    .search-box{
        width: 100%;
        margin: 40px 0 30px;
        @media screen and (max-width:767px){
            margin-top:25px
        }
        input{
            border: solid 1px rgb(218, 218, 218);
            width: 100%;
           padding: 13px 15px 13px 40px}
        span.ico {
            top: 14px;
        }
    }
    .employee-list{
        margin-bottom: 40px;
        max-height: 35vh;
        overflow-y: auto;
        @media screen and (max-width:767px){
            margin-bottom:30px
        }
        li{
            padding: 12px 0;
            border-bottom: solid 1px rgb(218, 220, 224);

        }
    }
}


.helpdesk-card {
    padding: 30px 30px 5px !important;
    @media screen and (max-width:767px) {
        padding: 25px 15px 5px !important;
    }

    a {
        text-decoration: none;
    }

    .card-status-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        @media screen and (max-width: 767px) {
            display: block;
             margin-bottom: 10px;
        }

        .timer-clock {
            font-weight: 300;
            font-size: 12px;
            color: #6f7788;
            display: flex;
            @media screen and (max-width:767px) {
                width: 100%;
            }
        }
        &.detail-box{
            @media screen and (max-width:767px) {
                flex-direction: column;
                align-items: flex-start;
                .timer-clock {
                    order: 2;
                    width: 100%;

                }
            }
        }
        .status-box {
            background: #66accf;
            border-radius: 3px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            line-height: 1;
            padding: 5px 10px;
            display: inline-block;
            &.pending {
                background: #66accf;
            }
            &.resolved {
                background: #a9cf3d;
            }
            &.progress {
                background: #66accf;
            }
            @media screen and (max-width:767px) {
             margin-bottom: 24px;
            }
        }
    }
    .ticket-desc-detail{
        font-size: 16px;
        padding-bottom: 30px;
        border-bottom: 0.6px solid #DADCE0;
        color: #535B5F;
        font-weight: 300;


    }


}

.chat-box-wrapper {
    margin-bottom: 30px;
    max-height: 500px;
    overflow-y: auto;
    @media screen and (max-width:767px) {
        margin-top: 20px;
    }
    .chat-card {
        background: #f2f3ef;
        border-radius: 6px;
        padding: 20px 20px 5px;
        margin-bottom: 10px;
        &.customer-chat {
            margin-left: 180px;
            background: #f2f3ef;
            word-wrap: break-word;
            @media screen and (max-width:767px) {
                margin-left: 0px;
                margin-right: 15px;
            }
        }
        &.helpdesk-chat {
            margin-right: 180px;
            background: #f9fcee;
            @media screen and (max-width:767px) {
                margin-right: 0px;
                margin-left: 15px;
            }
        }
        .circle{
            margin: 2px 6px;
            background: #E0E4DC;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            display: inline-block;

        }
    }
}
.write-message-wrapper {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.083998);
    margin: 0 -30px -5px;
    border-radius: 0 0 10px 10px;
    padding: 15px 30px;
    @media screen and (max-width:767px) {
        margin: 0 -20px -5px;
        padding: 8px 20px;
    }
    .input-btn-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        textarea {
            resize: none;
            border: 0;
            width: calc(100% - 130px);
            border-bottom: 1px solid rgba(151, 151, 151, 0.4);
            font-weight: 600;
            font-size: 14px;
            color: #102c42;
            height: 40px;
            padding: 2px 0;
            outline: 0;
             @media screen and (max-width:767px) {
       font-size: 12px;
    }
            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: #102c42;
                opacity: 0.5; /* Firefox */
            }
            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: #102c42;
                opacity: 0.5;
            }
            &::-ms-input-placeholder {
                /* Microsoft Edge */
                color: #102c42;
                opacity: 0.5;
            }
        }
        button {
            min-width: 11px;
        }
    }
}
