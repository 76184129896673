.promotion-tab {
    li {
        a {
            background-color: transparent !important;
            padding: 18px 0 !important;
            margin-right: 60px;
        }
    }
}
.promotion-card {
    padding: 30px 30px 5px !important;
    @media screen and (max-width: 767px) {
        padding: 20px 15px 1px !important;
    }
    &.pt-10 {
        padding-top: 10px !important;
    }
    a {
        text-decoration: none;
    }
    .card-status-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
            > a,
            > p {
                order: 2;
            }
        }
        .status-box {
            background: #66accf;
            border-radius: 3px;
            font-weight: 500;
            font-size: 10px;
            color: #ffffff;
            line-height: 1;
            padding: 5px 10px;
            margin-bottom: 5px;
            @media screen and (max-width: 767px) {
                order: 1;
                margin-bottom: 15px;
                font-size: 12px;
                padding: 7px 10px;
            }
            &.expired {
                background: #ee4f4f;
            }
            &.launched {
                background: #66accf;
            }
        }
    }
}
.promotion-detail-tab {
    border-bottom: 1px solid #f6f6f6;
    margin-left: -30px;
    margin-right: -30px;
    border-color: #f6f6f6 !important;
    li {
        a {
            padding: 18px 0 !important;
            margin-right: 30px;
            &:first-child {
                margin-left: 30px;
            }
            @media screen and (max-width: 767px) {
                margin-right: 25px;
            }
        }
    }
}

.launched-remaining-card {
    padding: 0px 30px 0px !important;
    background: #587e85 !important;
    margin-top: 30px;
    @media screen and (max-width: 767px) {
        padding: 20px 20px 15px !important;
    }
    .card-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .card-padding {
            padding-top: 20px;
            padding-bottom: 20px;
            @media screen and (max-width: 767px) {
                padding-top: 0;
            }
        }
        .card-spacing-left {
            p {
                margin-left: 25px;
                @media screen and (max-width: 767px) {
                    margin-left: 0;
                }
                &:first-child {
                    font-weight: 600;
                }
            }
        }
        .seperation-border-left {
            border-left: 1px solid #698b91;
            @media screen and (max-width: 767px) {
                border: none;
            }
            .seperation-border {
                @media screen and (max-width: 767px) {
                    border-bottom: 1px solid #698b91;
                    margin-bottom: 20px;
                    margin-top: -6px;
                }
            }
        }
        a.button-link {
            border: 2px solid #a9cf3d;
            color: #fff;
            margin-bottom: 1px;
            @media screen and (max-width: 767px) {
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 5px;
                width: calc(100% - 40px);
            }
        }
    }
}
.tab-table-list {
    div {
        box-shadow: none !important;
    }
    @media screen and (max-width: 991px) {
        margin-top: -20px;
        tr {
            box-shadow: none !important;
            margin: 0 !important;
        }
    }
    thead {
        th {
            background-color: transparent !important;
            font-weight: 600;
            font-size: 12px;
            color: #102c42;
            padding: 16px 0 !important;
        }
    }
    tbody {
        td {
            font-weight: 400;
            font-size: 14px;
            color: #535b5f !important;
            padding: 16px 0 !important;
            &:first-child::after {
                left: 0 !important;
            }
            &:last-child::after {
                left: 0 !important;
            }
        }
    }
}
.view-customer-modal {
    .alert-info {
        display: flex;
        padding: 15px;
        background: #f9fcee;
        border-radius: 10px;
        align-items: center;
        margin: 20px 0;
        @media screen and (max-width: 767px) {
            display: block;
            > div {
                align-items: flex-start;
                > img {
                    margin-top: 5px;
                }
            }
        }
        img {
            margin-right: 15px;
        }
        .send-nvite {
            white-space: nowrap;
            margin-left: 40px;
            @media screen and (max-width: 767px) {
                margin-left: 32px;
                margin-top: 7px;
            }
        }
    }
    .customer-list {
        margin-bottom: 40px;
        li {
            padding: 8px 0;
            font-weight: 600;
            font-size: 14px;
            color: #102c42;
        }
        &.view-customer-list li{
            border-bottom: 0.6px solid #dadce0;

                padding: 15px 0;
    margin: 0;

            p{
                padding: 0px;
    margin: 0px;
    font-size: 12px;
    font-weight: 400;
    color: #87928D;
            }
        }
    }
}

.select-promocode-dropdown {
    .toggle-dropdown {
        display: flex;
        justify-content: space-between;
        color: #102c42;
        padding-bottom: 15px;
        font-size: 16px;
        font-weight: 600;
        border-bottom: solid 1px rgba(151, 151, 151, 0.4);
        padding-top: 5px;
    }
    .menu-dropdown {
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.0628824);
        border-radius: 10px;
        width: 100%;
        .promocode-list {
            padding: 0 20px 2px;
            li {
                display: flex;
                justify-content: space-between;
                padding: 12px 0;
                font-size: 13px;
                font-weight: 400;
                &:not(:last-child) {
                    border-bottom: 1px solid #eeeff1;
                }
                .promo-code {
                    color: #111b45;
                }
                .discount {
                    color: #ff4550;
                }
            }
        }
    }
}
