img {
    max-width: 100%;
}
.sendbird-conversation__scroll-bottom-button {
    display: none;
}
.miraxis-messenger {
    display: flex;
    .img-cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .img-content-box {
        display: flex;
        align-items: center;
        width: calc(100% - 70px);
    }
    .message-input-box {
        display: flex;
        align-items: center;
        line-height: 1.3;
    }
    *::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    /* Track */
    *::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
    }

    /* Handle */
    *::-webkit-scrollbar-thumb {
        background: #587e85;
        border-radius: 10px;
    }
    .sendbird-channel-list {
        width: 410px;
        padding-right: 30px;
        @media screen and (max-width: 1200px) {
            width: 350px;
        }
        @media screen and (max-width: 991px) {
            width: 100%;
            padding-right: 0;
            transition: 0.3s;
            &.hide-channel-list {
                width: 0;
                overflow: hidden;
            }
        }
        .channel-list-header {
            margin-bottom: 30px;
            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
            }
            .profile-owner-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .profile-image {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    border-radius: 100%;
                    overflow: hidden;
                }
                .profile-info {
                    width: calc(100% - 60px);
                }
                .profile-name {
                    color: #587e85;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 1.25;
                }
                .account-ownner-desc {
                    font-size: 12px;
                    color: #6f7788;
                    line-height: 1.33;
                }
                .action-box {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    img {
                        cursor: pointer;
                    }
                    .plus-icon {
                        margin-left: 15px;

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
            .search-box {
                width: 100%;
                margin: 12px 0;
                input {
                    border: 1px solid #dadada;
                }
            }
        }
        .channel-dropdowm-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ch-checkbox{
                display: flex;
                align-items: center;
                span{
                    font-size: 13px;
                    font-weight: 400;
                }
            }
            .dropdown-menu {
                max-width: 210px;
            }
            .dropdown-btn{
                min-width: 75px;
                justify-content: flex-end;
            }
        }
        .dropdown-btn {
            color: #77928b;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            @media screen and (max-width: 767px) {
                font-size: 12px;
            }
            .caret-img {
                width: 10px;
                margin-left: 7px;
                @media screen and (max-width: 767px) {
                    margin-left: 3px;
                }
            }
        }
        .dropdown-menu {
            min-width: 180px;
            padding: 0 20px;
            border-radius: 10px;
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.06);
            border: 0;
            background-color: #fff;
            max-height: 200px;
            overflow-y: auto;
            .dropdown-item {
                font-size: 13px;
                color: #111b45;
                padding: 12px 0px;
                background-color: #fff;
                position: relative;
                border-bottom: 1px solid #f2f3ef;
            }
        }
        .empty-channel-list {
            text-align: center;
            height: calc(100% - 180px);
            color: #6f7788;
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            max-width: 300px;
            margin: 0 auto;
            h3 {
                font-weight: 500;
                font-size: 25px;
                color: #111b45;
                margin: 25px 0 10px;
            }
        }
        .channel-chat-list {
            max-height: calc(700px - 120px);
            overflow-y: auto;
            width: calc(100% + 30px);
            @media screen and (max-width: 991px) {
                width: 100%;
            }
            .channel-chat-single {
                display: flex;
                padding: 16px 30px 16px 16px;
                border-bottom: 1px solid #e0e0e0;
                justify-content: space-between;
                position: relative;
                @media screen and (max-width: 991px) {
                    padding: 12px 0;
                }
                @media screen and (max-width: 767px) {
                    padding: 12px 5px;
                }
                &.active-channel {
                    background: #f2f3ef;
                }
                .preview-image {
                    width: 70px;
                    height: 70px;
                    border-radius: 100%;
                    overflow: hidden;
                    margin-right: 15px;
                    @media screen and (max-width: 767px) {
                        width: 50px;
                        height: 50px;
                        margin-right: 8px;
                    }
                }
                .preview-member-info {
                    width: calc(100% - 90px);
                    .member-name {
                        color: #587e85;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 1.25;
                        margin-bottom: 5px;
                        white-space: nowrap;
                        max-width: 155px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        @media screen and (max-width: 1200px) {
                            max-width: 95px;
                        }
                    }
                    .member-office-name {
                        font-size: 10px;
                        color: #87928d;
                        line-height: 1.2;
                        margin-bottom: 8px;
                        max-width: 170px;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .member-last-message {
                        color: #2a4642;
                        font-size: 12px;
                        font-weight: 500;
                        max-width: 170px;
                        font-weight: 500;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .date-action-box {
                    display: flex;
                    align-items: center;
                    .date-box {
                        color: #87928d;
                        font-size: 10px;
                        margin-right: 10px;
                        white-space: nowrap;
                        @media screen and (max-width: 767px) {
                            margin-right: 5px;
                        }
                    }
                    .ellipsis-icon {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                        text-align: center;
                        line-height: 32px;
                        color: #587e85;
                        font-size: 18px;
                        border-radius: 100px;
                        &:hover {
                            background: #e6eced;
                        }
                        @media screen and (max-width: 767px) {
                            width: 20px;
                        }
                    }
                    .empty-ellipsis-icon {
                        width: 32px;
                        height: 32px;
                        @media screen and (max-width: 767px) {
                            width: 20px;
                        }
                    }
                }
                .unread-messages-count {
                    position: absolute;
                    right: 15px;
                    top: 5px;
                    width: 20px;
                    height: 20px;
                    background: #ff5757;
                    text-align: center;
                    line-height: 20px;
                    border-radius: 100%;
                    color: #fff;
                    font-size: 10px;
                    font-weight: 600;
                }
                .total-members {
                    margin-top: 20px;
                }
            }
        }
    }

    .conversation-wrapper {
        flex: 1;
        transition: 0.3s;
        min-height: 700px;
        background: #f2f3ef;
        border-radius: 6px;
        transition: 0.5s;
        @media screen and (max-width: 991px) {
            width: 0;
            overflow: hidden;
            &.show-conversation {
                width: 100%;
            }
        }
        &.contact-info-active {
            width: calc(100% - 410px - 300px);
            .sendbird-conversation__messages .sendbird-conversation__messages-padding .message-area {
                width: 95%;
            }
            @media screen and (max-width: 767px) {
              width: 0;
              display: none;
             }
        }
        .back-arrow {
            display: none;
            background: #fff;
            padding-bottom: 15px;
            @media screen and (max-width: 991px) {
                display: block;
            }
            img{
                margin-right: 5px;
            }
        }
        .sendbird-conversation {
            position: relative;
            height: 100%;
            @media screen and (max-width: 991px) {
               height: calc(100%  - 40px);
            }
            .loader-container {
               margin: 10px;
            }
            .spinner-border{
                width: 20px;
                height: 20px;
                border-width: 3px;
                border-color: #2a4642;
                border-right-color: transparent;
            }
            .empty-chat-box {
                padding: 0 20px;
                text-align: center;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #6f7788;
                font-size: 12px;
            }
        }

        .sendbird-conversation-header {
            display: flex;
            background: #f9faf6;
            border-bottom: 1px solid #e0e0e0;
            border-radius: 6px 6px 0 0;
            padding: 10px;
            justify-content: space-between;
            align-items: center;
            .user-image {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 12px;
            }
            .user-name {
                font-weight: 600;
                font-size: 16px;
                color: #587e85;
                width: calc(100% - 55px);
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .action-icon {
                color: #587e85;
                font-size: 18px;
                cursor: pointer;
            }
        }
        .sendbird-conversation__messages {
            .sendbird-conversation__scroll-container {
                max-height: calc(700px - 130px);
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                @media screen and (max-width:767px) {
                    max-height: calc(700px - 120px);
                }
            }
            .sendbird-conversation__messages-padding {
                padding: 20px;
                position: relative;
                overflow-y: auto;
                @media screen and (max-width:767px) {
                    padding: 5px;
                }
                .sendbird-separator {
                    text-align: center;
                    margin-bottom: 10px;

                    .sendbird-separator__text {
                        color: #87928d;
                        font-size: 12px;
                        font-weight: 500;
                        background: #fff;
                        display: inline-block;
                        border-radius: 6px;
                        padding: 7px 13px;
                        line-height: 1;
                    }
                }
                .other-message-box{
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
                .own-message-box {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
                .message-area {
                    margin-bottom: 10px;
                    width: 71%;
                    background: #ffffff;
                    border-radius: 10px 10px 10px 0px;
                    padding: 20px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 1.5;
                    color: #2f3245;
                    position: relative;
                    white-space: pre-wrap;
                    word-break: break-word;
                    .document-name a{
                        word-break: break-word;
                    }
                    @media screen and (max-width:767px) {
                        width: 95%;
                    }
                    a {
                        color: inherit;
                    }
                    &.outgoing-msg {
                        background: #587e85;
                        border-radius: 10px 10px 0px 10px;
                        color: #fff;
                        .message-date {
                            color: #e7eee0;
                        }
                        .name-office-box {
                            .name-box {
                                color: #a9cf3d;
                            }
                            .office-box {
                                color: #ffffff;
                                opacity: 0.5;
                            }
                        }
                    }
                    .message-action-options {
                        position: absolute;
                        right: 8px;
                        top: 8px;
                        font-size: 16px;
                        cursor: pointer;
                    }
                    .seen-msg-icon {
                        position: absolute;
                        right: 8px;
                        bottom: 8px;
                        font-size: 16px;
                        color: #a8cf3d;
                        .single-tick {
                            color: #fff;
                        }
                    }
                    .image-box {
                        margin-bottom: 10px;
                    }
                    .message-date {
                        color: #87928d;
                        font-size: 12px;
                        margin-bottom: 10px;
                        font-weight: 500;
                        svg {
                            font-size: 18px;
                            margin: 0 2px;
                            color: #e0e4dc;
                        }
                    }
                    .name-office-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        line-height: 1.2;
                        margin-top: 15px;
                        .name-box {
                            text-transform: uppercase;
                            font-weight: 600;
                            font-size: 10px;
                            color: #587e85;
                        }
                        .office-box {
                            font-size: 12px;
                            color: #87928d;
                        }
                    }
                }
            }
        }
        .sendbird-conversation__footer {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: #f9faf5;
            border-top: 1px solid #e0e0e0;
            padding: 10px;
            border-radius: 0 0 6px;
            @media screen and (max-width:991px) {
                position: fixed;
            }
            @media screen and (max-width:767px) {
               padding: 15px 15px;
            }
            .footer-inner-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 0;
                .message-input {
                    margin-right: 15px;
                    width: calc(100% - 100px);
                    textarea {
                        height: 60px;
                        background: #ffffff;
                        border: 1px solid #dadada;
                        border-radius: 5px;
                        padding: 12px 15px;
                        width: 100%;
                        outline: 0;
                        overflow-y: auto;
                        resize: none;
                        line-height: 1.3;
                        @media screen and (max-width:767px) {
                            height: 65px;
                            padding: 15px 15px;
                            font-size: 14px;
                        }
                    }
                }
                .send-message-icon {
                    width: 40px;
                    height: 40px;
                    background: #a9cf3d;
                    box-shadow: 4px 5px 14px #e4e9dd;
                    border-radius: 26.145px;
                    text-align: center;
                    line-height: 40px;
                    margin-left: 15px;
                    cursor: pointer;
                    @media screen and (max-width:767px) {
                        width: 30px;
                        height: 30px;
                        line-height: 20px;
                        padding: 6px;
                     }
                }
                .attach-file {
                    padding:2px;
                    cursor: pointer;
                    label{
                        cursor: pointer;
                        padding: 5px;
                    }
                }
            }
        }
    }
    .profile-contact-info {
        width: 300px;
        background: #f9fcee;
        border-left: 1px solid #e0e0e0;
        padding: 20px;
        position: relative;
        @media screen and (max-width: 1200px) {
            width: 250px;
        }
        @media screen and (max-width: 991px) {
          
            width: 100%;
         }
        @media screen and (max-width: 767px) {
           padding: 10px 15px;
          
        }
        .close-btn {
            position: absolute;
            top: 20px;
            right: 20px;
            background: #f3f3f3;
            border-radius: 100%;
            width: 27px;
            height: 27px;
            text-align: center;
            line-height: 27px;
            cursor: pointer;
        }
        .contact-heading {
            margin-top: 10px;
            font-weight: 500;
            font-size: 20px;
            color: #111b45;
            line-height: 1.25;
            margin-bottom: 20px;
            @media screen and (max-width:767px) {
                margin-bottom: 15px;
            }
        }
        .profile-img-box {
            text-align: center;
            .img-box {
                width: 80px;
                height: 80px;
                border-radius: 100%;
                overflow: hidden;
                margin: 20px auto 10px;
            }
            .profile-name {
                font-weight: 600;
                font-size: 16px;
                color: #587e85;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            .account-ownner-desc {
                font-size: 12px;
                color: #6f7788;
                line-height: 1.33;
            }
        }
        .media-doc-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 13px;
            margin-top: 20px;
            .text-box {
                font-weight: 500;
                font-size: 14px;
                color: #2f3245;
            }
            .no-media-text {
                font-size: 12px;
            }
        }
        .media-gallery {
            display: flex;
            margin: 0 -5px 20px;
            flex-wrap: wrap;
            .img-box {
                width: calc(25%);
                height: 60px;
                padding: 0 5px;
                margin-bottom: 10px;
                align-items: center;
                display: flex;
                justify-content: center;
                img {
                    border-radius: 10px;
                }
            }
            .doc-box{
                width: calc(25% - 10px);
                margin: 0 5px 10px;
                padding: 16px 18px;
                background: #FFFFFF;
                border-radius: 10px;
                text-align: center;
            }
        }
        .doc-list{
            display: flex;
            margin: 0 -5px 20px;
            flex-wrap: wrap;
            .doc-box{
                text-align: center;
                width: calc(25% - 10px);
                min-height: 60px;
                padding: 10px 5px;
                margin: 0 5px 10px;
                background: #FFFFFF;
        border-radius: 6px;
        .doc-name{
            font-size: 10px;
            margin: 5px auto 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 50px;
            overflow: hidden;
        }
            }
        }
        .personnal-detail {
            border-bottom: 1px solid #e0e0e0;
            border-top: 1px solid #e0e0e0;
            padding-top: 20px;
            padding-bottom: 20px;
            font-weight: 600;
            font-size: 14px;
            color: #102c42;
            margin-bottom: 20px;
            .c-field:last-child {
                margin-bottom: 0;
            }
            .group-member-count{
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-weight: 500;
                color: #2F3245;
                margin-bottom: 13px;
            }
            .group-member-list{
                max-height: 280px;
                overflow-y: auto;
                li{
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;
                    .img-box{
                        margin-right: 16px;
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        overflow: hidden;
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                       
                    }
                    .group-name{
                        font-weight: 600;
                        font-size: 14px;
                        color: #102C42;
                    }
                    .group-desc{
                        color: #87928D;
                        font-size: 12px;
                        font-weight: 400    ;
                    }
                }
            }
        }
        .delete-chat {
            font-weight: 500;
            font-size: 12px;
            color: #ee4f4f;
            display: inline-flex;
            align-items: center;
            cursor: pointer;
            .delete-icon {
                border: 1px solid #e0e0e0;
                width: 26px;
                height: 26px;
                border-radius: 100%;
                text-align: center;
                padding: 2px 5px;
                margin-right: 14px;
                display: block;
            }
        }
    }
    .media-doc-tabs {
        margin-top: 10px;
        .nav-tabs .nav-link.active {
            background-color: transparent;
        }
    }
    .tab-content-height{
        max-height: 540px;
        overflow: auto;
        overflow-x: hidden;
    }
}
