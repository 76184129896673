.feedback-detail {
    .feedback-card {
        padding: 70px 70px 50px;
        .btn-link {
            float: right;
        }
        .inner-wrapper {
            margin-bottom: 40px;
            .c-field {
                margin-bottom: 25px;
                white-space: pre-line;
            }
        }
    }
}
