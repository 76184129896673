.header-vendor{
    padding-left: 25px;
    @media screen and (max-width:991px) {
       padding-right: 25px;
    }
    @media screen and (max-width:767px) {
     padding-left: 0;
     padding-right: 0;
    }
    .vendor-menu-icon{
        margin-right: 40px;
        cursor: pointer;
        @media screen and (max-width:991px) {
            margin-right: 15px;
        }
    }
    .logo{
        @media screen and (max-width:767px) {
        width: 89px;
        }
    }
}