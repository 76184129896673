.leaves-page {
  margin-top: 30px;
  .alert-box {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    background: #ffba00;
    padding: 12px 21px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 6px;
    color: #000000;
    display: flex;
    @media screen and (max-width: 991px) {
      line-height: 20px;
      padding: 9px 10px;
    }

    .alert-img {
      margin-right: 18px;

      @media screen and (max-width: 991px) {
        margin-right: 11px;
        width: 54px;
      }
    }
  }
}
.apply-leaves-page {
  .sub-head {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.28px;
    margin-bottom: 30px;
    color: #000000;
  }
  .apply-leaves-card {
    @media screen and (max-width: 767px) {
      padding: 20px 20px 6px 20px;
    }
    .status-box {
      background: #f9fcee;
      padding: 0px 20px 10px;
      margin-top: 5px;
      @media screen and (max-width: 767px) {
        padding: 4px 0px 15px;
        margin-left: 0px;
        margin-right: 0px;
      }
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        color: #6f7788;
        margin-bottom: 10px;
      }
      .status {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #102c42;
        margin-bottom: 4px;
      }
    }
    .alert-box {
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;

      padding: 10px 20px;
      margin-bottom: 22px;

      max-width: 425px;
      width: 100%;

      background: #fcc539;
      color: #2f3245;
      img {
        margin-right: 12px;
      }
    }
  }
}
.temporary-modal {
  .main-wrapper {
    max-height: 255px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 15px;
    margin-bottom: 30px;

    &::-webkit-scrollbar {
      width: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #e0e0e0;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #587e85;
      border-radius: 10px;
    }
    .radio-wrapper {
      border-bottom: 0.6px solid #dadce0;

      label {
        padding-top: 16px;
        padding-bottom: 15px;
      }
    }
  }
}
